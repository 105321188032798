// @generated: @expo/next-adapter@2.1.5
import React, {
    useMemo, useEffect, useRef
} from 'react';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import {
    Div, P, svgSprite, Img
} from '../../../../hybrid/wrappers';
import { t } from '../../../../common/components/T'
import { rankColors } from '../../../../project/uikit/color-variables';
import Display from '../../../../common/components/Display';

const UserInfoBlock = ({
    data,
    styles,
    translation,
    compStyle
}) => {
    const contactsItems = data.contacts;
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);

    const dataArr = useMemo(() => contactsItems ? Object.keys(contactsItems) : [], [contactsItems]);
    const rankData = useMemo(() => data?.rank || data?.marketing_profile?.rank, [data]);
    const rankColor = useMemo(() => {
        if (rankData) {
            return rankColors[rankData?.sort] || rankColors[rankColors.length - 1]
        }
        return rankColors[0]
    }, [rankData]);
    const isDropdown = useMemo(() => {
        const res = [];
        dataArr?.map((item) => {
            if (contactsItems[item] !== null) {
                res.push(contactsItems[item]);
            }
        });
        return res.length > 0;
    }, [contactsItems, dataArr]);
    const ContactImg = (val) => {
        switch (val) {
        case 'email':
            return 'email-colored';
        case 'phone':
            return 'phone-colored';
        case 'facebook':
            return 'facebook-colored';
        case 'instagram':
            return 'instagram-colored';
        case 'ok':
            return 'odnoklassniki-colored';
        case 'telegram':
            return 'telegram-colored';
        case 'twitter':
            return 'twitter-colored';
        case 'viber':
            return 'viber-colored';
        case 'vk':
            return 'vkontakte-colored';
        case 'whatsapp':
            return 'whatsapp-colored';
        default:
            return 'phone';
        }
    };
    useEffect(() => {
        if (!previewCanvasRef.current || !imgRef.current) {
            return;
        }
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = {};
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(
            pixelRatio, 0, 0, pixelRatio, 0, 0
        );
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, []);

    return (
        <Div styles={{
            ...styles.formGroupBlockWeb, width: '100%', ...compStyle?.wrapper || {}
        }}
        >
            <>
                <Div styles={styles.formUserBlockWrapper}>
                    <Div styles={styles.formUserBlock}>
                        <Div styles={styles.formUserBlockImg}>
                            <Div/>
                            <Div>
                                <Div styles={{ 'z-index': '0', 'position': 'relative' }}>
                                    {
                                        !!data?.image?.links?.preview
                                            ? <Img styles={{
                                                ...styles.formUserAvatarImg,
                                                'object-fit': 'cover',
                                                'border-radius': '50%'
                                            }} src={data?.image?.links?.preview}/>
                                            : svgSprite('plug-user-image', { style: styles.formUserAvatarImg })
                                    }
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={styles.formUserAvatarCanvas}
                                    />
                                </Div>
                            </Div>
                            <P styles={{
                                ...styles.formUserPosition, ...styles.formUserPositionWeb,
                                background: rankColor.color
                            }}>
                                {!!rankData ? rankData.name : t(
                                    'Form-NoRank', 'Без ранга', 'widget', translation, 'form'
                                )}
                            </P>
                        </Div>
                        <Div>
                            <P styles={styles.formUserSubtitle}>
                                {data?.full_name ? data.full_name :
                                    <Display type='fio' data={data}/>
                                }
                            </P>
                            <Div styles={styles.formUserLogin}>
                                <Div styles={styles.formUserLoginIconWrap}>
                                    {svgSprite('user-circle-fill',
                                        { style: styles.formUserLoginIcon })}
                                </Div>
                                <P styles={styles.formUserLoginText}>{data.username}</P>
                            </Div>

                            <Div styles={{ ...styles.flexRow, ...styles.aligndatasCenter }}>
                                <P styles={{
                                    ...styles.formUserLoginText, ...styles.formUserLoginTextMR, ...styles.formUserLoginTextLabel
                                }}>Пакет:</P>
                                <P styles={styles.formUserLoginText}>{data?.marketing_profile?.package ? data?.marketing_profile?.package.name : 'Без пакета'}</P>
                            </Div>

                        </Div>
                    </Div>
                    {
                        !!isDropdown
                        &&
                        <Div styles={styles.formUserContactBlock}>
                            {
                                dataArr.map((contactItem, idx) => {
                                    if (data.contacts && data.contacts[contactItem] !== null) {
                                        return (
                                            <Div styles={styles.formUserContactItem} key={`${data.username}${idx}`}>
                                                {svgSprite(ContactImg(contactItem), { style: styles.formUserContactItemIcon })}
                                                <P>{`${data.contacts[contactItem]}`}</P>
                                            </Div>
                                        );
                                    }
                                })
                            }
                        </Div>

                    }
                </Div>
            </>
        </Div>
    );
};

export default Styles(UserInfoBlock, getStyles);