import React, { useContext, useMemo } from 'react';
import CartFilter from '../../../carfFilter/CartFilter';
import ContentAlert from '../../../contentAlert/ContentAlert';
import { LangContext } from '../../../../common/decorators/Language';
import { t } from '../../../../common/components/T';
import { Div } from '../../../../hybrid/wrappers';


const AdminProductSets = (props) => {
    const {
        formData, fieldsErr, setFormData, attribute, storeAlias
    } = props
    const { translation } = useContext(LangContext) || {};

    const addProduct = (product) => {
        let haveItem = 0
        let newData= []
        if (formData?.sets?.length > 0){
            newData = formData?.sets?.map((el) => {
                if (el.id === product.id) {
                    haveItem++
                    return {
                        ...el,
                        quantity: el.quantity + 1
                    }
                } else return el
            });
        }
        if (!haveItem) {
            newData.push({
                ...product,
                quantity: 1
            })
        }
        setFormData(attribute, newData)
    }
    const deleteProduct = (id) => {
        let newData = formData?.sets?.filter(el => el.id !== id)
        setFormData(attribute, newData)
    }
    const changeCountProduct = (id, flag, quan = 0) => {
        let newData = formData?.sets?.map(el => {
            if (el.id === id) {
                let newQuantity = 0
                if (flag === 'change') {
                    newQuantity = quan
                } else {
                    newQuantity = flag === 'set' ? el.quantity + 1 : el.quantity - 1
                }
                if (newQuantity) {
                    return {
                        ...el,
                        quantity: newQuantity
                    }
                } else {
                    return 'del'
                }
            } else return el
        })
        newData = newData?.filter(el => el !== 'del')
        setFormData(attribute, newData)
    }
    const productList = useMemo(() => {
        return formData?.sets?.map(item => ({
            ...item,
            product_id: item.id,
            price: item.price || item?.main_price?.price_for_client, //product.prices[0].price_for_client,
            currency_symbol: item?.prices?.[0]?.currency_symbol,
            marketing_currency_symbol: item.prices?.[0]?.marketing_currency || 'PV',
            marketing_price: item.prices?.[0]?.scores_for_client || '0',
            total_price: item?.total_price || '0',
            total_marketing_price: item?.total_marketing_price || '0',
            product: item
        }))
    }, [formData?.sets])
    return (
        <Div styles={{ width: '100%' }}>
            <ContentAlert type='info' message={t('infoAddProductsToSet', 'Выберите товары, которые будут входить в ваш набор', 'all', translation)}/>
            <CartFilter
                cartData={productList}
                funcs={{
                    add: addProduct,
                    del: deleteProduct,
                    counter: changeCountProduct
                }}
                errors={fieldsErr?.['sets']}
                storeAlias={storeAlias}
            />
        </Div>
    );
};

export default AdminProductSets;