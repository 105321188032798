// @generated: @expo/next-adapter@2.1.5
import React, { useMemo, useCallback } from 'react';
import ImgText from './cells/ImgText';
import Text from './cells/Text';
import StickyColumn from './cells/StickyColumn';
import Rang from './cells/Rang';
import Check from './cells/Check';
import Exist from './cells/Exist';
import Package from './cells/Package';
import PartnerStats from './cells/PartnerStats';
import Login from './cells/Login';
import Date from './cells/Date';
import Sums from './cells/Sums';
import Buyer from './cells/Buyer';
import Status from './cells/Status';
import Buttons from './cells/Buttons';
import Actions from './cells/Actions';
import DropDownText from './cells/DropDownText';
import Image from './cells/Image';
import Links from './cells/Links';
import SpecialStatus from './cells/SpecialStatus';
import Switcher from './cells/Switcher';
import Accordion from './cells/Accordion';
import Color from './cells/Color';
import Prices from './cells/Prices';
import TimePass from './cells/TimePass';
import ActiveRow from './cells/ActiveRow';
import DropDown from './cells/DropDown';
import DegreePlus from './cells/DegreePlus';
import Notifications from './cells/Notifications';
import NewsTitle from './cells/NewsTitle';
import OfficeTableDropdown from '../../hybrid/library/OfficeTableDropdown';
import TextHint from './cells/TextHint';

const GridSwitcher = ({
    idx,
    alias,
    val,
    rowIndex,
    thead,
    tbody,
    setGridData,
    styles,
    handlers = {},
    activeTr,
    setActiveTr,
    gridStyles,
    align,
    refTable,
    gridName,
    refTableChild,
    translation
}) => {
    const name = thead[idx]?.name;
    const type = thead[idx]?.type;
    const linkHref = thead[idx]?.linkHref;
    const link = useMemo(() => tbody[rowIndex]?.links?.show, [tbody, rowIndex]);
    const getHandlers = useCallback((key, value = false) => {
        const funcs = key
            ? handlers && handlers[name] && handlers[name][key]
                ? handlers[name][key]
                : false
            : handlers && handlers[name]
                ? handlers && handlers[name]
                : false;

        if (!funcs) return {};

        return Object.keys(funcs).reduce((accum, handlerName) => {
            accum[handlerName] = () => funcs[handlerName](tbody[rowIndex], setGridData, value);
            return accum;
        }, {});
    }, [handlers, name, rowIndex, setGridData, tbody]);

    const commonProps = useMemo(() => ({
        align,
        getHandlers,
        gridStyles,
        name,
        val,
        translation,
        linkHref
    }), [align, getHandlers, gridStyles, name, val, translation, linkHref]);
    switch (type) {
    case 'newsTitle':
        return <NewsTitle {...commonProps} styles={styles} link={link}/>

    case 'notifications':
        return <Notifications {...commonProps} styles={styles}/>
    case 'plus-degree':
        return <DegreePlus {...commonProps} styles={styles}/>
    case 'image-text':
        return <ImgText {...commonProps} styles={styles} link={link} />;

    case 'text':
        return <Text {...commonProps} styles={styles} link={link} />;
    case 'text_hint':
        return <TextHint {...commonProps} styles={styles} />;
    case 'sticky-column':
        return <StickyColumn {...commonProps} styles={styles} link={link} />;

    case 'contacts':
        if (!val || !Object.keys(val).filter(k => val[k]).length) return '-';
        return (
            <OfficeTableDropdown alias={alias} activeTr={activeTr} setActiveTr={setActiveTr} refTable={refTable} gridName={gridName} refTableChild={refTableChild} >
                {val}
            </OfficeTableDropdown>
        );

    case 'rang':
        return <Rang {...commonProps} styles={styles} gridName={gridName} refTable={refTable} refTableChild={refTableChild} idx={idx} thead={thead}/>;

    case 'deleted':
    case 'check':
        return <Check {...commonProps} styles={styles}/>;

    case 'exist':
        return <Exist {...commonProps} styles={styles} />;
    case 'package':
        return <Package {...commonProps} styles={styles} />;

    case 'partner_stats':
        return <PartnerStats {...commonProps} />;

    case 'sponsor':
    case 'login':
        return <Login {...commonProps} styles={styles} link={link} />;

    case 'date':
        return <Date {...commonProps} styles={styles}/>;

    case 'sums':
        return <Sums {...commonProps} styles={styles} />;

    case 'usernameFIO':
    case 'buyer':
        return <Buyer {...commonProps} styles={styles} />;

    case 'status':
        return <Status {...commonProps} styles={styles} />;

    case 'actions':
        return <Actions {...commonProps} styles={styles} refTable={refTable} gridName={gridName} refTableChild={refTableChild}/>;

    case 'button':
    case 'links':
        return <Buttons {...commonProps} itemID={tbody[rowIndex].id} tbodyRowIndexDel={tbody[rowIndex].deleted} styles={styles} />;
    case 'image':
        return <Image {...commonProps} styles={styles} alt='image' />
        // For store-category

    case 'name':
        return <DropDownText
            {...commonProps}
            styles={styles}

            parent_id={tbody[rowIndex].parent_id}
            rowIndex={rowIndex}
            parentID={tbody[rowIndex].id}
            hasChildren={tbody[rowIndex].has_children}
            drop={tbody[rowIndex].drop}
            countShow={tbody[rowIndex].countShow}
            depth={tbody[rowIndex].depth}
            handlers={handlers}/>
    case 'url':
        return <Links {...commonProps}/>
    case 'switcher':
        return <Switcher {...commonProps}/>
    case 'accordion':
        return <Accordion {...commonProps}/>
    case 'special_statuses':
        return <SpecialStatus {...commonProps} styles={styles} />
    case 'color':
        return <Color {...commonProps} />;
    case 'prices':
        return <Prices {...commonProps} styles={styles}/>
    case 'time-passed':
        return <TimePass {...commonProps}/>
    case 'component':
        return commonProps.val
    case 'active-row':
        return <ActiveRow {...commonProps} styles={styles}/>
    case 'dropdown':
        if (!Object.keys(styles).length) return null
        return <DropDown {...commonProps} styles={styles} refTable={refTable} gridName={gridName} refTableChild={refTableChild}/>
    default:
        return null;
    }
};

export default GridSwitcher;