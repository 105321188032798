import {
    useContext, useEffect, useState
} from 'react';
import { LangContext } from '../decorators/Language';
import UserContext from './getContext';
import {
    navigate,
    getData,
    getAllResponses,
    dataSender
} from './router';
import { clearUserCache, getLangLocale } from './memo';
import {
    getLangsConnect, getMenuConnect, getSettingsConnect, getLangLocaleConnect
} from './api';
import moment from 'moment-timezone';
import axios from 'axios/index';
import { t } from '../components/T';
import { zendoStorage } from '../../hybrid/wrappers';
import { requiredWidgetsList } from './datas';

const project = require('../../scripts/configs/project');

// Переменные среды

const getDomain = (domains, isCopy) => {
    if (!domains) return false;
    const array = domains.split('|');
    let URL = array[0];
    if (typeof window != 'undefined'){
        URL = array.find((d) => (isCopy ? 'ut.' : '') + d === window.location.host) || array[0];
    }
    return URL;
};


// получение элемента с LocalStorage
export const getLocalStorageItem = (key, defaultValue, needToAddToLs, needToParseValue) => {
    try {
        let ls_item = zendoStorage.get(key);
        if (!ls_item) {
            if (needToAddToLs) {
                zendoStorage.set(key, defaultValue)
            }
            return defaultValue
        }
        if (needToParseValue) {
            ls_item = JSON.parse(ls_item);
        }
        return ls_item;
    } catch (e){
        return defaultValue
    }
};

const getServerUrl = () => {
    let URL = getDomain(process.env.NEXT_PUBLIC_DOMAINS, process.env.NEXT_PUBLIC_ISCOPY);
    return URL ? `https://${process.env.NEXT_PUBLIC_ISCOPY ? 'at.' : 'api.'}${URL}` : process.env.NEXT_PUBLIC_SERVER_BACK_URL;
};
export const API_SERVER_URL = getServerUrl();
const getSiteUrl = () => {
    let URL = getDomain(process.env.NEXT_PUBLIC_DOMAINS, process.env.NEXT_PUBLIC_ISCOPY);
    return URL ? `https://${process.env.NEXT_PUBLIC_ISCOPY ? 'ut.' : ''}${URL}` : process.env.NEXT_PUBLIC_SERVER_FRONT_URL;
};
export const SITE_URL = getSiteUrl();

// Стили для определенной ширины/высоты экрана
// TODO: передаем объект, в котором ключи - min-width/min-height, используются только правила из подходящего объекта, type - width или height
export const media = (width, height, type, data) => {
    const keys = Object.keys(data).sort((a, b) => b - a);

    if (keys.length) {
        for (let i = 0;i < keys.length;i++) { if (Number(keys[i]) <= Number(type === 'w' ? width : height)) return data[keys[i]]; }
    } else { return {}; }
};

// Получение списка пунктов меню для определенного пользователя
export const rolesMenu = (menus, dataRoles) => {
    return menus ? Object.keys(menus).reduce((ac, key) => {
        if (!menus[key]) return ac;
        return {
            ...ac,
            [key]: menus[key]?.filter((m) =>
                m.showAll || !m?.roles?.length || m?.roles?.some((r) =>
                    dataRoles && dataRoles.find((u) =>
                        u.name === r || u.name === 'superadmin'))) ?? []
        }
    }, {}) : {}
};

// Разлогин
export const delToken = async (userToken, lang='ru', setter) => {
    clearUserCache();
    const del = async () => {
        if (setter) await setter(false);

        navigate('/[lang]/authentication/[type]', '/authentication/login', lang);
    };
    if (userToken) {
        navigate('/[lang]/logout', '/logout', lang);
    } else {
        await del();
    }
};

// Разлогин
export const logOutFunction = async (lang) => {
    clearUserCache();
    navigate('/[lang]/logout', '/logout', lang);
};


// Объединение списка групп со списком полей формы
export const compileGroupsData = (dataTabs, dataFields, translation, addData = false) => {
    if (dataTabs && dataFields) {
        return dataTabs.reduce((accum, tab) => {
            accum[tab.alias] = {
                title: tab.title,
                fields: tab.alias === 'basic'
                    ? dataFields.filter((field) => field.group === tab.alias).map(el => !!el?.placeholder && !!el?.disabled ? { ...el, tooltip: t('input-tooltip-disabled', 'Для изменения данных после прохождения верификации обратитесь в службу поддержки', '/profile', translation) } : el)
                    : dataFields.filter((field) => field.group === tab.alias),
                add: tab.alias === 'accessibility' && addData
                    ? [{
                        position: 'bottom',
                        comp: addData
                    }]
                    : null
            };
            return accum;
        }, {});
    }
    return false;
};

// Получение корзины
export const getCartData = async (
    cartId, userToken, lang, ip, currency
) => {
    if (!cartId && !userToken){
        return {}
    }
    let cart = ''
    if (cartId && !userToken) {
        cart = `/${cartId}`
    }


    // функціонал отримання корзини конкретного магазину
    let shopId = null
    if (process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL !== process.env.NEXT_PUBLIC_SERVER_FRONT_URL && !zendoStorage.get('shopId')){
        const getShopFunc = async () => {
            const ByDomainOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    ...lang ? { 'Accept-Language': lang } : {},
                    Authorization: `Bearer ${userToken}`
                },
                body: JSON.stringify({ domain: process.env.NEXT_PUBLIC_SERVER_FRONT_URL })
            };


            const response = await fetch(`${API_SERVER_URL}/api/v1/shop/shop/by-domain`, ByDomainOptions);
            return await response.json()
        }
        const shop = await getShopFunc()
        if (shop?.data?.id){
            shopId = shop.data.id
            zendoStorage.set('shopId', shop.data.id)
        }
    } else if (process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL !== process.env.NEXT_PUBLIC_SERVER_FRONT_URL) {
        shopId = zendoStorage.get('shopId')
    }

    let headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json;charset=UTF-8',
        ...userToken ? { Authorization: `Bearer ${userToken}` } : {},
        ...ip ? { ClientNodeIp: ip } : {},
        ...shopId ? { 'Shop-ID': shopId } : {},
        ...lang ? { 'Accept-Language': lang } : {}
    };


    if (currency) {
        headers['Accept-Currency'] = currency
    }

    let response = await fetch(`${API_SERVER_URL}/api/v1/shop/carts${cart}`,
        {
            method: cartId && !userToken ? 'GET' : 'POST',
            headers
        })

    const mainData = await response?.json()
    zendoStorage.set('cart_id', mainData?.data?.id)
    return await mainData
}

// Определение окончаний слов в зависимости от количества
export const declOfNum = (n, text_forms) => {
    n = Math.abs(n) % 100;
    let n1 = n % 10;
    if (n > 10 && n < 20) {
        return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
        return text_forms[1];
    }
    if (n1 === 1) {
        return text_forms[0];
    }
    return text_forms[2];
};

// Получение прогресса для радиального прогресс-бара
export const getStatusProgress = (data) => {
    let conditionDone = 0;
    let conditionLength = 0;
    let diff = data ? 100 : 0;
    if (!!data?.conditions) {
        conditionLength = Object.keys(data?.conditions)?.length;
        data?.conditions?.next_rank_package?.has_package && conditionDone++;
        if (!!data?.conditions?.volumes) {
            conditionLength = conditionLength + data?.conditions?.volumes?.length - 1;
            data?.conditions?.volumes.forEach((volume) => {
                if (volume.accumulated >= volume.target) {
                    conditionDone++;
                }
            });
        }
        if (data.conditions.branches_with_rank?.number) {
            conditionLength = conditionLength + Math.floor(data.conditions.branches_with_rank?.number) - 1;
            conditionDone = conditionDone + Math.floor(data.conditions.branches_with_rank?.target_number);
        }
        diff = !!conditionDone && !!conditionLength ? 100 / conditionLength * conditionDone : 0;
        if (data?.next_rank_logical_operator === 'or') {
            if (data?.conditions?.next_rank_package?.has_package) {
                diff = 100;
            }
            if (!!data?.conditions?.volumes) {
                let volumes = data?.conditions?.volumes.every((volume) => {
                    return volume.accumulated >= volume.target;
                });
                if (volumes) diff = 100;
            }
            if (data.conditions.branches_with_rank?.number >= data.conditions.branches_with_rank?.target_number) {
                diff = 100;
            }
        }
    }
    if (diff > 100) {
        diff = 100;
    }
    return {
        dasharray: 394,
        dashoffset: (394 - 109) * (100 - diff) / 100 + 109
    };
};

// Получение дерева категорий в магазине
export const getCategoriesTreeFetch = async (
    data, userToken, lang, ip, currency, storeAlias
) => {
    const newData = [];
    if (!data) return [];
    for (let i = 0;i < data.length;i++) {
        if (data[i].has_children && !data[i].children?.length) {
            let res = await getData(
                `${API_SERVER_URL}/api/v1/shop/product-categories?filter[parent_id]=${data[i].id}&page[size]=8`,
                userToken,
                lang,
                ip,
                currency,
                false,
                storeAlias
            );
            newData.push({
                ...data[i],
                children: res.data,
                has_children: true
            });
        } else {
            newData.push(data[i]);
        }
    }
    return newData;
};
export const getCategoriesTreeSsr = async (
    data, userToken, lang, ip, currency, storeAlias
) => {
    return await getCategoriesTreeFetch(
        data, userToken, lang, ip, currency, storeAlias
    );
};

// Получить url без get-параметров
export const removeURLParameter = (url, parameter) => {
    let urlparts = url?.split('?');
    if (urlparts.length >= 2) {
        let prefix = encodeURIComponent(parameter) + '=';
        let pars = urlparts[1]?.split(/[&;]/g);

        for (let i = pars.length;i-- > 0;) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

// Установить язык писем для пользователя
export const setLanguageForEmail = async (userToken, lang) => {
    try {
        return await axios({
            method: 'POST',
            url: `${API_SERVER_URL}/api/v1/user/profile/lang-code`,
            headers: {
                ...userToken ? { Authorization: `Bearer ${userToken}` } : {},
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            data: { language_code: lang }
        });
    } catch (e) {
        return console.warn('error', e);
    }
};
export async function getWidgetTranslates(lang, widget, translates, setTranslates = () => {}) {
    const staticData = translates?.widget_translates?.[widget]?.data;
    if (!staticData) {
        const transData = await getLangLocale(lang, null, widget);
        if (transData?.data) {
            setTranslates(transData.data);
            return transData.data;
        } else {
            setTranslates({});
            return {};
        }
    } else {
        setTranslates(staticData);
        return staticData;
    }
}
// Стандартные данные, актуальные для всех страниц
export async function getDefaultStaticProps(lang, page) {
    const langFetchRes = await getLangsConnect({ $or: [{ isDefault: true }, { active: true }] });
    const activeLangs = langFetchRes?.data || [];
    if (!lang && !page) return { activeLangs };

    let isActiveLanguage = activeLangs?.find((item) => item.alias === lang);
    if (!isActiveLanguage) return false;

    let storeAlias = false;
    const domain = process.env.NEXT_PUBLIC_SERVER_FRONT_URL;

    if (process.env.NEXT_PUBLIC_SERVER_FRONT_URL !== process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL) {
        try {
            const response = await new Promise((resolve, reject) => {
                dataSender(
                    `${API_SERVER_URL}/api/v1/shop/shop/by-domain`,
                    'POST',
                    { domain },
                    resolve,
                    reject,
                    false,
                    lang
                );
            });

            if (response && response.data && response.data.data && response.data.data.id) {
                storeAlias = response.data.data.id;
            } else {
            }
        } catch (error) {
        }
    }

    // TODO IP

    // const widgetList = ['datePickerRange', 'userModal', 'speedProgress', 'events', 'userSidebar', 'AppButtons', 'excel', 'form', 'month', 'groupForm', 'grid', 'gridHeader', 'gridFilter', 'modal2FA', 'searchWindow', 'SiteSettings']
    const promises = requiredWidgetsList.map((alias) =>
        new Promise((resolve) => {
            getLangLocaleConnect({ page: 'widget', group: alias }, lang).then((data) => {
                resolve({ data, alias });
            })
                .catch((error) => {
                    console.warn(error)
                });
        }));

    const widget_translates = await getAllResponses(promises)

    const currenciesRequest = new Promise((resolve) => {
        getData(
            '/api/v1/user/finance/active-real-currency-list',
            false,
            lang,
            moment().format('1DD.ss.2MM.3mm'),
            false,
            false,
            storeAlias
        ).then((data) => resolve({ data, alias: 'currencies' }))
            .catch((error) => {
                console.warn(error)
            });
    })
    const translatesRequest = new Promise((resolve) => {
        getLangLocaleConnect({ page: { $in: ['all', ...page?.split(',') || []] } }, lang).then((data) => resolve({ data, alias: 'translates' }))
            .catch((error) => {
                console.warn(error)
            });
    })

    const seoResRequest = new Promise((resolve) => {
        getSettingsConnect('seo').then((data) => resolve({ data, alias: 'seoRes' }))
            .catch((error) => {
                console.warn(error)
            });
    })
    const timeResRequest = new Promise((resolve) => {
        getData('/api/v1/settings/date', false, lang, moment().format('1DD.ss.2MM.3mm')).then((data) => resolve({ data, alias: 'timeRes' }))
            .catch((error) => {
                console.warn(error)
            });
    })
    const menuRequest = new Promise((resolve) => {
        getMenuConnect({ activity: true, location: 'site' }).then((data) => resolve({ data, alias: 'menu' }))
            .catch((error) => {
                console.warn(error)
            });
    })
    const menuOfficeRequest = new Promise((resolve) => {
        getMenuConnect({
            activity: true,
            location: 'office'
        }).then((data) => resolve({ data, alias: 'menuOffice' }))
            .catch((error) => {
                console.warn(error)
            });
    })
    const menuFooterRequest = new Promise((resolve) => {
        getMenuConnect({
            activity: true,
            location: 'footer'
        }).then((data) => resolve({ data, alias: 'menuFooter' }))
            .catch((error) => {
                console.warn(error)
            });
    })
    const menuAdminRequest = new Promise((resolve) => {
        getMenuConnect({
            activity: true,
            location: 'admin'
        }).then((data) => resolve({ data, alias: 'menuAdmin' }))
            .catch((error) => {
                console.warn(error)
            });
    })

    const settingsResRequest = new Promise((resolve) => {
        getSettingsConnect('display').then((data) => resolve({ data, alias: 'settingsRes' }))
            .catch((error) => {
                console.warn(error)
            });
    })
    const headers = {
        headers: {
            ClientNodeIp: moment().format('1DD.ss.2MM.3mm'),
            ...lang ? { 'Accept-Language': lang } : {}
        }
    };
    const fetchCaptchaRequest = new Promise((resolve) => {
        fetch(`${API_SERVER_URL}/api/v1/captcha`, headers).then((data) => resolve({ data, alias: 'fetchCaptcha' }))
            .catch((error) => {
                console.warn(error)
            });
    })

    const res = await getAllResponses([currenciesRequest, translatesRequest, seoResRequest, timeResRequest, menuRequest, menuOfficeRequest, menuFooterRequest, menuAdminRequest, settingsResRequest, fetchCaptchaRequest])

    const currency = res?.currencies?.data?.find((el) => el.main === true)?.code || 'USD';
    const seo = res?.seoRes?.data?.data || null;
    let projectTimeZone = 'Asia/Aqtau';
    const time = moment.tz(res?.timeRes?.data?.date, 'YYYY-MM-DD HH:mm:ss', projectTimeZone);
    const offset = time.diff(moment(new Date()));
    const settings = res?.settingsRes?.data?.data || null;
    const dataCaptcha = res?.fetchCaptcha.status === 200 ? await res?.fetchCaptcha.json() : null;
    const captcha = dataCaptcha ? dataCaptcha.data : dataCaptcha;

    return {
        captcha,
        currencies: res?.currencies,
        currency,
        offset,
        settings,
        menu: {
            site: res?.menu?.data,
            office: res?.menuOffice?.data,
            footer: res?.menuFooter?.data,
            admin: res?.menuAdmin?.data
        },
        seo,
        activeLangs,
        translates: {
            ...res?.translates?.data,
            widget_translates
        },
        lang: lang
    };
}

// Стандартные данные, актуальные для страниц кабинета и панели управления
export async function getUserStaticProps(params, page, protect = '', protectRoles = null) {
    const lang = params?.lang;
    const defaultProps = await getDefaultStaticProps(lang, page);
    if (!defaultProps) {
        return { notFound: true, revalidate: 10 };
    }
    return {
        props: {
            ...defaultProps,
            protect,
            protectRoles
        },
        revalidate: project?.revalidate
    };
}

// Стандартные данные, актуальные для страниц кабинета и панели управления для динамических роутов
export async function getUserServerSideProps(
    params, page, res, protect = '', protectRoles = null
) {
    res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');

    const lang = params?.lang;

    const defaultProps = await getDefaultStaticProps(lang, page);

    if (!defaultProps) {
        return { notFound: true };
    }
    return {
        props: {
            ...defaultProps,
            protect,
            protectRoles
        }
    };
}

// getStaticPath для активных языков
export const getLangPaths = async () => {
    const langFetchRes = await getLangsConnect({ $or: [{ isDefault: true }, { active: true }] });
    const activeLangs = langFetchRes?.data || [];

    return activeLangs?.map((lang) => ({ params: { lang: lang.alias } }));
};

export const camelizeStyles = (styles) => {
    if (!styles) return styles;
    const replacer = (s) => s.replace(/-./g, (x) => x[1].toUpperCase());
    return Object.keys(styles)?.reduce((acc, curr) => ({
        ...acc,
        [replacer(curr)]: styles[curr]
    }),
    {});
};

export const checkFileSize = (image, scale) =>
    new Promise((resolve) => {
        let img = new Image();
        img.onload = function () {
            if (this.width > scale || this.height > scale) {
                resolve(false);
            } else {
                resolve(image);
            }
        };
        img.onerror = function () {
            resolve(image);
        };

        img.src = image;
    });

export const useRandom = () => {
    const [randomNumber, setRandomNumber] = useState(0);
    useEffect(() => {
        setRandomNumber(Math.floor(Math.random() * 10000000));
    }, []);
    return randomNumber;
};

export const useWidgetTranslates = (alias) => {
    const { translation } = useContext(LangContext) || {};
    const [trans, setTranslates] = useState(null);

    const { lang } = useContext(UserContext);

    useEffect(() => {
        getWidgetTranslates(lang, alias, translation, setTranslates);
    }, [lang, translation, alias]);

    return trans;
};

export const replaceState =(newUrl, course_id, module_id, lesson_id) => {
    window.history.replaceState({
        ...window.history.state, as: newUrl, url: newUrl, course_id, module_id, lesson_id
    }, '', newUrl)
}
export const getIsSafari = () => {
    return (
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
            return p.toString() === '[object SafariRemoteNotification]';
        })(!window['safari'] ||
            // Перевірка на safari наступний рядок - RS
            // eslint-disable-next-line no-undef
            typeof safari !== 'undefined' && safari.pushNotification)
    );
};

export const WSS_PORT = process.env.NEXT_PUBLIC_WSS_PORT;
export const WSS_HOST = process.env.NEXT_PUBLIC_WSS_HOST;

export const getFullDateFormatString = (settings) => {
    const dateFormat = () => {
        switch (settings?.date_format) {
        case '0': {
            return 'MMMM DD, YYYY';
        }
        case '1': {
            return 'YYYY-MM-DD';
        }
        case '2': {
            return 'MM/DD/YYYY';
        }
        case '3': {
            return 'DD.MM.YYYY';
        }
        default:
            return 'YYYY-MM-DD';
        }
    };
    const timeFormat = () => {
        switch (settings?.time_format) {
        case '0':
            return 'hh:mm a';
        case '1':
            return 'hh:mm A';
        case '2':
            return 'HH:mm';
        default:
            return 'HH:mm';
        }
    };

    return `${dateFormat()} ${timeFormat()}`;
};

export const refreshChannelWS = (userToken, lang) => {
    dataSender(
        `${API_SERVER_URL}/api/v1/websockets/channel_ping`,
        'POST',
        {},
        () => {
        },
        () => {
        },
        userToken,
        lang
    );
}
export const getIsTouchDevice = () => {
    if (typeof window !== 'undefined' && window?.navigator) {
        if ('maxTouchPoints' in navigator) {
            if (navigator.maxTouchPoints > 0) {
                const mQ = matchMedia?.('(pointer: coarse)');
                return !!mQ.matches && navigator.maxTouchPoints > 0;
            }
            return false;
        } else if ('msMaxTouchPoints' in navigator) {
            if (navigator.msMaxTouchPoints > 0) {
                const mQ = matchMedia?.('(pointer: coarse)');
                return !!mQ.matches && navigator.msMaxTouchPoints > 0;
            }
            return false;
        } else {
            const mQ = matchMedia?.('(pointer: coarse)');
            if (mQ?.media === '(pointer: coarse)') {
                return !!mQ.matches;
            } else if ('orientation' in window) {
                return true; // Deprecated, but good fallback
            } else {
                // Only as a last resort, fall back to user agent sniffing
                const UA = navigator.userAgent;
                return /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
            }
        }
    }
    return false;
};
export const useIsTouchDevice = () => {
    /**
     *
     * function copied from
     * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
     *
     * */

    const [hasTouchScreen, setHasTouchScreen] = useState(false);

    useEffect(() => {
        setHasTouchScreen(getIsTouchDevice());
    }, []);

    return hasTouchScreen;
};

export const getPusherOptions = (userToken, lang) => {
    const apiUrl = new URL(API_SERVER_URL);
    return {
        key: 'key',
        dev: process.env.NODE_ENV === 'development',
        cluster: 'mt1',
        forceTLS: false,
        enabledTransports: ['ws', 'wss'],
        wsHost: WSS_HOST,
        wsPort: WSS_PORT,
        wssPort: WSS_PORT,
        scheme: apiUrl.protocol,
        authorizer: (channel) => {
            return {
                authorize: (socketId, callback) => {
                    return dataSender(
                        `${API_SERVER_URL}/broadcasting/auth`,
                        'POST',
                        { socket_id: socketId, channel_name: channel.name },
                        (data) => {
                            callback(false, data.data);
                        },
                        () => {},
                        userToken,
                        lang
                    );
                }
            };
        }
    }
}