import React from 'react';
import { UseQuickNotifications } from '../utils/UseQuickNotifications';
import { Div } from '../../hybrid/wrappers';
import NotificationsQuick from '../../widgets/notifications/NotificationsQuick/NotificationsQuick';
import getStyles from '../../project/styles/layouts-styles/office-layout-styles';
import Styles from '../../common/decorators/Styles';


const QuickNotificationsAlert = ({ styles }) => {
    const { quickNotifications } = UseQuickNotifications();

    return (
        <div>
            {quickNotifications?.length ?
                <Div styles={styles.notificationsQuickWrapper}>
                    {quickNotifications.map((el) =>
                        <NotificationsQuick key={el?.id} notification={el} />)}
                </Div>
                : null}
        </div>
    );
};

export default Styles(QuickNotificationsAlert, getStyles);