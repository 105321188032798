import React, { useContext, useMemo } from 'react';
import Head from 'next/head';
import UserContext from '../../common/utils/getContext';
import { useRouter } from 'next/router';
import { SITE_URL } from '../../common/utils/utils';
import PropTypes from 'prop-types';
const project = require('../../scripts/configs/project');

const SeoData = ({
    data, title, alias
}) => {
    const router = useRouter();

    const {
        lang, seo, activeLangs
    } = useContext(UserContext);

    const link = useMemo(() => {
        if (router.asPath.indexOf(`/${lang}/`) === 0){
            return router.asPath.slice(`/${lang}/`.length - 1)
        } else if (router.asPath !== `/${lang}`){
            return router.asPath
        }
        return ''
    }, [lang, router.asPath]);
    const validatedTitle = useMemo(() =>
        typeof title === 'string'
            ? title
            : title?.props?.defaultStr ? title?.props?.defaultStr : '',
    [title])
    const seoData = useMemo(() => data, [data]);
    const seoDataDefault = useMemo(() => seo?.find((s) => s?.alias === alias) || seo?.find((s) => s.alias === 'default') || null, [alias, seo])
    const meta = useMemo(() => {
        if (!seoDataDefault) return null;
        const accum = {
            title: null,
            description: null,
            keywords: null,
            seo_image: null
        };
        if (seoDataDefault.seo_title && typeof seoDataDefault.seo_title === 'object'){
            accum.title = seoDataDefault?.seo_title?.[lang] || null
        } else {
            accum.title = seoDataDefault.seo_title
        }
        if (seoDataDefault.seo_description && typeof seoDataDefault.seo_description === 'object'){
            accum.description = seoDataDefault.seo_description?.[lang] || null
        } else {
            accum.description = seoDataDefault.seo_description
        }
        if (seoDataDefault.seo_keywords && typeof seoDataDefault.seo_keywords === 'object'){
            accum.keywords = seoDataDefault.seo_keywords?.[lang] || null
        } else {
            accum.keywords = seoDataDefault.seo_keywords
        }
        if (seoDataDefault?.seo_image && typeof seoDataDefault?.seo_image === 'object'){
            accum.seo_image = typeof seoDataDefault?.seo_image?.[lang] === 'string' ? seoDataDefault?.seo_image?.[lang] : typeof seoDataDefault?.seo_image?.[lang]?.url === 'string' ? seoDataDefault?.seo_image?.[lang]?.url : null
        } else {
            accum.seo_image = seoDataDefault?.seo_image
        }
        return accum;
    }, [seoDataDefault, lang]);

    const metaTitle = useMemo(() => `${(typeof seoData?.seo_title === 'string' ? seoData?.seo_title : false) || validatedTitle || ''}${(validatedTitle || typeof seoData?.seo_title === 'string') && meta?.title ? ' - ' : ''}${meta?.title || ''}`, [seoData?.seo_title, validatedTitle, meta?.title])

    const metaDescription = useMemo(() => typeof seoData?.seo_description === 'string' ?
        seoData?.seo_description :
        typeof seoData?.seo_description?.[lang] === 'string' ? seoData?.seo_description?.[lang] :
            meta?.description,
    [seoData?.seo_description, lang, meta?.description])

    const metaKeyWords = useMemo(() => typeof seoData?.seo_keywords === 'string' ?
        seoData?.seo_keywords :
        typeof seoData?.seo_keywords?.[lang] === 'string' ?
            seoData?.seo_keywords?.[lang] :
            meta?.keywords,
    [seoData?.seo_keywords, lang, meta?.keywords])

    const metaImage = useMemo(() => typeof seoData?.seo_image === 'string' ?
        seoData?.seo_image :
        typeof seoData?.seo_image?.links?.preview === 'string' ?
            seoData.seo_image.links.preview :
            typeof seoData?.seo_image === 'string' ? seoData?.seo_image :
                typeof seoData?.seo_image?.[lang] === 'string' ?
                    seoData?.seo_image?.[lang] :
                    typeof seoData?.seo_image?.[lang]?.url === 'string' ?
                        seoData?.seo_image?.[lang]?.url :
                        meta?.seo_image
    , [seoData?.seo_image, lang, meta?.seo_image])

    const defaultLang = useMemo(() => activeLangs.find((language) => language.isDefault) || {}, [activeLangs])
    const isMainCopy = router?.asPath === `/${defaultLang.alias}` || project.rootSeoUrl && router?.asPath === `/${defaultLang.alias}${project.rootSeoUrl}`
    const isMainLangCopy = project.rootSeoUrl && router?.asPath === `/${lang}${project.rootSeoUrl}`
    const canonical = isMainCopy
        ? `${SITE_URL}/`
        : isMainLangCopy
            ? `${SITE_URL}/${lang}`
            : data?.canonical || `${SITE_URL}/${lang}${router.asPath !== `/${lang}` ? router.asPath : ''}`
    if (!validatedTitle) return null;
    return (
        <Head>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <meta prefix='og: http://ogp.me/ns#' />
            {
                activeLangs?.map((l) =>
                    <link key={`metaalternate${l.alias}${link}`} rel='alternate' href={`${SITE_URL}/${l.alias}${link}`} hrefLang={l.alias}/>)
            }
            {
                process.env.NEXT_PUBLIC_NO_FOLLOW &&
                <meta name='robots' content='noindex, nofollow' key='robots' />

            }
            <meta name='viewport' content='initial-scale=1.0, width=device-width' key='viewport'/>
            {
                metaTitle ?
                    <>
                        <meta key='og:title' property='og:title' content={metaTitle} />
                        <title key='title'>{metaTitle}</title>
                    </>
                    : null
            }
            <meta key='og:site' property='og:site' content={project.company} />
            <meta key='og:site_name' property='og:site_name' content={project.company} />
            {
                metaDescription ?
                    <>
                        <meta key='description' name='description' content={metaDescription}/>
                        <meta key='og:description' property='og:description' content={metaDescription} />
                    </>
                    : null
            }
            {
                metaKeyWords ?
                    <meta key='keywords' name='keywords' content={metaKeyWords}/>
                    : null
            }
            {
                isMainCopy || isMainLangCopy || data?.canonical || !router?.query?.lang && router?.asPath !== '/' ?
                    <>
                        <meta key='og:url' property='og:url' content={canonical} />
                        <link key='canonical' rel='canonical' href={canonical}/>
                    </>
                    : null
            }
            {
                metaImage ?
                    <>
                        <meta property='image' content={metaImage}/>
                        <meta property='og:image' content={metaImage}/>
                    </>
                    : null
            }
        </Head>
    )
};

SeoData.propTypes = {
    data: PropTypes.object,
    title: PropTypes.string,
    canonical: PropTypes.string
};

export default SeoData;