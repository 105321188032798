import React, {
    useState, useEffect, useRef, useContext
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import { getGeoDataKz } from '../../../../common/utils/memo';
import { API_SERVER_URL } from '../../../../common/utils/utils';
import FormSelect from './FormSelect'
import UserContext from '../../../../common/utils/getContext';

const state = [0, 'delivery_kz_state_id']
const area = [0, 'delivery_kz_area_id']
const county = [0, 'delivery_kz_county_id']
const village_1 = [0, 'delivery_kz_village_1_id']
const village_2 = [0, 'delivery_kz_village_2_id']

const getIsState = (a) => {
    if (state.indexOf(a) !== -1) {
        return state.indexOf(a)
    } else {
        return false
    }
}
const getIsArea = (a) => {
    if (area.indexOf(a) !== -1) {
        return area.indexOf(a)
    } else {
        return false
    }
}
const getIsCounty = (a) => {
    if (county.indexOf(a) !== -1) {
        return county.indexOf(a)
    } else {
        return false
    }
}
const getIsVillage_1 = (a) => {
    if (village_1.indexOf(a) !== -1) {
        return village_1.indexOf(a)
    } else {
        return false
    }
}
const getIsVillage_2 = (a) => {
    if (village_2.indexOf(a) !== -1) {
        return village_2.indexOf(a)
    } else {
        return false
    }
}
const getDisabled = (attribute, formData) => {
    if (getIsArea(attribute)) {
        return !Boolean(formData[state[getIsArea(attribute)]])
    }
    if (getIsCounty(attribute)) {
        return !Boolean(formData[area[getIsCounty(attribute)]])
    }
    if (getIsVillage_1(attribute)) {
        return !Boolean(formData[county[getIsVillage_1(attribute)]])
    }
    if (getIsVillage_2(attribute)) {
        return !Boolean(formData[village_1[getIsVillage_2(attribute)]])
    }
}
const FormDropdown = (props) => {
    const [options, setOptions] = useState(false)
    const [value, setValue] = useState(props.value)

    const [load, setLoad] = useState(false)
    const { userToken, lang } = useContext(UserContext);
    const nextPage = useRef(null)
    const searchQ = useRef(null)
    const [prevValue, setPrevValue] = useState(false)

    useEffect(() => {
        if (getIsArea(props.attribute) && props.formData[state[getIsArea(props.attribute)]] !== prevValue) {
            setOptions(false)
            setPrevValue(props.formData[state[getIsState(props.attribute)]])
        } else if (getIsCounty(props.attribute) && props.formData[area[getIsCounty(props.attribute)]] !== prevValue) {
            setOptions(false)
            setPrevValue(props.formData[area[getIsCounty(props.attribute)]])
        } else if (getIsVillage_1(props.attribute) && props.formData[area[getIsVillage_1(props.attribute)]] !== prevValue) {
            setOptions(false)
            setPrevValue(props.formData[village_1[getIsVillage_1(props.attribute)]])
            if (nextPage.current) {
                nextPage.current.value = null;
            }
            if (searchQ.current) {
                searchQ.current.value = null;
            }
        } else if (getIsVillage_2(props.attribute) && props.formData[village_1[getIsVillage_2(props.attribute)]] !== prevValue) {
            setOptions(false)
            setPrevValue(props.formData[village_1[getIsVillage_2(props.attribute)]])
            if (nextPage.current) {
                nextPage.current.value = null;
            }
            if (searchQ.current) {
                searchQ.current.value = null;
            }
        }
    }, [prevValue, props.attribute, props.formData])


    useEffect(() => {
        setValue(props.value)
    }, [props.value])
    useEffect(() => {
        (async () => {
            if (props?.value?.value) {
                setValue({
                    id: props?.value?.id,
                    label: props?.value?.name
                })

                // getGeoNameKz(props?.value?.value, userToken, lang, props?.localization?.currency)
                //     .then((res) => {
                //         setValue({
                //             id: props?.value?.id,
                //             label: props?.value?.name
                //         })
                //     })
            }
        })();
    }, [lang, props?.value, userToken])

    const getStates = async (q, page, next, callback) => {
        let url;

        if (getIsState(props.attribute)) {
            url = `${API_SERVER_URL}/api/v1/shop/hand-books?page[size]=50`
        } else if (getIsArea(props.attribute)) {
            url = `${API_SERVER_URL}/api/v1/shop/hand-books/children/${props?.formData?.delivery_kz_state_id?.id}?page[size]=50`
        } else if (getIsCounty(props.attribute)) {
            url = `${API_SERVER_URL}/api/v1/shop/hand-books/children/${props?.formData?.delivery_kz_area_id?.id}?page[size]=50`
        } else if (getIsVillage_1(props.attribute)) {
            url = `${API_SERVER_URL}/api/v1/shop/hand-books/children/${props?.formData?.delivery_kz_county_id?.id}?page[size]=50`
        } else if (getIsVillage_2(props.attribute)) {
            url = `${API_SERVER_URL}/api/v1/shop/hand-books/children/${props?.formData?.delivery_kz_village_1_id?.id}?page[size]=50`
        }

        const data = await getGeoDataKz(url, userToken, lang, props?.localization?.currency)


        setOptions((pre) => {
            if (pre && page) {
                return [...pre, ...data?.data?.map((item) => ({
                    ...item, title: item.name, id: item.id
                }))]
            } else {
                return data?.data?.map((item) => ({
                    ...item, title: item.name, id: item.id
                }))
            }
        })
        if (data?.meta?.current_page < data?.meta?.last_page) {
            if (nextPage.current) {
                nextPage.current.value = data?.meta?.current_page + 1;
            }
        } else {
            if (nextPage.current) {
                nextPage.current.value = null;
            }
        }
        if (q) {
            if (searchQ.current) {
                searchQ.current.value = q;
            }
        } else {
            if (searchQ.current) {
                searchQ.current.value = null;
            }
        }
        if (callback) {
            callback(data?.data?.map((item) => ({
                ...item, label: item.name, value: item.id
            })))
        }
    }
    const searchHandler = async (e, callback) => {
        if (getIsVillage_2(props.attribute)) {
            await getStates(e, false, false, callback)
        }
    }

    const loadOptions = async () => {
        if (!options && !load) {
            setLoad(true)
            await getStates('', false, 1)
            setLoad(false)
        }
    }
    // const onMenuScrollToBottom = async (e, q) => {
    //     if (e.current?.value) {
    //         setLoad(true)
    //         await getStates(q.current?.value, true, e.current?.value)
    //         setLoad(false)
    //     }
    // }
    const changeHandler = (e, attribute) => {
        if (getIsState(attribute) && area[getIsState(attribute)] && county[getIsState(attribute)] && village_1[getIsState(attribute)] && village_2[getIsState(attribute)]) {
            if (e) {
                props.setFormData(area[getIsState(attribute)], null)
                props.setFormData(county[getIsState(attribute)], null)
                props.setFormData(village_1[getIsState(attribute)], null)
                props.setFormData(village_2[getIsState(attribute)], null)
                props.setFormData(attribute, e)
            } else {
                props.setFormData(area[getIsState(attribute)], null)
                props.setFormData(county[getIsState(attribute)], null)
                props.setFormData(village_1[getIsState(attribute)], null)
                props.setFormData(village_2[getIsState(attribute)], null)
                props.setFormData(attribute, null)
            }
        } else if (getIsArea(attribute) && county[getIsArea(attribute)] && village_1[getIsArea(attribute)] && village_2[getIsArea(attribute)]) {
            if (e) {
                props.setFormData(county[getIsArea(attribute)], null)
                props.setFormData(village_1[getIsArea(attribute)], null)
                props.setFormData(village_2[getIsArea(attribute)], null)
                props.setFormData(attribute, e)
            } else {
                props.setFormData(county[getIsArea(attribute)], null)
                props.setFormData(village_1[getIsArea(attribute)], null)
                props.setFormData(village_2[getIsArea(attribute)], null)
                props.setFormData(attribute, null)
            }
        } else if (getIsCounty(attribute) && village_1[getIsCounty(attribute)] && village_2[getIsCounty(attribute)]) {
            if (e) {
                props.setFormData(village_1[getIsCounty(attribute)], null)
                props.setFormData(village_2[getIsCounty(attribute)], null)
                props.setFormData(attribute, e)
            } else {
                props.setFormData(village_1[getIsCounty(attribute)], null)
                props.setFormData(village_2[getIsCounty(attribute)], null)
                props.setFormData(attribute, null)
            }
        } else if (getIsVillage_1(attribute) && village_2[getIsVillage_1(attribute)]) {
            if (e) {
                props.setFormData(village_2[getIsVillage_1(attribute)], null)
                props.setFormData(attribute, e)
            } else {
                props.setFormData(village_2[getIsVillage_1(attribute)], null)
                props.setFormData(attribute, null)
            }
        } else {
            if (e) {
                props.setFormData(attribute, e)
            } else {
                props.setFormData(attribute, null)
            }
        }
    }

    useEffect(() => {
        if (props.preparedLoad) {
            loadOptions()
        }
        // eslint-disable-next-line
    }, [props.preparedLoad])

    const disabled = props?.customDisabled ? props.customDisabled : getDisabled(props.attribute, props.formData)
    return <><input ref={nextPage} type='hidden' /><input ref={searchQ} type='hidden' /><FormSelect
        {...props}
        disabled={disabled}
        options={options}
        isClear={false}
        value={value}
        isLoading={load}
        loadOptions={getIsVillage_2(props.attribute) ? searchHandler : false}
        handlers={{
            ...props.handlers || {},
            onChange: (e, a) => { changeHandler(e, a) },
            onMenuOpen: async (a) => { await loadOptions(a) }
        }}
    /></>
};

FormDropdown.propTypes = {
    attribute: PropTypes.string,
    setFormData: PropTypes.func,
    handlers: PropTypes.object,
    formData: PropTypes.object
};

export default Styles(FormDropdown, getStyles);