import React, {
    useState, useContext, useEffect, useMemo
} from 'react';
import PropTypes from 'prop-types';
import {
    Div, svgSprite, Portal
} from '../../hybrid/wrappers';
import getStyles from '../../project/styles/widget-styles/modal-styles';
import Styles from '../../common/decorators/Styles';
import UserData from './components/UserData';
import UserStats from './components/UserStats';
import ProgressBlock from './components/ProgressBlock';
import UserVolumes from './components/UserVolumes';
import { t } from '../../common/components/T';
import { getWidgetTranslates } from '../../common/utils/utils';
import UserContext from '../../common/utils/getContext';
import Loader from '../plug/Loader';
import { useWindowDimensions } from '../../common/utils/windowDimensions';
import { LangContext } from '../../common/decorators/Language';
import Skeleton from '../plug/Skeleton';
import { userSidebarSkeleton } from '../plug/components/skeleton/patterns';

const RenderData = ({
    styles,
    userData,
    setShowUser,
    setUserData,
    isAbsolute,
    closeHandler,
    trans
}) => {
    const [showUserInfo, setShowUserInfo] = useState(true);

    const widthDimensions = useWindowDimensions();
    const screenWidth = useMemo(() => widthDimensions < 576, [widthDimensions]);
    const loaderStyles = useMemo(() => ({
        loader: {
            padding: '0',
            transform: 'scale(0.5)',
            height: '50px'
        }
    }), []);
    return (
        <Portal closeHandler={closeHandler} fullWidth={screenWidth} fullHeight={screenWidth} customClass='officePage modalTop'>
            <Div
                styles={
                    isAbsolute
                        ? showUserInfo
                            ? { ...styles.userModalInfoContainerFixed, display: 'block' }
                            : { ...styles.userModalInfoContainer, display: 'none' }
                        : showUserInfo
                            ? { ...styles.userModalInfoContainer, display: 'block' }
                            : { ...styles.userModalInfoContainer, display: 'none' }
                }
            >
                <Div styles={styles.userModalInfoWrap}>
                    <Div
                        styles={styles.userModalClose}
                        effects={{ hover: styles.userModalCloseHover }}
                        data-uitest='51_uitest' onClick={() => {
                            setShowUserInfo(!showUserInfo);
                            setShowUser(false);
                            setUserData(false);
                        }}
                    >
                        {svgSprite('arrow-right', {
                            width: '20px',
                            height: '14px'
                        })}
                    </Div>

                    <Div styles={styles.userModalInfoBlock} className='custom-scroll'>
                        <Div styles={styles.userModalHeader}>
                            {t(
                                'user_modal_title', 'Выбранный пользователь', 'widget', trans, 'userModal'
                            )}
                        </Div>
                        <>
                            <Div styles={styles.userModalTopWrap}>
                                <UserData
                                    trans={trans}
                                    styles={styles}
                                    data={{ ...userData, ...userData?.marketing_profile }}
                                    loaderStyles={loaderStyles}
                                />
                            </Div>
                            <Div styles={styles.hr} />
                            {
                                userData.loaded !== false || (
                                    userData.new_partners_number
                                    || userData.active_partners_number
                                    || userData.children_number
                                    || userData.children_count
                                    || userData.partners_number
                                    || userData.descendants_count
                                    || userData.binary_partners
                                ) ?
                                    <UserStats
                                        styles={styles}
                                        data={{ ...userData, ...userData?.marketing_profile }}
                                        trans={trans}

                                    />
                                    : userData?.rankData ?
                                        <Loader compStyles={loaderStyles}/>
                                        : null
                            }

                            {
                                userData?.rankData ?
                                    <ProgressBlock
                                        styles={styles}
                                        rankData={userData.rankData || {}}
                                        trans={trans}
                                    />
                                    : userData.loaded === false && userData?.marketing_profile ? <Loader compStyles={loaderStyles}/> : null
                            }

                            <Div styles={styles.hr} />

                            {
                                userData.loaded === false && !userData?.marketing_profile ? <Loader compStyles={loaderStyles}/> :
                                    <Div>
                                        <UserVolumes styles={styles} data={userData}/>
                                    </Div>

                            }
                            {
                                userData.sponsor ?
                                    <>
                                        <Div styles={styles.hr} />
                                        <Div styles={styles.userModalHeader}>
                                            {/*{t('user_modal_sponsor_title', 'Ваш спонсор', 'widget', trans, 'userModal')}*/}
                                            {t(
                                                'user_modal_sponsor_titleSM', 'Спонсор', 'widget', trans, 'userModal'
                                            )}
                                        </Div>
                                        <Div styles={styles.userModalTopWrap}>
                                            <UserData
                                                styles={styles}
                                                data={{
                                                    ...userData.sponsor,
                                                    ...userData.sponsor?.marketing_profile,
                                                    rankData: userData.sponsor?.marketing_profile?.rank ? {} : false
                                                }}
                                                trans={trans}
                                                // isSponsor={true}
                                            />
                                        </Div>
                                    </>
                                    : userData.loaded === false && userData?.marketing_profile ? <Loader compStyles={loaderStyles}/> : null
                            }

                        </>
                    </Div>
                </Div>
            </Div>
        </Portal>
    );
};
const UserModalInfo = (props) => {
    const {
        styles,
        userData,
        setShowUser,
        setUserData,
        isAbsolute,
        closeHandler
    } = props
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};
    const [showUserInfo, setShowUserInfo] = useState(true);
    const { lang } = useContext(UserContext);
    const [trans, setTranslates] = useState(null);

    useEffect(() => {
        getWidgetTranslates(lang, 'userModal', translation, setTranslates);
    }, [lang, translation]);
    const widthDimensions = useWindowDimensions();

    const screenWidth = useMemo(() => widthDimensions < 576, [widthDimensions]);
    return (
        <Portal closeHandler={closeHandler} fullWidth={screenWidth} fullHeight={screenWidth} customClass='officePage modalTop'>
            <Div
                styles={
                    isAbsolute
                        ? showUserInfo
                            ? { ...styles.userModalInfoContainerFixed, display: 'block' }
                            : { ...styles.userModalInfoContainer, display: 'none' }
                        : showUserInfo
                            ? { ...styles.userModalInfoContainer, display: 'block' }
                            : { ...styles.userModalInfoContainer, display: 'none' }
                }
            >
                <Div styles={styles.userModalInfoWrap}>
                    <Div
                        styles={styles.userModalClose}
                        effects={{ hover: styles.userModalCloseHover }}
                        data-uitest='51_uitest' onClick={() => {
                            setShowUserInfo(!showUserInfo);
                            setShowUser(false);
                            setUserData(false);
                        }}
                    >
                        {svgSprite('arrow-right', {
                            width: '20px',
                            height: '14px'
                        })}
                    </Div>

                    <Div styles={styles.userModalInfoBlock} className='custom-scroll'>
                        <Div styles={styles.userModalHeader}>
                            {t(
                                'user_modal_title', 'Выбранный пользователь', 'widget', trans, 'userModal'
                            )}
                        </Div>
                        <Skeleton
                            pattern={userSidebarSkeleton}
                            wait={Boolean(userData && trans)}
                            Component={RenderData}
                            componentProps={{ ...props, trans }}
                        />
                    </Div>
                </Div>
            </Div>
        </Portal>
    );
};
UserModalInfo.propTypes = {
    styles: PropTypes.object,
    userData: PropTypes.object,
    setShowUser: PropTypes.func,
    setUserData: PropTypes.func,
    isAbsolute: PropTypes.bool,
    closeHandler: PropTypes.func,
    trans: PropTypes.object
};
export default Styles(UserModalInfo, getStyles);