import React, {
    useContext, useMemo, useRef, useState
} from 'react';
import { P, Div } from '../../../../hybrid/wrappers';
import UserContext from '../../../../common/utils/getContext';
import { useWindowDimensions } from '../../../../common/utils/windowDimensions';
import CircleLoader from '../../../plug/CircleLoader';

const OpenSwitcher = ({
    styles,
    setGridData,
    viewData,
    variant,
    afterOpenSwitherClick,
    setActiveTr,
    openSwitcher,
    rowData,
    lineOffset,
    levelOffset,
    loadingStructure = false,
    setLoadingStructure = () => {}
}) => {
    const level = useMemo(() => rowData.depth - levelOffset, [levelOffset, rowData?.depth]);
    const {
        userToken, setUserToken, setAlertData, lang
    } = useContext(UserContext);

    const [checkUser, setCheckUser] = useState(null);
    const canPress = useRef(true);
    const mobile = useWindowDimensions() < 768;
    const getSubData = useMemo(() => {
        if (!(openSwitcher?.handlers && Object.keys(openSwitcher.handlers).length)) return {};
        return Object.keys(openSwitcher.handlers).reduce((accum, handlerName) => {
            accum[handlerName] = () => openSwitcher.handlers[handlerName](
                rowData,
                setAlertData,
                setGridData,
                afterOpenSwitherClick,
                setUserToken,
                userToken,
                setActiveTr,
                viewData,
                variant,
                canPress,
                lang
            );

            return accum;
        }, {});
    }, [openSwitcher?.handlers, rowData, setAlertData, setGridData, afterOpenSwitherClick, setUserToken, userToken, setActiveTr, viewData, variant, lang]);

    const innerStyles = {
        fullScreenLineMargin: { 'margin-left': '7px' },
        fullScreenBottomOffset: { bottom: '48%' },
        leftOffset: { left: `${level * (mobile ? 46 : 46) - 28}px` },
        heightLine: { height: `${75 * lineOffset}px` }
    };

    return (
        <React.Fragment>
            {
                rowData.has_children
                    ?
                    <Div
                        styles={{ ...styles.td__depthWrap }}
                        {...getSubData}
                    >
                        {
                            loadingStructure && checkUser === rowData?.username
                                ?
                                <Div styles={styles.switcherLoader}>
                                    <CircleLoader/>
                                </Div>
                                :
                                !!rowData.has_children
                                    ?
                                    <P
                                        onClick={() => {
                                            setCheckUser(rowData?.username)
                                            setTimeout(() => {
                                                setCheckUser(null);
                                            }, 1000);
                                            setLoadingStructure(true)
                                        }}
                                        styles={styles.td__depth}
                                        effects={{ hover: { cursor: 'pointer', opacity: '0.65' } }}
                                    >{rowData.isOpen ? '–' : '+'}
                                    </P>

                                    :
                                    <Div styles={styles.divShowMenu}>
                                        <P effects={{ hover: { cursor: 'pointer', opacity: '0.65' } }}
                                        >
                                            {rowData.isOpen ? '–' : '+'}
                                        </P>
                                    </Div>
                        }
                    </Div>

                    : <Div styles={{ ...styles.stubSwitcher }}/>
            }
            <React.Fragment>
                {!!level && variant !== 2 &&
                            <React.Fragment>
                                <Div
                                    styles={{
                                        ...styles.horizontLine,
                                        ...innerStyles.fullScreenBottomOffset,
                                        ...innerStyles.fullScreenLineMargin,
                                        ...innerStyles.leftOffset,
                                        ...styles.horizontLineWeb
                                    }}
                                />
                                <Div
                                    styles={{
                                        ...styles.verticalLine,
                                        ...innerStyles.fullScreenBottomOffset,
                                        ...innerStyles.fullScreenLineMargin,
                                        ...innerStyles.leftOffset,
                                        ...innerStyles.heightLine,
                                        ...styles.verticalLineWeb
                                    }}
                                />
                            </React.Fragment>
                }
            </React.Fragment>

        </React.Fragment>

    );
};

export default OpenSwitcher;