import { useMemo } from 'react';
import { useWindowDimensions } from '../../common/utils/windowDimensions';
import {
    Div, FakeLink, P, svgSprite
} from '../../hybrid/wrappers';

const InfoMarker = ({
    styles, contactData, setShowModal
}) => {
    const isMobile = useWindowDimensions() < 768;

    const socialWeb = useMemo(() => {
        if (!contactData) return;
        const socialPosition = [];

        const socialInit = ['facebook', 'instagram', 'twitter', 'vk', 'ok', 'tiktok', 'clubhouse'];
        const keysSocial = Object.keys(contactData)

        keysSocial.forEach(el => socialInit.includes(el) && !!contactData[el] ? socialPosition.push({ title: el, value: contactData[el] }) : null)

        return socialPosition;
    }, [contactData]);

    const messengeres = (val) => {
        if (!contactData) return;
        const filteredMessenger = [];

        const messengerInit = ['telegram', 'viber', 'whatsapp'];
        contactData.contacts.forEach(el => messengerInit.includes(el.type) && el.value === val ? filteredMessenger.push({ title: el.type, value: el.value }) : null)

        return filteredMessenger;
    };

    const contacts = () => {
        if (!contactData) return;
        const filteredContact = [];

        const contactInit = ['email', 'tel', 'skype'];

        contactData?.contacts?.forEach(el => contactInit?.includes(el.type) && !!el.value ? filteredContact.push(el) : null)

        return filteredContact;
    };

    return (
        <>
            <Div>
                <P styles={styles.containerTitle}>{contactData?.name}</P>
                <P styles={styles.infoType} >{contactData?.type?.title}</P>
            </Div>
            {
                isMobile &&
                    <Div onClick={() => setShowModal(false)} styles={styles.closeIcon}>{ svgSprite('cross', {
                        width: 10, height: 10, fill: styles.variable.greySuperDarkColor
                    })}</Div>
            }
            <Div styles={{ 'width': '100%' }}>
                {contacts()?.map((elem, idx) => {
                    const {
                        type, value, description
                    } = elem
                    const contact = type === 'tel'
                        ? `tel:${value}`
                        : type === 'email'
                            ? `mailto:${value}`
                            : `skype:${value}`
                    return (
                        <>
                            <Div styles={styles.contactBlock}>
                                <Div styles={styles.contactSvg}>
                                    {svgSprite(type === 'email'
                                        ? 'envelope' : type === 'tel'
                                            ? 'phone' : 'skype', {
                                        width: '24px',
                                        height: '24px',
                                        fill: styles.variable.greyExtraDarkColor
                                    })}
                                </Div>
                                <Div styles={styles.BlockContainer}>
                                    <P styles={styles.blockfirstText}>
                                        <FakeLink follow={true} styles={{
                                            color: styles.variable.blueColor, outline: 'none', 'border': 'none', 'box-shadow': 'none'
                                        }} href={contact}>
                                            {value}
                                        </FakeLink>
                                    </P>
                                    <P styles={styles.blockSecondText}>{description}</P>
                                </Div>
                                {type === 'tel' &&
                                <Div styles={{ display: 'flex' }}>
                                    {messengeres(value)?.map(el => {
                                        switch (el.title) {
                                        case 'telegram': return (
                                            <FakeLink styles={styles.iconMargin} href={`http://t.me/${el.value}`} target='_blank'>
                                                {svgSprite('telegram', {
                                                    width: '24px',
                                                    height: '24px',
                                                    fill: styles.variable.blueSkyHalfTransparentColor
                                                })}
                                            </FakeLink>
                                        );
                                        case 'viber': return (
                                            <FakeLink styles={styles.iconMargin} href={`viber://add?number=${el.value}`} target='_blank'>
                                                {svgSprite('viber-colored', {
                                                    width: '24px',
                                                    height: '24px',
                                                    fill: styles.variable.bluePurpleColor
                                                })}
                                            </FakeLink>
                                        );
                                        case 'whatsapp': return (
                                            <FakeLink styles={styles.iconMargin} href={`https://wa.me/${el.value}`} target='_blank'>
                                                {svgSprite('whatsapp', {
                                                    width: '24px',
                                                    height: '24px',
                                                    fill: styles.variable.greenDeepDarkColor
                                                })}
                                            </FakeLink>
                                        );
                                        }
                                    })}
                                </Div>
                                }
                            </Div>

                            {
                                contacts()[idx + 1] || contactData?.custom_contacts?.length ?
                                    <Div styles={styles.borderLine}></Div>
                                    : null
                            }
                        </>
                    )
                })}

                {!!contactData?.custom_contacts?.length &&
                    <>
                        {
                            contactData.custom_contacts.map((item, idx) =>
                                <>
                                    <Div styles={styles.contactBlock}>
                                        <Div>{svgSprite('pin', {
                                            width: '24px',
                                            height: '24px',
                                            fill: styles.variable.greyExtraDarkColor

                                        })}</Div>
                                        <Div styles={styles.BlockContainer}>
                                            <P styles={styles.blockfirstAdrres}>{item.value}</P>
                                            <P styles={styles.blockSecondText}>{item.description}</P>
                                        </Div>
                                    </Div>

                                    {
                                        contactData.custom_contacts[idx + 1] || socialWeb?.length ? <Div styles={styles.borderLine}></Div> : null
                                    }
                                </>)
                        }
                    </>
                }

            </Div>

            {socialWeb?.length ?
                <Div>
                    <P styles={styles.socialTitle}>Мы в соц. сетях</P>
                    <Div styles={{ display: 'flex' }}>
                        {socialWeb?.map(el => {
                            switch (el.title) {
                            case 'facebook': return (
                                <FakeLink styles={styles.iconMargin} href={`https://facebook.com/${el.value}`} target='_blank'>
                                    {svgSprite('facebook-colored', {
                                        width: '24px',
                                        height: '24px',
                                        fill: styles.variable.blueDeepAddColor
                                    })}
                                </FakeLink>
                            );
                            case 'twitter': return (
                                <FakeLink href={`https://twitter.com/${el.value}`} target='_blank' styles={styles.iconMargin}>
                                    {svgSprite('twitter-colored', {
                                        width: '24px',
                                        height: '24px',
                                        fill: styles.variable.blueDeepAddColor
                                    })}
                                </FakeLink>
                            );
                            case 'instagram': return (
                                <FakeLink styles={styles.iconMargin} href={`https://www.instagram.com/${el.value}`} target='_blank'>
                                    {svgSprite('instagram-colored', {
                                        width: '24px',
                                        height: '24px'
                                    })}
                                </FakeLink>
                            );
                            case 'clubhouse': return (
                                <FakeLink styles={styles.iconMargin} href={`https://www.joinclubhouse.com/${el.value}`} target='_blank'>
                                    {svgSprite('clubhouse-colored', {
                                        width: '24px',
                                        height: '24px'
                                    })}
                                </FakeLink>
                            );
                            case 'tiktok': return (
                                <FakeLink styles={styles.iconMargin} href={`https://www.tiktok.com/${el.value[0] === '@' ? el.value : '@' + el.value}`} target='_blank'>
                                    {svgSprite('tiktok-colored', {
                                        width: '24px',
                                        height: '24px'
                                    })}
                                </FakeLink>
                            );
                            case 'vk': return (
                                <FakeLink styles={styles.iconMargin} href={`https://vk.com/${el.value}`} target='_blank'>
                                    {svgSprite('vkontakte-colored', {
                                        width: '24px',
                                        height: '24px'
                                    })}
                                </FakeLink>
                            );
                            case 'ok': return (
                                <FakeLink styles={styles.iconMargin} href={`https://ok.ru/${el.value}`} target='_blank'>
                                    {svgSprite('odnoklassniki-colored', {
                                        width: '24px',
                                        height: '24px'
                                    })}
                                </FakeLink>
                            )
                            }
                        })}
                    </Div>
                </Div>
                : null}
        </>
    )
}

export default InfoMarker;