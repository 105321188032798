// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useCallback, useRef, useEffect
} from 'react';
import { Div, P } from '../../../hybrid/wrappers';
import GridSwitcher from '../GridSwitcher';
import OpenSwitcher from './open/OpenSwitcher';
import T, { t } from '../../../common/components/T';

import {
    TbodyComponent, TrComponent, TdComponent
} from './wrappers'

const GridTbody = ({
    tableData,
    styles,
    setGridData,
    thead,
    widthArr,
    variant,
    openSwitcher,
    handlers = {},
    viewData,
    gridStyles,
    sticky,
    refTable,
    gridName,
    refTableChild,
    translation,
    checkAdmin,
    changePosition,
    draggable = false
}) => {
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window['safari'] || typeof safari !== 'undefined');
    const [tbody, setTbody]= useState(tableData?.data || [])
    useEffect(() => {
        setTbody(tableData?.data || [])
    }, [tableData?.data]);
    // const tbody = tableData?.data || [];
    const [activeTr, setActiveTr] = useState(false);
    const getHandlersTR = useCallback((rowData) => {
        if (!(handlers?.tr && Object.keys(handlers.tr).length && rowData)) return {};

        return Object.keys(handlers.tr).reduce((accum, handlerName) => {
            accum[handlerName] = () => handlers.tr[handlerName](rowData, setGridData);
            return accum;
        }, {});
    }, [handlers?.tr, setGridData]);

    const getHandlersTD = useCallback((rowData, cellData, index, cellIndex) => {
        if (!(handlers?.td && handlers.td[cellIndex] && Object.keys(handlers.td[cellIndex]).length && rowData)) return {};

        return Object.keys(handlers.td[cellIndex]).reduce((accum, handlerName) => {
            accum[handlerName] = () => handlers.td[cellIndex][handlerName](
                rowData, cellData, index, cellIndex, setGridData
            );
            return accum;
        }, {});
    }, [handlers?.td, setGridData]);

    const lineOffsetCalc = useCallback((index) => {
        if (!tbody[index - 1]) {
            return 1;
        }
        let count = 1;
        const newArray = tbody.slice(0, index).reverse();
        for (let i = 0;i <= newArray.length;i++) {
            if (newArray[i] && newArray[i].depth > tbody[index].depth) {
                count++;
            } else {
                break;
            }
        }
        return count;
    }, [tbody]);

    const margin = useCallback((level) => {
        if (!!level && level >= 0 && variant !== 2){
            return { 'margin-left': `${level * 46}px` }
        }
        return {};
    }, [variant]);

    const getCustomStyles = (name, index) => gridStyles[name] || gridStyles[index] || {};
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);

    const handleSort = async () => {
        //duplicate items
        let _tbody = [...tbody];

        //remove and save the dragged item content
        const draggedItemContent = _tbody.splice(dragItem?.current?.index, 1)[0];

        //switch the position
        _tbody.splice(dragOverItem?.current?.index, 0, draggedItemContent);
        await changePosition(dragItem?.current?.id, dragOverItem?.current?.index)

        //update the actual array
        setTbody(_tbody);
        //reset the position ref
        dragItem.current = null;
        dragOverItem.current = null;
    };

    return (
        <TbodyComponent sticky={sticky}>
            {
                tbody.length === 0 && <TrComponent sticky={sticky}><TdComponent sticky={sticky} styles={{ ...styles.noData }} colSpan={99999}><T textName='gridNoData' defaultStr='Нет данных' page='all' /></TdComponent></TrComponent>
            }
            {
                tbody?.length > 0 && tbody.map((rowData, index) =>
                    <React.Fragment key={index}>
                        <TrComponent
                            sticky={sticky}
                            {...getHandlersTR(rowData)}
                            styles={{
                                ...draggable? { cursor: 'grabbing' }: {}, ...Object.keys(getHandlersTR(rowData)).length && styles.cursorPointer, ...gridStyles?.tr || (draggable? { cursor: 'grabbing' }: {})
                            }}
                            effects={
                                gridStyles?.tr?.effects || { hover: styles.trHover }
                            }
                            className='tr-component'
                            draggable={draggable}
                            // onDragOver={draggable ? (e) => e.preventDefault() : null}
                            onDragStart={draggable ? () => dragItem.current = { index: index, id: rowData?.id }: null}
                            onDragEnter={draggable ? () => dragOverItem.current = { index: index, id: rowData?.id } : null}
                            onDragEnd={draggable ? handleSort : null}
                        >
                            {
                                Object.keys(rowData).map((cellData, cellIndex) =>
                                    <React.Fragment key={`gridCell${cellIndex}`}>
                                        {
                                            thead[cellIndex]?.name === cellData
                                        &&
                                            <TdComponent
                                                {...getHandlersTD(rowData, cellData, index, cellIndex)}
                                                sticky={sticky}
                                                styles={{
                                                    ...styles.widthFitContent,
                                                    'white-space': 'initial',
                                                    ...widthArr[cellIndex] || {},
                                                    ...sticky?.leftStickyColumnCount ? {}
                                                        : { ...styles.td, ...isSafari ? styles.tdSafari : {} },
                                                    ...thead.length === cellIndex + 1
                                                        ? styles.tdLast
                                                        : {},
                                                    ...gridStyles?.td?.styles || {},
                                                    ...getCustomStyles(cellData, cellIndex).td?.styles || {},
                                                    ...rowData?.name?.alias && gridStyles?.cellStyles
                                                        ? {
                                                            ...gridStyles?.cellStyles[cellData]?.td?.styles,
                                                            ...gridStyles?.cellStyles[`${cellData}_${rowData?.name?.alias}`]?.td?.styles
                                                        } : {}
                                                }}
                                                effects={getCustomStyles(cellData, cellIndex).td?.effects || {}}
                                            >
                                                {
                                                    rowData.activeRow && rowData.activeRow !== 'disabled'&& <Div styles={{
                                                        ...styles.activeRow || {},
                                                        ...cellIndex === 0 ? styles.activeRowFirst : {},
                                                        ...thead.length === cellIndex + 1 ? styles.activeRowLast : {},
                                                        ...gridStyles?.activeRow?.styles || {},
                                                        ...getCustomStyles(cellData, cellIndex).activeRow?.styles || {},
                                                        ...rowData.addedComponent ? { ...gridStyles.activeRow?.parentDiv || {} } : {},
                                                        ...isSafari ? styles.activeRowSafari : {},
                                                        ...rowData?.name?.alias && gridStyles?.cellStyles
                                                            ? {
                                                                ...gridStyles?.cellStyles[cellData]?.activeRow?.styles,
                                                                ...gridStyles?.cellStyles[`${cellData}_${rowData?.name?.alias}`]?.activeRow?.styles
                                                            } : {}
                                                    }}
                                                    />
                                                }
                                                {
                                                    rowData.deleted ? <Div styles={{
                                                        ...styles.activeRow || {},
                                                        ...styles.deletedRow || {},
                                                        ...cellIndex === 0 ? styles.activeRowFirst : {},
                                                        ...thead.length === cellIndex + 1 ? styles.activeRowLast : {},
                                                        ...gridStyles?.activeRow || {},
                                                        ...isSafari ? styles.activeRowSafari : {}
                                                    }}
                                                    /> : rowData.blocked && <Div styles={{
                                                        ...styles.activeRow || {},
                                                        ...styles.blockedRow || {},
                                                        ...cellIndex === 0 ? styles.activeRowFirst : {},
                                                        ...thead.length === cellIndex + 1 ? styles.activeRowLast : {},
                                                        ...gridStyles?.activeRow || {},
                                                        ...isSafari ? styles.activeRowSafari : {}
                                                    }}
                                                    />
                                                }

                                                <Div
                                                    styles={{
                                                        ...openSwitcher ? styles.tdFixedHeight : {},
                                                        ...cellIndex === 0 ? margin(Math.max(rowData.depth - tbody[0].depth, 0)) : {},
                                                        ...cellIndex === 0 && !['center', 'right'].includes(thead[cellIndex]?.align) || thead[cellIndex]?.align === 'left'
                                                            ? styles.tdAlignLeft
                                                            : thead[cellIndex]?.align === 'right' ? styles.tdAlignRight : styles.tdAlignCenter,
                                                        ...gridStyles?.td?.div || {},
                                                        ...rowData.addedComponent ? { ...gridStyles.td?.parentDiv || {} } : {},
                                                        ...getCustomStyles(cellData, cellIndex).td?.div || {},
                                                        ...rowData.addedComponent ? { ...getCustomStyles(cellData, cellIndex).td?.parentDiv } : {},
                                                        ...rowData?.name?.alias && gridStyles?.cellStyles
                                                            ? {
                                                                ...gridStyles?.cellStyles[cellData]?.td?.div,
                                                                ...gridStyles?.cellStyles[`${cellData}_${rowData?.name?.alias}`]?.td?.div
                                                            } : {},
                                                        ...thead[cellIndex]?.nowrap?.td ? { 'white-space': 'nowrap' } : {},
                                                        ...thead[cellIndex]?.nowrap?.td === false ? { 'white-space': 'normal' } : {}
                                                    }}
                                                    effects={getCustomStyles(cellData, cellIndex).td?.div?.effects || {}}
                                                    className={gridStyles?.td?.div?.className || ''}
                                                >
                                                    {
                                                        cellIndex === 0 && openSwitcher && (checkAdmin == false && rowData?.depth < 5 ? true : checkAdmin == true ? true : false)
                                                        &&
                                                            <OpenSwitcher
                                                                styles={styles}
                                                                partnersCount={rowData.partner_stats?.descendants_count}


                                                                viewData={viewData}

                                                                setGridData={setGridData}
                                                                variant={variant}

                                                                afterOpenSwitherClick={(data) => openSwitcher.afterOpenSwitherClick(rowData, data)}
                                                                openSwitcher={openSwitcher}
                                                                rowData={rowData}
                                                                setActiveTr={setActiveTr}
                                                                lineOffset={lineOffsetCalc(index)}
                                                                levelOffset={tbody[0].depth}
                                                                loadingStructure={openSwitcher.loadingStructure}
                                                                setLoadingStructure={openSwitcher.setLoadingStructure}

                                                            />

                                                    }
                                                    <Div
                                                        styles={{
                                                            ...styles.switcherWrap,
                                                            ...!isSafari ? styles.switcherWrapRelative : {},
                                                            ...gridStyles.td?.switcher,
                                                            ...getCustomStyles(cellData, cellIndex).td?.switcher || {},
                                                            ...rowData?.name?.alias && gridStyles?.cellStyles
                                                                ? {
                                                                    ...gridStyles?.cellStyles[cellData]?.td?.switcher,
                                                                    ...gridStyles?.cellStyles[`${cellData}_${rowData?.name?.alias}`]?.td?.switcher
                                                                } : {}
                                                        }}
                                                    >
                                                        <GridSwitcher
                                                            idx={cellIndex}
                                                            alias={rowData.id}
                                                            val={typeof rowData[`${cellData}`] === 'object' ? { ...rowData[`${cellData}`] } : rowData[`${cellData}`]}
                                                            rowIndex={index}
                                                            tbody={tbody}
                                                            thead={thead}
                                                            styles={styles}

                                                            handlers={handlers}
                                                            setGridData={setGridData}
                                                            activeTr={activeTr}
                                                            setActiveTr={setActiveTr}
                                                            gridStyles={gridStyles}
                                                            align={cellIndex === 0 && !['center', 'right'].includes(thead[cellIndex]?.align) || thead[cellIndex]?.align === 'left' ? 'left' : thead[cellIndex]?.align || 'center'}
                                                            refTable={refTable}
                                                            gridName={gridName}
                                                            refTableChild={refTableChild}
                                                            translation={translation}
                                                        />
                                                    </Div>
                                                    {
                                                        thead[cellIndex]?.type === 'login' && cellIndex === 0 && !!rowData.deleted ?
                                                            <P styles={styles.deleteUserLabel}>
                                                                {t(
                                                                    'gridDeletedUser', 'Удален', 'widget', translation, 'grid'
                                                                )}
                                                            </P>
                                                            : cellIndex === 0 && rowData.is_new_partner ?
                                                                <P styles={styles.newUserLabel}>
                                                                    {t(
                                                                        'gridNewUser', 'Новый', 'widget', translation, 'grid'
                                                                    )}
                                                                </P>
                                                                : thead[cellIndex]?.type === 'login' && cellIndex === 0 && !!rowData.blocked ?
                                                                    <P styles={styles.blockedUserLabel}>
                                                                        {t(
                                                                            'gridBlockedUser', 'Заблокирован', 'widget', translation, 'grid'
                                                                        )}
                                                                    </P>
                                                                    : null
                                                    }
                                                </Div>
                                            </TdComponent>

                                        }
                                    </React.Fragment>)
                            }
                        </TrComponent>
                        {
                            rowData.addedComponent ?
                                <TrComponent sticky={sticky} colSpan={99999}>
                                    <TdComponent sticky={sticky} styles={{ ...styles.addedComponentInner }} colSpan={99999}>{rowData.addedComponent}</TdComponent>
                                </TrComponent> : null
                        }
                    </React.Fragment>)
            }
        </TbodyComponent>
    );
};

export default GridTbody;