// import { svgSprite } from '../../hybrid/wrappers';
import React from 'react';

export const textNotification1 = (notification, css) => {
    let { subject, text } = notification;
    return (
        <>
            <p className={css.NotificationsHeaderItemTitle}>{subject}</p>
            <p className={css.NotificationsHeaderItemSubTitle}>
                {text && <div dangerouslySetInnerHTML={{ __html: text }}/>}
            </p>
        </>
    );
}
export const getNotificationsConfig =(firstSymbol) => {
    return {
        document_posted: {
            icon: firstSymbol === '+' ? 'menu-plus' : firstSymbol === '-' ? 'menu-minus' : 'money',
            width: firstSymbol === '+' || firstSymbol === '-' ? '22px' : '22px',
            height: firstSymbol === '+' || firstSymbol === '-' ? '22px' : '15px',
            bg: firstSymbol === '+'
                ? 'rgba(44, 65, 53, 1)'
                : firstSymbol === '-'
                    ? 'rgba(189, 165, 127, 1)'
                    : 'rgba(67, 95, 197, 1)',
            fill: 'rgba(255, 255, 255, 1)'
        },
        FinanceDocumentPosted: {
            icon: firstSymbol === '+' ? 'menu-plus' : firstSymbol === '-' ? 'menu-minus' : 'money',
            width: firstSymbol === '+' || firstSymbol === '-' ? '22px' : '22px',
            height: firstSymbol === '+' || firstSymbol === '-' ? '22px' : '15px',
            bg: firstSymbol === '+'
                ? 'rgba(44, 65, 53, 1)'
                : firstSymbol === '-'
                    ? 'rgba(189, 165, 127, 1)'
                    : 'rgba(67, 95, 197, 1)',
            fill: 'rgba(255, 255, 255, 1)'
        },
        bonus_payed: {
            icon: 'gift',
            bg: 'rgba(107, 145, 123, 1)',
            fill: 'rgba(255, 255, 255, 1)'
        },
        BonusPayed: {
            icon: 'gift',
            bg: 'rgba(107, 145, 123, 1)',
            fill: 'rgba(255, 255, 255, 1)'
        },
        rank_assigned: {
            icon: 'star-line',
            bg: 'rgba(250, 176, 49, 1)',
            fill: 'rgba(255, 255, 255, 1)'
        },
        RankAssigned: {
            icon: 'star-line',
            bg: 'rgba(250, 176, 49, 1)',
            fill: 'rgba(255, 255, 255, 1)'
        },
        user_registered: {
            icon: 'user',
            bg: 'rgba(33, 175, 206, 1)',
            fill: 'rgba(255, 255, 255, 1)',
            width: '20px',
            height: '20px'
        },
        UserRegistered: {
            icon: 'user',
            bg: 'rgba(33, 175, 206, 1)',
            fill: 'rgba(255, 255, 255, 1)',
            width: '20px',
            height: '20px'
        },
        default_config: {
            icon: 'logo_mini',
            bg: 'radial-gradient(47.3% 47.5% at 44.99% 59.52%, #E0AA57 0%, #AA792D 100%)',
            fill: 'rgba(255, 255, 255, 1)'
        }
    }
}

// const notificationsTypesButton = {
//     0: 'DocumentPosted',
//     1: 'BonusPayed',
//     2: 'RankAssigned',
//     3: 'BecameActive',
//     4: 'UserRegistered',
//     5: 'volume',
//     6: 'PackageAssigned',
//     7: '',
//     8: 'custom'
// };
// const notificationsTypesQuick = {
//     0: 'FinanceDocumentPosted',
//     1: 'BonusPayed',
//     2: 'MarketingRankAssigned',
//     3: 'UserBecameActive',
//     4: 'UserRegistered',
//     5: 'volume',
//     6: 'PackageAssigned',
//     7: '',
//     8: 'custom'
// };
//
// export const getColorNotification = (type, firstSymbolSubject, component) => {
//     const notificationsTypes = component === 'quick' ? notificationsTypesQuick : notificationsTypesButton;
//     switch (type.toLowerCase()) {
//     case notificationsTypes['0'].toLowerCase():
//         return firstSymbolSubject === '+'
//             ? 'rgba(44, 65, 53, 1)'
//             : firstSymbolSubject === '-'
//                 ? 'rgba(189, 165, 127, 1)'
//                 : 'rgba(67, 95, 197, 1)';
//     case notificationsTypes['1'].toLowerCase():
//         return 'rgba(107, 145, 123, 1)';
//     case notificationsTypes['2'].toLowerCase():
//         return 'rgba(250, 176, 49, 1)';
//     case notificationsTypes['3'].toLowerCase():
//         return 'rgba(139, 185, 42, 1)';
//     case notificationsTypes['4'].toLowerCase():
//         return 'rgba(33, 175, 206, 1)';
//     default:
//         return 'var(--greenColor)';
//     }
// };
// export const getIcon = (type, css, subject, component) => {
//     const firstSymbolSubject = subject?.amount ? subject.amount[0] : subject[0];
//     const backgroundColor = getColorNotification(type, firstSymbolSubject, component);
//     const notificationsTypes = component === 'quick' ? notificationsTypesQuick : notificationsTypesButton;
//     switch (type.toLowerCase()) {
//     case notificationsTypes['0'].toLowerCase():
//         return (
//             <div className={css.notificationsIconWrapper} style={{ backgroundColor }}>
//                 {svgSprite(firstSymbolSubject === '+' ? 'menu-plus' : firstSymbolSubject === '-' ? 'menu-minus' : 'money', {
//                     width: firstSymbolSubject === '+' || firstSymbolSubject === '-' ? '15px' : '27.5px',
//                     height: firstSymbolSubject === '+' || firstSymbolSubject === '-' ? '15px' : '27.5px',
//                     fill: 'rgba(255, 255, 255, 1)'
//                 })}
//             </div>
//         );
//     case notificationsTypes['1'].toLowerCase():
//         return (
//             <div className={css.notificationsIconWrapper} style={{ backgroundColor }}>
//                 {svgSprite('gift', {
//                     width: '30px',
//                     height: '30px',
//                     fill: 'rgba(255, 255, 255, 1)'
//                 })}
//             </div>
//         );
//     case notificationsTypes['2'].toLowerCase():
//         return (
//             <div className={css.notificationsIconWrapper} style={{ backgroundColor }}>
//                 {svgSprite('star-line', {
//                     width: '30px',
//                     height: '30px',
//                     fill: 'rgba(255, 255, 255, 1)'
//                 })}
//             </div>
//         );
//     case notificationsTypes['3'].toLowerCase():
//         return (
//             <div className={css.notificationsIconWrapper} style={{ backgroundColor }}>
//                 {svgSprite('briefcase', {
//                     width: '30px',
//                     height: '30px',
//                     fill: 'rgba(255, 255, 255, 1)'
//                 })}
//             </div>
//         );
//     case notificationsTypes['4'].toLowerCase():
//         return (
//             <div className={css.notificationsIconWrapper} style={{ backgroundColor }}>
//                 {svgSprite('user', {
//                     width: '20px',
//                     height: '20px',
//                     fill: 'rgba(255, 255, 255, 1)'
//                 })}
//             </div>
//         );
//     case notificationsTypes['5'].toLowerCase():
//         return (
//             <div className={css.notificationsIconWrapper} style={{ background: 'radial-gradient(47.3% 47.5% at 44.99% 59.52%, #E0AA57 0%, #AA792D 100%)' }}>
//                 {svgSprite('logo_mini', {
//                     width: '30px',
//                     height: '30px'
//                     // fill: 'rgba(255, 255, 255, 1)'
//                 })}
//             </div>
//         );
//     default:
//         return (
//             <div className={css.notificationsIconWrapper} style={{ background: 'radial-gradient(47.3% 47.5% at 44.99% 59.52%, #E0AA57 0%, #AA792D 100%)' }}>
//                 {svgSprite('logo_mini', {
//                     width: '30px',
//                     height: '30px',
//                     fill: 'rgba(44, 65, 53, 1)'
//                 })}
//             </div>
//         );
//     }
// };

// export const textNotification = (notification, css, translates) => {
//     let {
//         type, params, subject, text
//     } = notification;
//     type = type.replace('_', '');
//     const notificationsTypes = notificationsTypesButton;
//     switch (type) {
//     case notificationsTypes['0'].toLowerCase():
//         return (
//             <>
//                 <p className={css.NotificationsHeaderItemTitle}>
//                     {params?.amount + (params?.amount ? ' - ' : '')}
//                     <T
//                         textName='finance-operation-success'
//                         defaultStr='Финансовая операция проведена успешно'
//                         page='widget'
//                         translates={translates}
//                         group='Notifications'
//                     />
//                 </p>
//                 <p className={css.NotificationsHeaderItemSubTitle}>{params?.name || ''}</p>
//             </>
//         );
//     case notificationsTypes['1'].toLowerCase():
//         return (
//             <>
//                 <p className={css.NotificationsHeaderItemTitle}>
//                     {params?.amount + (params?.amount
//                         ? ' - '
//                         : '')}
//                     <T
//                         textName='bonus-operation-success'
//                         defaultStr='Начислен бонус'
//                         page='widget'
//                         translates={translates}
//                         group='Notifications'
//                     />
//                     <span> </span>
//                     {params?.name || ''}
//                 </p>
//                 <p className={css.NotificationsHeaderItemSubTitle}>
//                     <T
//                         textName='bonus-operation-success-subtitle'
//                         defaultStr='Вы получили от пользователя'
//                         page='widget'
//                         translates={translates}
//                         group='Notifications'
//                     />
//                     {' ' + text}
//                 </p>
//             </>
//         );
//     case notificationsTypes['2'].toLowerCase():
//         return (
//             <>
//                 <>
//                     <p className={css.NotificationsHeaderItemTitle}>{subject + ' «' + params?.name + '»'}</p>
//                     <p className={css.NotificationsHeaderItemSubTitle}>
//                         <T
//                             textName='rank-operation-success'
//                             defaultStr='Отличный результат, продолжайте двигаться с тем же темпом!'
//                             page='widget'
//                             translates={translates}
//                             group='Notifications'
//                         />
//                     </p>
//                 </>
//             </>
//         );
//         // case notificationsTypes['3'].toLowerCase():
//         //     return (
//         //         <>
//         //             <p className={css.NotificationsHeaderItemTitle}>{subject}</p>
//         //             <p className={css.NotificationsHeaderItemSubTitle}>
//         //                 <T
//         //                     textName='active-operation-success'
//         //                     defaultStr='Вы успешно выполнили все условия активности за период'
//         //                     page='widget'
//         //                     translates={translates}
//         //                     group='Notifications'
//         //                 />
//         //                 {' ' + params.from + (params?.to ? ' - ' + params.to : '')}
//         //             </p>
//         //         </>
//         //     );
//     case notificationsTypes['4'].toLowerCase():
//         return (
//             <>
//                 <p className={css.NotificationsHeaderItemTitle}>{params.username + ' - ' + subject}</p>
//                 <p className={css.NotificationsHeaderItemSubTitle}>
//                     {
//                         <T
//                             textName='user-operation-success'
//                             group='Notifications'
//                             defaultStr='Поздравляем! По вашей реферальной ссылке был зарегистрирован новый партнер'
//                             page='widget'
//                             translates={translates}
//                         />
//                     }
//                 </p>
//             </>
//         );
//     case notificationsTypes['5'].toLowerCase():
//         return (
//             <>
//                 <p className={css.NotificationsHeaderItemTitle}>{subject}</p>
//                 <p className={css.NotificationsHeaderItemSubTitle}>
//                     <T
//                         textName='user-volume-plus-success'
//                         group='Notifications'
//                         defaultStr='Начисление'
//                         page='widget'
//                         translates={translates}
//                     />
//                     <span> </span>
//                     {params?.amount}
//                     <span> </span>
//                     <T
//                         textName='ser-volume-plus-success1'
//                         group='Notifications'
//                         defaultStr='баллов в'
//                         page='widget'
//                         translates={translates}
//                     />
//                     <span> </span>
//                     {params?.name}
//                 </p>
//             </>
//         );
//     case notificationsTypes['6'].toLowerCase():
//         return (
//             <>
//                 <p className={css.NotificationsHeaderItemTitle}>{subject}</p>
//                 <p className={css.NotificationsHeaderItemSubTitle}>
//                     <T
//                         textName='user-buy-pack-success'
//                         group='Notifications'
//                         defaultStr='Вы успешно приобрели пакет '
//                         page='widget'
//                         translates={translates}
//                     />
//                     <span> </span>
//                     {params?.packageName || ''}
//                 </p>
//             </>
//         );
//     case notificationsTypes['7'].toLowerCase():
//         return (
//             <>
//                 <p className={css.NotificationsHeaderItemTitle}>{params.username + ' - ' + subject}</p>
//                 <p className={css.NotificationsHeaderItemSubTitle}>
//                     {
//                         <T
//                             textName='user-operation-success'
//                             group='Notifications'
//                             defaultStr='Поздравляем! По вашей реферальной ссылке был зарегистрирован новый партнер'
//                             page='widget'
//                             translates={translates}
//                         />
//                     }
//                 </p>
//             </>
//         );
//     case notificationsTypes['8']:
//         return (
//             <>
//                 <p className={css.NotificationsHeaderItemTitle}>{subject}</p>
//                 <p className={css.NotificationsHeaderItemSubTitle}>
//                     {text && <div dangerouslySetInnerHTML={{ __html: text }}/>}
//                 </p>
//             </>
//         );
//
//     default:
//         return null;
//     }
// };