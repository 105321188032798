import { media } from '../../../common/utils/utils';
import officeStyles from './office/search-window-styles'
import mainStyles from './main/search-window-styles'
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    officeStyles, mainStyles,
    formCursorPointer: { 'cursor': 'pointer' },
    searchWindowWrapper: media(width, height, 'w', {
        992: {
            'max-width': '750px',
            'width': '750px',
            'border-radius': '20px',
            'background-color': variable.whiteColor
        },
        768: {
            'max-width': '750px',
            'width': '750px',
            'border-radius': '20px',
            'background-color': variable.whiteColor
        },
        0: {
            'border-radius': '0px',
            'padding-bottom': '0px',
            'background-color': variable.greyColor,
            'flex-grow': '1'
            // order: -1,
        }
    }),
    searchWindowTableWrapper: media(width, height, 'w', {
        992: {
            'align-items': 'center',
            padding: '24px 32px 1px',
            // height: `${height - 50}px`,
            'overflow-y': 'scroll'
        },
        768: {
            'align-items': 'center',
            padding: '12px 24px 1px 24px',
            height: `${height - 50}px`,
            'overflow-y': 'scroll'
        },
        0: {
            'border-radius': '20px 20px 0 0',
            'align-items': 'center',
            padding: '16px 12px 0',
            background: variable.whiteColor,
            height: `${height - 58}px`,
            'overflow-y': 'scroll',
            'overflow-x': 'hidden'
        }
    }),
    searchWindowTable: {
        'margin-bottom': '32px',
        'overflow': 'hidden'
    },
    searchTable: media(width, height, 'w', {
        768: { 'min-width': '686px' },
        0: {}
    }),
    searchWindowTitleBlock: media(width, height, 'w', {
        992: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'position': 'relative',
            'align-items': 'center',
            padding: '32px 32px 0 32px'
        },
        768: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'position': 'relative',
            'align-items': 'center',
            padding: '24px 24px 0 24px'
        },
        0: {
            'background-color': variable.whiteColor,
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'center',
            padding: '10px',
            'margin-bottom': '12px',
            'box-shadow': `0px 2px 15px ${variable.blackExtraTransparentColor}`,
            'border-radius': '0px 0px 20px 20px'
        }
    }),
    searchWindowTitle: media(width, height, 'w', {
        992: {
            color: variable.darkColor,
            ...uikit.title,
            'text-align': 'center',
            ...uikit.medium
        },
        0: {
            color: variable.darkColor,
            ...uikit.title,
            'font-weight': 500
        }
    }),
    searchWindowTitleClose: media(width, height, 'w', {
        992: {
            'position': 'absolute',
            top: '32px',
            right: '32px'
        },
        768: {
            'position': 'absolute',
            top: '24px',
            right: '24px'
        },
        0: {}
    }),
    searchWindowTitleSvg: {
        fill: variable.greyExtraDarkColor,
        width: '12px',
        height: '12px'
    },
    noDataAlert: media(width, height, 'w', {
        768: {
            background: variable.greyTransparentHalfColor,
            'border-radius': '8px',
            padding: '16px'
        },
        0: {
            'text-align': 'center',
            background: variable.greyTransparentHalfColor,
            'border-radius': '8px',
            padding: '16px'
        }
    }),
    multiWrap: media(width, height, 'w', {
        992: { padding: '0 32px' },
        768: { padding: '0 24px' },
        0: { padding: '0 10px' }
    }),
    multiValueWrap: media(width, height, 'w', {
        0: {
            display: 'flex',
            'flex-wrap': 'wrap'
        }
    }),
    multiValueCont: media(width, height, 'w', {
        0: {
            display: 'flex',
            'margin-right': '4px',
            'background-color': 'rgba(44, 65, 53, 1)',
            'border-radius': '6px',
            margin: '2px',
            padding: '5px 8px',
            'max-width': '100%',
            overflow: 'hidden',
            'text-overflow': 'ellipsis',
            'white-space': 'nowrap',
            color: variable.whiteColor
        }
    }),
    multiValueButton: media(width, height, 'w', {
        0: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'border-radius': '6px',
            margin: '8px 0',
            padding: '5px 8px',
            'max-width': '100%',
            overflow: 'hidden',
            'text-overflow': 'ellipsis',
            'white-space': 'nowrap',
            color: variable.whiteColor,
            'background-color': variable.orangeColor,
            'min-width': '140px',
            ...uikit.subtitle,
            'font-weight': '500',
            cursor: 'pointer'
        }
    })

});
export default stylesheets;