// @generated: @expo/next-adapter@2.1.5
import React, {
    useContext, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import {
    P, Div, svgSprite
} from '../../../hybrid/wrappers';
import Display from '../../../common/components/Display'
import UserContext from '../../../common/utils/getContext';
import Styles from '../../../common/decorators/Styles';
import getStyles from '../../../project/styles/widget-styles/grid-styles';
import { getMemoizedData } from '../../../common/utils/memo';
import UserModalInfo from '../../userModalInfo/UserModalInfo';

const Login = ({
    gridStyles = {}, getHandlers = () => {}, val, name, styles, link, align
}) => {
    const { userToken, lang } = useContext(UserContext);

    if (!val || typeof val === 'object' && !val.username && !val.not_user){
        return <P>-</P>
    }

    const [sideBar, setSideBar] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    const getUserData = async (val) => {
        if (val?.not_user || val?.is_deleted) return
        if (val?.links?.partner || val?.id) {
            setSideBar(true)
        }
        const data = val?.links?.partner ? await getMemoizedData(val?.links?.partner, userToken, lang) : val?.id && await getMemoizedData(`/api/v1/user/partners/${val?.id}`, userToken, lang);
        if (!data) return
        const rankData = await getMemoizedData(data?.data?.marketing_profile?.links?.rank_progress, userToken, lang);
        if (data && data?.data) {
            const rankDataSponsor = await getMemoizedData(data?.data?.sponsor?.marketing_profile?.links?.rank_progress, userToken, lang)

            setUserInfo({
                ...data.data.profile,
                ...data.data,
                sponsor: data?.data?.sponsor ? {
                    ...data.data?.sponsor,
                    package: data.data?.sponsor?.marketing_profile?.package,
                    activity: data.data?.sponsor?.marketing_profile?.activity,
                    rank: rankDataSponsor?.data?.current_rank,
                    rankData: rankDataSponsor?.data
                } : null,
                activity: data.data?.marketing_profile?.activity,
                rankData: rankData?.data,
                rank: rankData?.data?.current_rank,
                package: data?.data?.marketing_profile?.package,
                new_partners_number: data?.data?.marketing_profile?.new_partners_number,
                active_partners_number: data?.data?.marketing_profile?.active_partners_number,
                children_number: data?.data?.children_count,
                partners_number: data?.data?.descendants_count
            })
        }
    }

    const cursor = useMemo(() => val?.cursor ? { cursor: val.cursor } : val?.not_user ? { cursor: 'default' } : { cursor: 'pointer' }, [val])

    return (
        <Div
            styles={{
                ...gridStyles[name]?.wrapper?.styles || (align === 'left'
                    ? {
                        ...styles.flexColumn, ...styles.alignItemsStart, ...cursor
                    }
                    : align === 'right'
                        ? {
                            ...styles.flexColumn, ...styles.alignItemsEnd, ...cursor
                        }
                        : {
                            ...styles.flexColumn, ...styles.alignItemsCenter, ...cursor
                        })
            }}
            effects={gridStyles[name]?.wrapper?.effects || {}}
            onClick={() => getUserData(val)}
        >
            <P
                styles={gridStyles[name]?.fio?.styles || styles.buyerName}
                effects={gridStyles[name]?.fio?.effects || {}
                }
            >
                {
                    val.not_user
                        ? val.not_user
                        : val.full_name ? val.full_name :
                            <Display type='fio' data={{
                                first_name: val.first_name,
                                last_name: val.last_name,
                                patronymic: val.patronymic
                            }}/>

                }
            </P>
            {
                !val.not_user ?
                    <Div
                        {...getHandlers(false)}
                        styles={gridStyles[name]?.icoWrap1?.styles || { ...styles.userNameBlock, ...val.full_name || val.first_name && val.last_name && val.patronymic ? styles.userNameBlockMb : {} }}
                        effects={gridStyles[name]?.icoWrap1?.effects || {
                            hover: !!link && styles.formCursorPointer,
                            focus: !!link && styles.formCursorPointer
                        }
                        }
                    >
                        <P
                            styles={gridStyles[name]?.icoWrap2?.styles
                                ? {
                                    ...styles.dFlex, ...styles.alignItemsCenter, ...gridStyles[name].icoWrap2.styles
                                }
                                : {
                                    ...styles.marginRight8, ...styles.dFlex, ...styles.alignItemsCenter
                                }
                            }
                            effects={gridStyles[name]?.icoWrap2?.effects || {}
                            }
                            tagName='span'
                        >
                            {
                                svgSprite('user-circle-fill',
                                    gridStyles[name]?.ico?.styles || {
                                        fill: styles.variable.blueColor,
                                        style: styles.userNameBlockIcon
                                    })
                            }
                        </P>
                        <P styles={{ 'white-space': 'nowrap' }}>{`${val.username || val}`}</P>
                    </Div>
                    : null
            }
            {
                sideBar ?
                    <UserModalInfo
                        userData={userInfo}
                        setShowUser={setSideBar}
                        setUserData={setUserInfo}
                        closeHandler={() => { setSideBar(false);setUserInfo(null) }}
                    />
                    : null
            }

        </Div>
    )
};

Login.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
    ]),
    styles: PropTypes.object,
    name: PropTypes.string
};
export default Styles(Login, getStyles);