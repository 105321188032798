import {
    useContext, useCallback, useState, useMemo
} from 'react';
import UserContext from './getContext';
import { usePusherChannel } from './pusher.utills';

export const UseQuickNotifications = () => {
    const { userInfo } = useContext(UserContext) || {};

    const [quickNotifications, setQuickNotifications] = useState([]);
    const [quickNotificationsForCounter, setQuickNotificationsForCounter] = useState([]);

    const onMessage = useCallback((data) => {
        setQuickNotifications((pre) => [...pre, data]);
        setQuickNotificationsForCounter((pre) => [...pre, data]);
    }, []);

    const events = useMemo(() => ['Illuminate\\Notifications\\Events\\BroadcastNotificationCreated'], []);
    usePusherChannel(userInfo?.data?.hasOwnProperty('id') && `private-App.Models.User.${userInfo?.data?.id}`, events, { onMessage });
    return {
        quickNotifications, quickNotificationsForCounter, setQuickNotificationsForCounter
    };
};