import { t } from '../../../../common/components/T';
import { camelizeStyles } from '../../../../common/utils/utils';
import { getPlaceholderColor } from '../helpers';
import getControl from './components/Control';
import getDropdownIndicator from './components/DropdownIndicator';
import getMultiValueContainer from './components/MultiValueContainer';
import getMultiValueLabel from './components/MultiValueLabel';
import getMultiValueRemove from './components/MultiValueRemove';
import getOption from './components/Option';

const saveInputValue = (inputValue, { action, prevInputValue }) => {
    switch (action) {
    case 'input-change':
        return inputValue;
    case 'set-value':
        return prevInputValue;
    default:
        return prevInputValue;
    }
};
export const getConfig = (
    val, attribute, title, hint, required, disabled, placeholder,
    optionsConfig,
    portal, customStyles, multiple,
    limit, selectedLimit,
    initHandlers, setVal, setFormData, changeHandler,
    variationStyle, styles, compStyle,
    insertComponent, errors, isLoading,
    transl, isClear, className, isSearchable = true, noOptionsMessage
) => ({
    ...initHandlers,
    ...optionsConfig,
    ...portal ? { menuPortalTarget: document.body } : {},
    styles: customStyles,
    ...multiple && limit !== 1 ? { onInputChange: saveInputValue } : {},
    isOptionDisabled: () => multiple && val?.length >= limit,
    components: {
        Option: getOption(multiple, limit, styles),
        MultiValueLabel: getMultiValueLabel(multiple, val, selectedLimit),
        MultiValueRemove: getMultiValueRemove(
            attribute, multiple, val, selectedLimit, setVal, setFormData
        ),
        MultiValueContainer: getMultiValueContainer(multiple, val, selectedLimit),
        DropdownIndicator: getDropdownIndicator(styles, compStyle),
        // Control: getControl(
        //     title, hint, required, val, insertComponent, styles, compStyle, variationStyle, errors
        // )
        Control: getControl
        // ClearIndicator: getClearIndicator(styles)
    },
    isDisabled: disabled,
    hideSelectedOptions: !multiple,
    noOptionsMessage: noOptionsMessage,
    value: val,
    isLoading: isLoading,
    isSearchable,
    onChange: changeHandler,
    menuPlacement: 'auto',
    placeholder: placeholder || t(
        'choose', 'Выберите', 'widget', transl, 'form'
    ),
    isClearable: isClear || multiple && val?.length > selectedLimit,
    maxMenuHeight: 273,
    className,
    isMulti: multiple,
    closeMenuOnSelect: !multiple || limit === 1,
    max: limit,
    // fix selecta
    myProps: {
        title,
        hint,
        required,
        val,
        insertComponent,
        styles,
        compStyle,
        variationStyle,
        errors,
        multiple,
        limit
    }
});
export const getOptions = (options) => options?.[0] && !options[0].label ? options?.map((item) => (
    {
        ...item,
        value: item?.id || item?.value,
        label: item?.title || item?.code || item?.name || item?.label
    }
)) : options?.[0] && options[0].label ? options : []

export const getCustomStyles = (
    styles, profileContext, multiple, val, isClear, variationStyle, compStyle, errors, disabled
) => ({
    menu: (provided) => ({
        ...provided,
        ...camelizeStyles(styles.formSelectMenu),
        ...camelizeStyles({ 'z-index': profileContext?.mainPage ? 996 : 9999 })
    }),
    option: (provided, state) => ({
        ...provided,
        ...state.isDisabled ? camelizeStyles({ opacity: '0.5', 'pointer-events': 'none' }) : {},
        ...camelizeStyles(styles.formSelectOption),
        ...state.isFocused || state.isSelected ? camelizeStyles(styles.formSelectOptionActive) : {},
        ...state.isFocused ? camelizeStyles(styles.formSelectOptionHover) : {}
    }),
    menuPortal: (provided) => ({
        ...provided,
        ...camelizeStyles({ 'z-index': 9999 })
    }),
    menuList: (provided) => ({
        ...provided,
        ...camelizeStyles(styles.formSelectMenuList)
    }),
    control: () => camelizeStyles(styles.formSelectControl),
    indicatorsContainer: () => camelizeStyles(styles.formSelectIndicatorsContainer),
    valueContainer: () => ({
        ...camelizeStyles(styles.formSelectValueContainer),
        ...multiple && val?.length ? camelizeStyles(styles.formSelectValueContainerMultiple) : {}
    }),
    multiValue: (provided) => ({
        ...provided,
        ...camelizeStyles(styles.formSelectMultiValue)
    }),
    multiValueLabel: () => camelizeStyles(styles.formSelectMultiValueLabel),
    multiValueRemove: () => camelizeStyles(styles.formSelectMultiValueRemove),
    dropdownIndicator: (provided, state) => ({
        ...state.selectProps.menuIsOpen ? camelizeStyles(styles.formSelectDropdownIndicatorOpened) : {},
        ...camelizeStyles(styles.formSelectDropdownIndicator)
    }),

    input: () => camelizeStyles(styles.formSelectInput),
    singleValue: () => camelizeStyles(styles.formSelectSingleValue),

    indicatorSeparator: () => camelizeStyles(styles.formSelectIndicatorSeparator),
    clearIndicator: isClear ? () => camelizeStyles(styles.formSelectClearIndicator) : () => ({ display: 'none' }),

    placeholder: (provided, state) => ({
        ...provided,
        ...camelizeStyles({
            'margin-left': '0',
            ...styles.formSelectPlaceholder,
            ...getPlaceholderColor(
                variationStyle, styles, compStyle, errors, state?.isFocused, disabled
            ),
            ...compStyle?.placeholder,
            ...state?.selectProps.menuIsOpen ? { display: 'none' } : {}
        })
    })
})