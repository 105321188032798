import * as React from 'react';
import UserContext from '../common/decorators/UserContext';
import { withRouter } from 'next/router';
import '../project/stylesToApp.scss';
import 'react-dropdown/style.css';
import 'react-phone-input-2/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import ProtectedOffice from '../common/decorators/ProtectedOffice';
import ProtectedAdmin from '../common/decorators/ProtectedAdmin';
import { useState } from 'react';

const App = ({
    Component, pageProps, router
}) => {
    const { protect, protectRoles } = pageProps;
    const getLayout = Component.getLayout || ((page) => page);

    const [layoutProps, setLayoutProps] = useState({});
    return (
        <>
            {protect === 'admin' ?
                <>
                    <ProtectedAdmin role={protectRoles}>
                        {getLayout(<Component {...pageProps} setLayoutProps={setLayoutProps} />, pageProps, layoutProps, router)}
                    </ProtectedAdmin>
                </>
                : protect === 'office' || protect === 'user' ?
                    <>
                        <ProtectedOffice>
                            {getLayout(<Component {...pageProps} setLayoutProps={setLayoutProps} />, pageProps, layoutProps, router)}
                        </ProtectedOffice>
                    </>
                    :
                    <>
                        {getLayout(<Component {...pageProps} setLayoutProps={setLayoutProps} />, pageProps, layoutProps, router)}
                    </>
            }
        </>
    );
};
const FallbackPages = (OriginalComponent) =>
    class WrapperComponent extends React.Component {
        constructor(params) {
            super(params);
        }

        render() {
            if (this.props.router.isFallback) return null;
            return <OriginalComponent {...this.props} />;
        }
    };
export default withRouter(FallbackPages(UserContext(App)));