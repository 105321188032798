import React, {
    useCallback, useMemo, useContext
} from 'react';
import CKEditor from 'ckeditor4-react';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';

import {
    Div, FieldError, FieldInfo
} from '../../../../hybrid/wrappers'
import FromInputTitle from '../FormFieldTitle';
import UserContext from '../../../../common/utils/getContext';
import PropTypes from 'prop-types';

const Editor = ({
    compStyle,
    attribute,
    value,
    errors,
    title,
    setFormData,
    styles,
    required,
    hint,
    type,
    info
}) => {
    const { lang } = useContext(UserContext);

    const config = useMemo(() => ({
        versionCheck: false,
        clipboard_handleImages: false,
        allowedContent: true,
        uiColor: styles.variable.whiteColor,
        language: lang,
        // stylesSet: false,
        // filebrowserBrowseUrl :  '/page',
        filebrowserUploadUrl: '/api/ckfinder',
        toolbar: [
            {
                name: 'style',
                items: ['Format']
            },
            {
                name: 'basicstyles',
                items: ['Bold', 'Italic', 'Strike']
            },
            {
                name: 'links',
                items: ['Link']
            },
            {
                name: 'paragraph',
                items: ['BulletedList', 'NumberedList', 'Blockquote']
            },
            {
                name: 'insert',
                items: ['Table', '']
            },
            {
                name: 'clipboard',
                items: ['Undo', 'Redo']
            },
            {
                name: 'document',
                items: ['Source']
            },
            { name: 'tools', items: ['Maximize'] }
        ]
    }), [lang, styles.variable.whiteColor]);
    const onChange = useCallback((value) => {
        setFormData(attribute, value.editor.getData());
    }, [setFormData, attribute]);

    return (
        <Div styles={{ ...styles.formGroup, ...compStyle?.wrapper || {} }}>
            {!!title &&
                <FromInputTitle
                    styles={styles}
                    type={type}
                    errors={errors}
                    title={title}
                    compStyle={compStyle}
                    hint={hint}
                    required={required}
                    variationStyle={'separated_title'}
                />
            }
            <CKEditor
                data={value}
                onChange={onChange}
                config={config}
            />
            {
                !!info
                    && <FieldInfo styles={{ ...styles, ...compStyle?.info ? compStyle?.info : {} }}>{info}</FieldInfo>
            }

            {
                errors && errors.length ? errors.map((err, idx) => <FieldError
                    styles={{ ...styles, ...compStyle?.error || {} }} key={`${attribute}${idx}`}>{err}</FieldError>) : null
            }
        </Div>
    )
}

Editor.propTypes = {
    compStyle: PropTypes.object,
    attribute: PropTypes.string,
    value: PropTypes.string,
    errors: PropTypes.array,
    title: PropTypes.object,
    setFormData: PropTypes.func,
    styles: PropTypes.object,
    required: PropTypes.bool,
    hint: PropTypes.node,
    type: PropTypes.string,
    info: PropTypes.node
};

export default Styles(Editor, getStyles);