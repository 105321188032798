import React, { useEffect, useState } from 'react';
import { Div, FieldError } from '../../hybrid/wrappers';

import ReCAPTCHA from 'react-google-recaptcha';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types';

const Captcha = ({
    setFormData, attribute, site_key, errors, styles, compStyle, value, captchaCount, tryCount, version
}) => {
    const [visible, setVisible] = useState(false);

    const recaptchaRef = React.createRef();
    function onChange(value) {
        if (value) {
            setFormData(attribute, value);
        }
    }

    if (!!errors?.length || errors?.message) {
        if (typeof window !== 'undefined') {
            if (version === 'reCaptcha_v2' && value.value == 1) {
                window?.grecaptcha?.reset();
            }
        };
    }

    useEffect(() => {
        if (tryCount <= captchaCount) {
            if (value.attribute === 'user::auth' && !!+value.value) {
                setVisible(true);
            }
            if (value.attribute === 'user::register' && !!+value.value) {
                setVisible(true);
            }
            if (value.attribute === 'user::password_recovery' && !!+value.value) {
                setVisible(true);
            }
        }
    }, [captchaCount, tryCount, value]);
    if (!site_key || !visible) return null;
    return (
        <Div styles={{ 'margin-bottom': '24px' }}>
            {version === 'reCaptcha_v3'
                ?
                <GoogleReCaptchaProvider reCaptchaKey={site_key} >
                    <GoogleReCaptcha onVerify={onChange} />
                </GoogleReCaptchaProvider>
                : version === 'reCaptcha_v2' ?
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={site_key}
                        onChange={onChange}
                    />
                    : ''
            }
            {
                errors && errors.length ? errors.map((err, idx) => <FieldError styles={{ ...styles, ...compStyle?.error || {} }} key={`${attribute}${idx}`}>{err}</FieldError>) : null
            }
        </Div>
    );
}

Captcha.propTypes = {
    setFormData: PropTypes.func,
    attribute: PropTypes.string,
    site_key: PropTypes.string,
    errors: PropTypes.array,
    styles: PropTypes.object,
    compStyle: PropTypes.object,
    value: PropTypes.object,
    captchaCount: PropTypes.number,
    tryCount: PropTypes.number,
    version: PropTypes.oneOf(['reCaptcha_v3', 'reCaptcha_v2'])
};
export default Captcha;