import { dataSender, getData } from '../../common/utils/router';
import { transformSearchUserData } from './initData';
import { API_SERVER_URL } from '../../common/utils/utils';

export const sendFunction = (
    val, url, setUserTableData, userToken, setAlertData, lang, transformSearchData = false, userId, filterParams = {}
) => {
    const searchParams = Object.keys(filterParams || {})?.
        filter(el => filterParams[el]?.length > 1)?.
        map(key => {
            return `filter[${key}]=${filterParams[key]}`;
        });

    const searchParam = searchParams?.length
        ? `${!url || url.indexOf('?') === -1 ? '?' : '&'}${searchParams?.join('&')}`
        : !!val && val.length
            ? `${!url || url.indexOf('?') === -1 ? '?' : '&'}filter[q]=${val}`
            : '';
    (async () => {
        const getRoles = await getData('/api/v1/user/roles', userToken, lang);
        let isLogist = false;
        if (getRoles?.data && getRoles.data.length) {
            getRoles.data.map(i => {
                if (i.name === 'logistician') {
                    isLogist = true
                }
            })
        }
        const urlData = url ? `${url}${searchParam}` : isLogist ? `${API_SERVER_URL}/api/v1/admin/user-management/users${searchParam}` : `${API_SERVER_URL}/api/v1/user/partners/${userId || '1'}/descendants${searchParam}`;

        dataSender(
            urlData,
            'GET',
            false,
            (response) => {
                setAlertData(null);
                const resData = response.data;
                if (transformSearchData) {
                    setUserTableData(transformSearchData(resData));
                } else {
                    setUserTableData(transformSearchUserData(resData));
                }
            },
            (data) => {
                if (data?.message) {
                    setAlertData({ type: 'error', cont: data?.message });
                } else {
                    setAlertData({ type: 'error', cont: data?.false });
                }
            },
            userToken,
            lang
        );
    })()
};