import { media } from '../../../common/utils/utils';
import uikit from '../../uikit/styles';


const stylesheets = (width, height, variable = {}) => ({
    modalWrapper: media(width, height, 'w', {
        768: {
            width: '600px',
            background: variable.whiteColor,
            'border-radius': '20px',
            padding: '32px',
            position: 'relative',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'flex-direction': 'column'
        },
        0: {
            margin: '0 auto',
            width: '95%',
            background: variable.whiteColor,
            'border-radius': '24px',
            padding: '16px',
            position: 'relative',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'flex-direction': 'column'
        }
    }),
    modalTitle: media(width, height, 'w', {
        768: {
            ...uikit.title,
            'font-weight': '500',
            color: variable.blackColor,
            'margin-bottom': '16px'
        },
        0: {
            'margin-bottom': '14px',
            ...uikit.title,
            'font-weight': '500',
            color: variable.blackColor
        }
    }),
    middleWrapper: media(width, height, 'w', {
        768: {
            display: 'flex',
            'align-items': 'center',
            'margin-bottom': '24px',
            width: '100%'
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'flex-direction': 'column',
            'margin-bottom': '16px',
            width: '100%'
        }
    }),
    modalSubTitle: media(width, height, 'w', {
        768: {
            ...uikit.text,
            'text-alaing': 'center',
            color: variable.darkHalfTransparentColor,
            'margin-bottom': '16px'
        },
        0: {
            ...uikit.text,
            'margin-bottom': '14px',
            color: variable.darkHalfTransparentColor
        }
    }),
    closeWrap: media(width, height, 'w', {
        768: {
            width: '24px',
            height: '24px',
            position: 'absolute',
            top: '24px',
            right: '24px',
            cursor: 'pointer'
        },
        0: {
            width: '24px',
            height: '24px',
            position: 'absolute',
            top: '16px',
            right: '16px'
        }
    }),
    modalWindowCloseIcon: {
        width: '14px',
        fill: variable.blackColor,
        height: '14px'
    },
    inputWraper: media(width, height, 'w', {
        768: {
            width: '60%',
            height: '64px',
            'border-radius': '12px'
        },
        0: {
            width: '100%',
            height: '60px',
            'border-radius': '12px'
        }
    }),
    timeWrapper: media(width, height, 'w', {
        768: { 'margin-left': '24px' },
        0: {
            order: '-1',
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'flex-direction': 'column',
            'margin-bottom': '12px'
        }
    }),
    timeText: {
        ...uikit.tiny,
        color: variable.greySuperDarkColor,
        'padding-bottom': '6px'
    },
    blockTime: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        width: '80px',
        ...uikit.subtitle,
        color: variable.blackColor,
        padding: '8px 0',
        background: variable.greyTransparentHalfColor,
        'border-radius': '8px'
    },
    inputLable: {
        ...uikit.small,
        'line-height': '1.14',
        color: variable.blackColor
    },
    acceptBtn: media(width, height, 'w', {
        768: {
            ...uikit.text,
            height: '64px',
            cursor: 'pointer',
            width: '100%',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            background: variable.orangeColor,
            color: variable.whiteColor,
            'text-align': 'center',
            'border-radius': '14px',
            'margin-bottom': '8px',
            'font-weight': '500'
        },
        0: {
            ...uikit.text,
            height: '64px',
            width: '100%',
            background: variable.orangeColor,
            color: variable.whiteColor,
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'border-radius': '14px',
            'margin-bottom': '8px',
            'font-weight': '500'
        }
    }),
    reSendBtn: media(width, height, 'w', {
        768: {
            height: '64px',
            cursor: 'pointer',
            width: '100%',
            background: variable.whiteColor,
            border: `1px solid ${variable.darkExtraTransparentColor}`,
            color: variable.blackColor,
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'font-weight': '500',
            'border-radius': '14px'
        },
        0: {
            height: '64px',
            cursor: 'pointer',
            width: '100%',
            background: variable.whiteColor,
            border: `1px solid ${variable.darkExtraTransparentColor}`,
            color: variable.blackColor,
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'font-weight': '500',
            'border-radius': '14px'
        }
    }),
    inputStyles: media(width, height, 'w', {
        768: {
            ...uikit.small,
            'padding-top': '10px',
            width: '90%'
        },
        0: {
            ...uikit.small,
            'padding-top': '6px'
        }
    }),
    buttonWrapper: media(width, height, 'w', {
        768: {
            ...uikit.flexRow,
            ...uikit.alignItemsCenter,
            width: '100%'
        },
        0: {
            ...uikit.flexColumn,
            ...uikit.alignItemsCenter,
            ...uikit.justifyContentCenter,
            width: '100%'
        }
    }),
    modelButtonFirst: media(width, height, 'w', {
        768: {
            ...uikit.mr3,
            ...uikit.mb0
        },
        0: {
            ...uikit.mr3,
            ...uikit.mb3
        }
    })
});
export default stylesheets;