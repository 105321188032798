import React, {
    useState, useEffect, useContext, useCallback
} from 'react'
import {
    Portal, Div, svgSprite, Button
} from '../../hybrid/wrappers'
import Styles from '../../common/decorators/Styles';
import getStyles from '../../project/styles/widget-styles/modal2FA-styles';
import { dataSender } from '../../common/utils/router';
import UserContext from '../../common/utils/getContext';
import { API_SERVER_URL, getWidgetTranslates } from '../../common/utils/utils';
import { t } from '../../common/components/T'
import { LangContext } from '../../common/decorators/Language';
import moment from 'moment/moment';
import Timer from './Timer';
import PropTypes from 'prop-types';
import FormInput from '../form/components/form-input/FormInput';

const Modal2FA = ({
    styles,
    type,
    event,
    sendData = {},
    senderFunc,
    showModal,
    setShowModal,
    ifClosed,
    formErrors,
    showError=false,
    sender2FAAllow=false,
    senderFunc2FAAllow = () => {}
}) => {
    const {
        setAlertData, userToken, lang
    } = useContext(UserContext);


    const { login, password } = sendData;
    const [codeInput, setCodeInput] = useState('')
    const [time, setTime] = useState(moment(new Date()).add(10, 'minutes')
        .toDate());
    const [transl, setTranslates] = useState(null)
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    useEffect(() => {
        getWidgetTranslates(lang, 'modal2FA', translation, setTranslates);
    }, [lang, translation]);

    const reSender = useCallback(() => {
        dataSender(
            `${API_SERVER_URL}/api/v1/user/security/send-code`,
            'POST',
            {
                login, password, event, type
            },
            () => {
                setTime(false)
                setAlertData({
                    type: 'success', cont: t(
                        '2fa_send-code', 'Код отправлен', 'widget', transl, 'modal2FA'
                    )
                })
                setTime(moment(new Date()).add(10, 'minutes')
                    .toDate());
                //setSendCodeAllow(false)
            },
            () => {
                setAlertData({
                    type: 'error', cont: t(
                        '2fa_Error-send-code', 'Ошибка при отправке кода', 'widget', transl, 'modal2FA'
                    )
                })
            },
            userToken,
            lang
        )
    }, [event, lang, login, password, setAlertData, transl, type, userToken])
    useEffect(() => {
        if (type === 'google') {
            setTime(moment(new Date()).add(10, 'minutes')
                .toDate())
        } else {
            reSender()
        }
        return () => {
            setShowModal(false)
            setCodeInput('')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const closeHandler = () => {
        if (ifClosed) ifClosed()
        setShowModal(false)
    }
    return (
        <>
            {showModal &&
        <Portal closeHandler={closeHandler}>
            <Div styles={styles.modalWrapper}>
                <Div styles={styles.closeWrap} data-uitest='663_uitest' onClick={closeHandler}>
                    {svgSprite('cross', {
                        style: styles.modalWindowCloseIcon,
                        fill: styles.variable.greyExtraDarkColor
                    })}
                </Div>
                <Div styles={styles.modalTitle}>{t(
                    '2fa_modal-Title', 'Введите код двухэтапной аутентификации', 'widget', transl, 'modal2FA'
                )}</Div>
                <Div styles={styles.middleWrapper}>
                    <Div styles={styles.inputWraper}>
                        <FormInput title={{
                            body: t(
                                '2fa_modal-CodeFromApp', 'Код из письма или приложения', 'widget', transl, 'modal2FA'
                            )
                        }} errors={showError ? formErrors : {}} compStyle={{ }} placeholder={'****'} value={codeInput} setFormData={(a, v) => { setCodeInput(v);senderFunc2FAAllow(true) }}
                        />
                    </Div>
                    <Div styles={styles.timeWrapper}>
                        {
                            time ?
                                <>
                                    <Div styles={styles.timeText}>{t(
                                        '2fa_modal-LifeSicle', 'Срок действия кода', 'widget', transl, 'modal2FA'
                                    )}</Div>
                                    <Timer translation={transl} styles={styles} date={time}/>
                                </>
                                : null
                        }
                    </Div>
                </Div>
                <Button disabled={!codeInput.length}
                    styles={{ ...styles.acceptBtn, ...!codeInput?.length ? { opacity: '0.5', 'pointer-events': 'none' }: {} }}
                    data-uitest='664_uitest'
                    onClick={() => {
                        senderFunc(codeInput, sender2FAAllow)
                    }}
                >
                    {t('confirm', 'Подтвердить', 'all', translation)}
                </Button>
                <Div styles={styles.reSendBtn} data-uitest='665_uitest' onClick={reSender}>{t(
                    '2fa_modal-send-again', 'Отправить код еще раз', 'widget', transl, 'modal2FA'
                )}</Div>
            </Div>
        </Portal>
            }
        </>
    )
}

Modal2FA.propTypes = {
    styles: PropTypes.object,
    type: PropTypes.string,
    event: PropTypes.string,
    sendData: PropTypes.object,
    senderFunc: PropTypes.func,
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    ifClosed: PropTypes.func
};
export default Styles(Modal2FA, getStyles)