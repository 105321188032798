import React, { useContext } from 'react';
import Link from 'next/link';
import { WebComponent } from './styled';
import ModalPortal from './library/portal/ModalPortal';
import AnimatedElement from './library/AnimatedElement';
import TooltipElement from './library/TooltipElement';
import AlertElement from './library/AlertElement';
import SvgSpriteWeb from './library/SvgSprite';
import FieldErrorElement from './library/FieldErrorElement';
import FieldInfoElement from './library/FieldInfoElement';
import SliderComponent from '../widgets/slider/SliderComponent';
import classNames from 'classnames';
import UserContext from '../common/utils/getContext';

export const zendoStorage = {
    get: (item) => {
        if (typeof window != 'undefined') {
            return window?.sessionStorage?.getItem(item) || window?.localStorage?.getItem(item)
        }
        return null
    },
    set: (item, val, session) => {
        if (typeof window != 'undefined') {
            session
                ? window.sessionStorage?.setItem(item, val)
                : window.localStorage?.setItem(item, val);
        }
    },

    remove: (item) => {
        window?.localStorage.removeItem(item)
        window?.sessionStorage.removeItem(item)
    }
};

export const svgSprite = (name, dataStyles, id, stroke = []) => {
    const [strokeDasharray, strokeDashoffset] = stroke;
    return <SvgSpriteWeb name={name} id={id} dataStyles={{
        width: '1.5rem', height: '1.5rem', fill: 'inherit', ...dataStyles
    }} strokeDasharray={strokeDasharray} strokeDashoffset={strokeDashoffset} />
};

export const Div = (props) => <WebComponent {...props} teg='div'>{props.children}</WebComponent>;

export const P = (props) => <WebComponent {...props} teg='p'>{props.children}</WebComponent>;

export const H1 = (props) => <WebComponent {...props} teg='h1'>{props.children}</WebComponent>;

export const H2 = (props) => <WebComponent {...props} teg='h2'>{props.children}</WebComponent>;

export const H3 = (props) => <WebComponent {...props} teg='h3'>{props.children}</WebComponent>;

export const H4 = (props) => <WebComponent {...props} teg='h4'>{props.children}</WebComponent>;

export const H5 = (props) => <WebComponent {...props} teg='h5'>{props.children}</WebComponent>;

export const H6 = (props) => <WebComponent {...props} teg='h6'>{props.children}</WebComponent>;

export const Label = (props) => <WebComponent {...props} teg='label'>{props.children}</WebComponent>;

export const A = (props) => {
    const { routerLoader } = useContext(UserContext);
    const linkProps = props.as ? { href: props.href, as: props.as } : { href: props.href };
    const linkPropsKey = Object.keys(linkProps);
    const compProps = Object.keys(props).reduce((accum, key) => {
        if (!linkPropsKey.includes(key)) accum[key] = props[key];

        return accum;
    }, {});
    return (
        <Link {...linkProps}>
            <a target={props.target} className={classNames(routerLoader ? 'disableEvents' : '')}>
                <WebComponent
                    {...compProps}
                    title={typeof compProps?.title === 'string' ? compProps?.title : ''}
                    teg='span'
                >
                    {props.title}
                    {props?.children ? props?.children : null }
                </WebComponent>
            </a>
        </Link>
    );
};

export const Header = (props) => <WebComponent {...props} teg='header'>{props.children}</WebComponent>;

export const Nav = (props) => <WebComponent {...props} teg='nav'>{props.children}</WebComponent>;

export const Footer = (props) => <WebComponent {...props} teg='footer'>{props.children}</WebComponent>;

export const Main = (props) => <WebComponent {...props} teg='main'>{props.children}</WebComponent>;

export const Form = (props) => <WebComponent {...props} teg='form'>{props.children}</WebComponent>;

export const Input = (props) => <WebComponent {...props} teg='input'>{props.children}</WebComponent>;

export const TextArea = (props) => <WebComponent {...props} teg='textarea'>{props.children}</WebComponent>;

export const Button = (props) => <WebComponent {...props} teg='button'>{props.children}</WebComponent>;

export const Img = (props) => <WebComponent {...props} {...(props.alt ? {} : { alt: props.src })} teg='img' />;

export const Portal = (props) => <ModalPortal {...props}>{props.children}</ModalPortal>;

export const Table = (props) => <WebComponent {...props} teg='table'>{props.children}</WebComponent>;

export const TR = (props) => <WebComponent {...props} teg='tr'>{props.children}</WebComponent>;

export const Tbody = (props) => <tbody {...props}>{props.children}</tbody>;

export const Thead = (props) => <tbody {...props}>{props.children}</tbody>;

export const TD = (props) => <WebComponent {...props} teg='td'>{props.children}</WebComponent>;

export const TH = (props) => <WebComponent {...props} teg='th'>{props.children}</WebComponent>;

export const AnimatedDiv = (props) => <AnimatedElement {...props}>{props.children}</AnimatedElement>;

export const Tooltip = (props) => <TooltipElement {...props}>{props.children}</TooltipElement>;

export const Alert = (props) => <AlertElement {...props}>{props.children}</AlertElement>;

export const FieldError = (props) => <FieldErrorElement {...props}>{props.children}</FieldErrorElement>;

export const FieldInfo = (props) => <FieldInfoElement {...props}>{props.children}</FieldInfoElement>;

export const BlankLink = (props) => {
    const linkProps = props.as ? { href: props.href, as: props.as } : { href: props.href };
    const linkPropsKey = Object.keys(linkProps);
    const compProps = Object.keys(props).reduce((accum, key) => {
        if (!linkPropsKey.includes(key)) accum[key] = props[key];

        return accum;
    }, {});
    return (
        <a style={props?.icon? { display: 'flex', 'alignItems': 'center' } : {}} target={props.target || '_blank'} href={props.href} rel='nofollow' title={''}>
            {props?.icon ? props?.icon : null}
            <WebComponent {...compProps} teg='span' title={''}>{props.title}</WebComponent>
        </a>
    );
};

export const FakeLink = (props) => {
    return (
        <WebComponent
            className='fakeLink'
            tagName='a'
            {...(!props.follow ? { rel: 'nofollow' } : {})}
            {...props}
        />
    );
};

export const Slider = (props) => <SliderComponent {...props} />;

export const Svg = (props) => <svg {...props}>{props.children}</svg>;
export const Path = (props) => <path {...props}>{props.children}</path>;
export const Defs = (props) => <defs {...props}>{props.children}</defs>;
export const Circle = (props) => <circle {...props}>{props.children}</circle>;
export const G = (props) => <g {...props}>{props.children}</g>;
export const LinearGradient = (props) => <linearGradient {...props}>{props.children}</linearGradient>;
export const RadialGradient = (props) => <radialGradient {...props}>{props.children}</radialGradient>;
export const Mask = (props) => <mask {...props}>{props.children}</mask>;
export const Stop = (props) => <stop {...props}>{props.children}</stop>;

export const getDimensions = () => window ? { width: window.innerWidth, height: window.innerHeight } : {};
export const eventDimension = (func, remove) => remove ? window.removeEventListener('resize', func) : window.addEventListener('resize', func);