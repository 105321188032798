import React, {
    useMemo, useContext, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import {
    Div, P, Portal, svgSprite
} from '../../hybrid/wrappers';
import SingleButton from '../form/form-control-buttons/SingleButton';
import getStyles from '../../project/styles/widget-styles/deletemodal-styles';
import Styles from '../../common/decorators/Styles';
import { LangContext } from '../../common/decorators/Language';
import { t } from '../../common/components/T';
import FormComponent from '../form/FormComponent';
import Display from '../../common/components/Display';
import uikit from '../../project/uikit/styles';
import { getData } from '../../common/utils/router';
import UserContext from '../../common/utils/getContext';

const DeleteModal = ({
    children, styles, showModal, setShowModal, typeModal, title, text,
    modalFooterShow = true, buttons, customStyles = {},
    field = false, setData, warningTextDelete = false,
    warningTextBlock = false, showUserName, isMainMarketing= false, deliveryInfo
}) => {
    const { translation } = useContext(LangContext) || {};
    const { userToken, lang } = useContext(UserContext);
    const [mainMarketing, setMainMarketing] = useState(null)
    const compStyles = useMemo(() => (
        {
            ...customStyles, ...Object.keys(styles).reduce((accum, item) =>
                Object.assign(accum, { [item]: { ...styles[item], ...customStyles[item] || {} } })
            , {})
        } || {}
    ), [styles, customStyles]);

    useEffect(() => {
        (async () => {
            if (isMainMarketing){
                const marketingData = await getData('/api/v1/admin/marketing', userToken, lang);
                const activeMarketing = marketingData?.data?.find(el => el.status === 'active')
                setMainMarketing(activeMarketing)
            }
        })();
    }, [lang, userToken, isMainMarketing]);

    return (
        <>
            {
                !!showModal
            &&
                <Portal customClass={typeModal || ''} closeHandler={setShowModal}>
                    <Div styles={compStyles.modalWrapper}>
                        <Div styles={compStyles.modalHeader}>
                            <P styles={compStyles.modalTitle}>{title || t('confirmAction', 'Подтвердите действие', 'all', translation)}</P>
                            <Div
                                data-uitest='574_uitest' onClick={() => setShowModal(false)}
                                styles={compStyles.modalClose}
                            >
                                {svgSprite('cross', {
                                    style: compStyles.modalCloseSvg,
                                    fill: styles.variable.greyExtraDarkColor
                                })}
                            </Div>
                        </Div>

                        <Div styles={compStyles.modalBody}>
                            {!!children
                            && children}
                            {!!text
                            && <P styles={compStyles.modalBodyText}>{text}</P>}
                            {
                                !!deliveryInfo
                            && <P styles={compStyles.modalBodyText}>{deliveryInfo}</P>
                            }
                            {showUserName ?
                                <Div styles={{
                                    ...showModal?.rowData?.login?.first_name || showModal?.rowData?.login?.last_name || showModal?.rowData?.login?.patronymic ? { 'margin-right': '6px' } : {},
                                    ...uikit.flexRow, ...uikit.justifyContentCenter, ...uikit.alignItemsCenter, ...uikit.medium, ...uikit.flexWrap
                                }}>
                                    <Div styles={{ ...uikit.flexRow, ...uikit.alignItemsCenter }}>
                                        {svgSprite('user-circle-fill', { fill: styles.variable.blueColor })}
                                        <P styles={{ 'margin-left': '6px' }}>{showModal?.rowData?.login?.username}</P>
                                    </Div>
                                    <P styles={{ 'margin-left': '6px' }}>
                                        <Display type='fio' data={{
                                            first_name: showModal?.rowData?.login?.first_name,
                                            last_name: showModal?.rowData?.login?.last_name,
                                            patronymic: showModal?.rowData?.login?.patronymic
                                        }}/>
                                        <P styles={{ opacity: '.6' }} tagName={'span'}>?</P>
                                    </P>
                                </Div>
                                : null}
                            {warningTextBlock ?
                                <Div styles={compStyles.modalWarningWrapper}>
                                    <P styles={compStyles.modalWarningTitle}>{t(
                                        'modal_block_title_block_list', 'Блокировка приведет к следующим условиям:', 'widget', translation, 'accordion'
                                    )}</P>
                                    <Div styles={compStyles.modalWarningList}>
                                        <Div styles={compStyles.modalWarningListItem}>
                                            <Div>{svgSprite('cross', { style: compStyles.modalWarningListIco })}</Div>
                                            {t(
                                                'modal_block_text_11', 'Пользователь теряет доступ к аккаунту', 'widget', translation, 'accordion'
                                            )}
                                        </Div>
                                        {mainMarketing && mainMarketing?.blocked_involved === true ?
                                            <Div styles={compStyles.modalWarningListItem}>
                                                <Div>{svgSprite('check', {
                                                    style: {
                                                        ...compStyles.modalWarningListIco,
                                                        fill: styles.variable.greenColor
                                                    }
                                                })}</Div>
                                                {t(
                                                    'modal_block_text_22', 'Пользователь продолжает участвовать в получении бонусов по маркетингу', 'widget', translation, 'accordion'
                                                )}
                                            </Div> :
                                            <Div styles={compStyles.modalWarningListItem}>
                                                <Div>{svgSprite('deleteModalIco', {
                                                    style: {
                                                        ...compStyles.modalWarningListIco,
                                                        fill: styles.variable.greenColor
                                                    }
                                                })}</Div>
                                                {t(
                                                    'modal_block_text_22-no', 'Пользователь не продолжает участвовать в получении бонусов по маркетингу', 'widget', translation, 'accordion'
                                                )}
                                            </Div>}
                                        <Div styles={compStyles.modalWarningListItem}>
                                            <Div>{svgSprite('check', {
                                                style: {
                                                    ...compStyles.modalWarningListIco,
                                                    fill: styles.variable.greenColor
                                                }
                                            })}</Div>
                                            {t(
                                                'modal_block_text_33', 'Аккаунт может быть восстановлен', 'widget', translation, 'accordion'
                                            )}

                                        </Div>
                                    </Div>
                                </Div> : null}
                            {warningTextDelete ?
                                <Div styles={compStyles.modalWarningWrapper}>
                                    <P styles={compStyles.modalWarningTitle}>{t(
                                        'modal_block_title_delete_list', 'Удаление приведет к следующим условиям:', 'widget', translation, 'accordion'
                                    )}</P>
                                    <Div styles={compStyles.modalWarningList}>
                                        <Div styles={compStyles.modalWarningListItem}>
                                            <Div>{svgSprite('cross', { style: compStyles.modalWarningListIco })}</Div>
                                            {t(
                                                'modal_block_text_1', 'Пользователь теряет доступ к аккаунту', 'widget', translation, 'accordion'
                                            )}
                                        </Div>

                                        <Div styles={compStyles.modalWarningListItem}>
                                            <Div>{svgSprite(mainMarketing && mainMarketing?.deleted_involved === false ?
                                                'deleteModalIco' :'check', {
                                                style: {
                                                    ...compStyles.modalWarningListIco,
                                                    ...mainMarketing && mainMarketing?.deleted_involved === false ? {} : { fill: styles.variable.greenColor }
                                                }
                                            })}</Div>
                                            {mainMarketing && mainMarketing?.deleted_involved === false ?
                                                t(
                                                    'modal_delete_text_1', 'Пользователь не учавствует в получении бонусов по маркетингу', 'widget', translation, 'accordion'
                                                ) :
                                                t(
                                                    'modal_delete_text_1-true', 'Пользователь учавствует в получении бонусов по маркетингу', 'widget', translation, 'accordion'
                                                )
                                            }
                                        </Div>
                                        <Div styles={compStyles.modalWarningListItem}>
                                            <Div>{svgSprite('cross', { style: compStyles.modalWarningListIco })}</Div>
                                            {t(
                                                'modal_delete_text_2', 'Вывод средств со счета возможен только через администратора в ручном режиме', 'widget', translation, 'accordion'
                                            )}
                                        </Div>
                                        <Div styles={compStyles.modalWarningListItem}>
                                            <Div>{svgSprite('cross', { style: compStyles.modalWarningListIco })}</Div>
                                            {t(
                                                'modal_delete_text_3', 'Восстановление аккаунта невозможно!', 'widget', translation, 'accordion'
                                            )}
                                        </Div>
                                    </Div>
                                </Div> : null}
                            {!!field &&
                                    <Div>
                                        <FormComponent
                                            data={
                                                [
                                                    {
                                                        attribute: field?.attribute,
                                                        placeholder: field?.placeholder,
                                                        title: { body: field?.title, text: '' },
                                                        type: 'textarea',
                                                        value: null
                                                    }
                                                ]
                                            }
                                            setFormDataMain={setData}
                                            sendData={{}}
                                            styles={{
                                                ...styles,
                                                groupFormFieldWrap: styles.adminSettingsFormFieldWrapperWeb,
                                                subtitle: styles.adminSettingssubtitle,
                                                hr: styles.adminSettingshr
                                            }}
                                            funcs={{}}
                                            customData={{}}
                                            buttonNotShow={true}
                                        />
                                    </Div>
                            }
                        </Div>

                        {modalFooterShow
                        &&
                            <Div styles={compStyles.modalFooter}>
                                {
                                    buttons.map((item, idx) =>
                                        <SingleButton
                                            key={`button${idx}`}
                                            customStyles={styles}
                                            type={item.type}
                                            clickFunc={item.clickFunc}
                                            buttonText={
                                                item.buttonText
                                                    ? item.buttonText
                                                    : item.type === 'ok'
                                                        ? t('send', 'Отправить', 'all', translation)
                                                        : t('cancel', 'Отмена', 'all', translation)
                                            }
                                            customButton={{
                                                ...compStyles.singleButton ? { singleButton: compStyles.singleButton } : {},
                                                ...item.type === 'ok' ? {
                                                    butWrapper: {
                                                        ...compStyles.formButtonPrimary,
                                                        ...compStyles.formButWrapper
                                                    }
                                                } : { butWrapper: compStyles.formButWrapper }
                                            }}
                                        />)
                                }
                            </Div>
                        }
                    </Div>
                </Portal>

            }
        </>
    )
};

DeleteModal.propTypes = {
    styles: PropTypes.object,
    showModal: PropTypes.object,
    setShowModal: PropTypes.func,
    typeModal: PropTypes.string,
    title: PropTypes.node,
    text: PropTypes.node,
    modalFooterShow: PropTypes.bool,
    buttons: PropTypes.array,
    customStyles: PropTypes.object,
    field: PropTypes.object,
    setData: PropTypes.func,
    warningTextDelete: PropTypes.bool,
    warningTextBlock: PropTypes.bool,
    showUserName: PropTypes.bool,
    mainMarketing: PropTypes.bool
};
export default Styles(DeleteModal, getStyles);