import React from 'react';
import PropTypes from 'prop-types';
import {
    P,
    Tooltip
} from '../../../hybrid/wrappers';

const TextHint = ({
    getHandlers, gridStyles, name, val
}) => {
    return (
        <P
            {...getHandlers(false)}
            styles={gridStyles[name]?.wrapper?.styles || {}}
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >
            <Tooltip text={val?.hint}>
                <P>{val?.text}</P>
            </Tooltip>
        </P>
    );
};

TextHint.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    name: PropTypes.string,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ])
};

export default TextHint;