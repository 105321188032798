// @generated: @expo/next-adapter@2.1.5
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { A } from '../../../hybrid/wrappers';
import UserContext from '../../../common/utils/getContext';

const Title = ({
    body,
    links,
    Comp,
    data,
    linkData
}) => {
    const elemProps = data ? { ...data } : {};
    const { lang } = useContext(UserContext);
    if (links && Object.keys(links).length) {
        const linksProps = linkData || {};

        const linksJsx = Object.keys(links).reduce((accum, linkKey) => {
            const linkObj = links[linkKey];
            const href = lang && linkObj.href && linkObj.href[0] === '/' && linkObj.href.slice(1, lang.length + 1) !== 'lang' ? `/${lang}${linkObj.href}` : linkObj.href
            accum[linkKey] =
              <A
                  key={`titleLink${linkKey}`}
                  {...linksProps}
                  title={linkObj.title}
                  href={href}
                  target={linkObj.target}
              />;
            return accum;
        }, {});

        return (
            <Comp {...elemProps} styles={{ marginRight: '10px' }}>
                {
                    body.split('${').reduce((arr, part, idx) => {
                        if (idx === 0) {
                            arr.push(<span key={`title${idx}`}>{part}</span>);
                            return arr;
                        }

                        const splitPart = part.split('}');
                        const text = splitPart.slice(1).join('}');
                        arr.push(<span key={`title${idx}var`}>{linksJsx[splitPart[0]]}</span>);
                        arr.push(<span key={`title${idx}text`}>{text}</span>);
                        return arr;
                    }, [])
                }
            </Comp>
        );
    }

    return <Comp {...elemProps}>{body}</Comp>;
};

Title.propTypes = {
    body: PropTypes.node,
    links: PropTypes.object,
    data: PropTypes.object,
    linkData: PropTypes.object
};

export default Title;