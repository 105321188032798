import React, { useMemo } from 'react';
import {
    Div,
    P,
    svgSprite,
    Tooltip
} from '../../../hybrid/wrappers';
import T from '../../../common/components/T';
import { packVariantList } from '../../../common/utils/datas';


const PackItem = ({
    pack,
    styles,
    isPackBlockedOrHidden,
    packStat,
    cartTotalSum,
    currentUserPack,
    packListActive,
    isPartner,
    type = 'store_widget'
}) => {
    const packStyles = useMemo(() => {
        return packVariantList?.find(el => el?.id === pack?.id);
    }, [pack?.id]);


    const CartSumPlusAccumulated = pack?.id < 3
        ? cartTotalSum + pack?.user_total_personal_volume
        : cartTotalSum;
    const isBlocked = isPackBlockedOrHidden(pack);
    const isNotEnough = packStat?.current?.id
        ? pack?.id > packStat?.current?.id
        : true;
    const packWidth = type === 'cart_hover'
        ? styles.widthCartHover
        : type === 'cart_widget'
            ? styles.widthCartWidget
            : styles.widthStoreWidget;
    return (
        <Div styles={{
            ...styles.packWrap,
            ...pack?.id !== packListActive?.[0]?.id
                ? { 'flex-grow': 1 }
                : {}
        }}>
            {pack?.id !== packListActive?.[0]?.id
                ? <Div styles={styles.progressBar}>
                    <Div
                        styles={{
                            'background-color': packStyles?.color,
                            height: '3px',
                            width: `${CartSumPlusAccumulated >= pack?.price || currentUserPack?.id >= pack?.id
                                ? !isPartner && pack?.id > 2 ? 0 : 100
                                : packStat?.next?.id === pack?.id
                                    ? 100 / (pack?.price / CartSumPlusAccumulated)
                                    : 0}%`
                        }}
                    />
                </Div>
                : null}
            <Div styles={{
                ...styles.packBg,
                ...packWidth,
                opacity: isNotEnough
                    ? 0.5
                    : 1,
                'background-color': packStyles?.blockBg,
                'background-image': `url(../../../../../src/img/store/bg.png), ${packStyles?.bg}`,
                'box-shadow': isNotEnough || isBlocked
                    ? 'none'
                    : packStyles?.shadow
            }}>
                {(!isPartner && pack?.id > 2 || isBlocked) && <Div styles={{
                    ...styles.packWrapperBlock, background: packStyles?.blockBg, 'backdrop-filter': 'blur(3px)'
                }}>
                    <Div styles={styles.packageWrapperBlockIcon}>
                        {svgSprite('lock1', styles.packageWrapperBlockIconSvg)}
                    </Div>
                </Div>}
                <Div styles={styles.packNameTitle}>
                    <Div styles={styles.packIcon}>
                        {svgSprite(`pack_op_${pack?.id}`)}
                    </Div>
                    <P styles={styles.packNameLabel}>{pack?.name}</P>
                </Div>
                <Div styles={{ ...styles.packPriceLabel }}>
                    {pack?.id <= currentUserPack?.id
                        ? <T textName='storeCurrentPackHas' defaultStr='Получен' page='/store'/>
                        : pack?.id >= 3
                            ? <Tooltip text={<T textName='price_item_tooltip_text'
                                defaultStr='Сумма личной покупки, которая должна быть совершена одной финансовой операцией.'
                                page='/office'/>} className='info_icon_wrapper'>
                                <Div styles={{ display: 'flex' }}>
                                    <Div>{pack?.formatted?.price}</Div>
                                    <Div styles={{ 'margin-left': '2px' }}>
                                        {svgSprite('menu-faq', {
                                            width: '14px',
                                            height: '14px',
                                            fill: '#ffffff'
                                        })}
                                    </Div>
                                </Div>
                            </Tooltip>
                            : <Div>{pack?.formatted?.price}</Div>
                    }
                </Div>
            </Div>
        </Div>
    );
};

export default PackItem;