import { components } from 'react-select';
import { Div } from '../../../../../hybrid/wrappers';
import FromInputTitle from '../../FormFieldTitle';
import { getIsCoupleVariation } from '../../helpers';

const getControl = (props) => {
    const {
        title, hint, required, val, insertComponent, styles, compStyle, variationStyle, errors
    } = props?.selectProps?.myProps;
    return (
        <Div
            childrenRef={props?.innerRef}
            styles={
                {
                    ...styles[`formControl__${variationStyle}`],
                    ...compStyle?.input || {},
                    ...errors && errors.length
                        ? {
                            ...styles[`formControlError__${variationStyle}`],
                            ...compStyle?.inputError || {}
                        } : {
                            ...props?.menuIsOpen
                                ? {
                                    ...styles[`formControlFocus__${variationStyle}`],
                                    ...compStyle?.inputFocus || {}
                                } : {},
                            ...props?.isDisabled
                                ? {
                                    ...styles['formControlDisabled'],
                                    ...compStyle?.inputDisabled || {}
                                } : {}
                        }
                }
            }>
            <Div styles={styles.formControlContent}>
                <Div styles={styles.formControlInner}>
                    {
                        !!title && getIsCoupleVariation(variationStyle) ?
                            <FromInputTitle
                                styles={styles}
                                errors={errors}
                                title={title}

                                compStyle={compStyle}
                                hint={hint}
                                required={required}
                                isFilled={Boolean(props?.isMulti ? val?.length : val?.id || val?.value)}
                                variationStyle={variationStyle}
                                focused={props?.menuIsOpen}
                            /> : null
                    }
                    <components.Control {...props} />
                </Div>
                {insertComponent}
            </Div>
        </Div>
    )
}
export default getControl

// import { components } from 'react-select';
// import { Div } from '../../../../../hybrid/wrappers';
// import FromInputTitle from '../../FormFieldTitle';
// import { getIsCoupleVariation } from '../../helpers';

// const getControl = (
//     title, hint, required, val, insertComponent, styles, compStyle, variationStyle, errors
// ) => {
//     const Control = (props) => {
//         return (
//             <Div
//                 childrenRef={props?.innerRef}
//                 styles={
//                     {
//                         ...styles[`formControl__${variationStyle}`],
//                         ...compStyle?.input || {},
//                         ...errors && errors.length
//                             ? {
//                                 ...styles[`formControlError__${variationStyle}`],
//                                 ...compStyle?.inputError || {}
//                             } : {
//                                 ...props?.menuIsOpen
//                                     ? {
//                                         ...styles[`formControlFocus__${variationStyle}`],
//                                         ...compStyle?.inputFocus || {}
//                                     } : {},
//                                 ...props?.isDisabled
//                                     ? {
//                                         ...styles['formControlDisabled'],
//                                         ...compStyle?.inputDisabled || {}
//                                     } : {}
//                             }
//                     }
//                 }>
//                 <Div styles={styles.formControlContent}>
//                     <Div styles={styles.formControlInner}>
//                         {
//                             !!title && getIsCoupleVariation(variationStyle) ?
//                                 <FromInputTitle
//                                     styles={styles}
//                                     errors={errors}
//                                     title={title}

//                                     compStyle={compStyle}
//                                     hint={hint}
//                                     required={required}
//                                     isFilled={Boolean(props?.isMulti ? val?.length : val?.id || val?.value)}
//                                     variationStyle={variationStyle}
//                                     focused={props?.menuIsOpen}
//                                 /> : null
//                         }
//                         <components.Control {...props} />
//                     </Div>
//                     {insertComponent}
//                 </Div>
//             </Div>
//         )
//     };
//     return Control;
// }
// export default getControl