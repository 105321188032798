import React from 'react';
import {
    Svg, Path, G, Circle
} from '../../hybrid/wrappers';

const SvgSpriteAdminCore = ({ name, dataStyles }) => {
    switch (name) {
    case 'cookies-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 40 40' fill='none'>
                <G clipPath='url(#clip0_19815_244475)'>
                    <Path d='M12.7691 27.2084C12.187 27.1713 11.6007 27.0965 11.013 26.9819C3.63227 25.5432 -1.18463 18.3936 0.254085 11.013C1.6928 3.63227 8.84233 -1.18463 16.223 0.254085C22.4401 1.466 26.838 6.72982 27.2104 12.7707L12.7691 27.2084Z' fill='#E2894C'/>
                    <Path d='M13.0037 0.25404C19.2207 1.46595 23.6186 6.72977 23.991 12.7707L23.9897 12.927C23.9799 14.1457 23.8184 15.3278 23.5241 16.456L27.2103 12.7707C26.8379 6.72977 22.44 1.46587 16.223 0.25404C14.7928 -0.0247529 13.3715 -0.0676503 11.9985 0.0964544C12.3328 0.136374 12.6679 0.188607 13.0037 0.25404Z' fill='#D47C3F'/>
                    <Path d='M35.9227 21.3966C35.803 21.2769 35.6969 21.149 35.6025 21.0154C34.5489 21.4619 33.2848 21.2564 32.4259 20.3976C31.567 19.5387 31.3616 18.2745 31.8082 17.221C31.6745 17.1267 31.5465 17.0205 31.4269 16.9008C30.5162 15.9901 30.3399 14.6236 30.897 13.5357C29.4849 13.0398 27.9666 12.7692 26.385 12.7692C18.8654 12.7692 12.7695 18.865 12.7695 26.3846C12.7695 33.9042 18.8653 40 26.3849 40C33.9045 40 40.0004 33.9041 40.0004 26.3845C40.0004 24.8286 39.7386 23.3338 39.2581 21.9412C38.1752 22.4801 36.8251 22.2991 35.9227 21.3966Z' fill='#F0AA7D'/>
                    <Path d='M39.2573 21.9412C38.1744 22.4801 36.8245 22.2991 35.9219 21.3966C35.8023 21.2769 35.6961 21.149 35.6018 21.0154C34.5482 21.4619 33.284 21.2564 32.4252 20.3976C31.5664 19.5387 31.3609 18.2745 31.8074 17.221C31.6737 17.1267 31.5458 17.0205 31.4262 16.9008C30.5154 15.9901 30.3392 14.6236 30.8963 13.5357C29.4841 13.0398 27.9659 12.7692 26.3842 12.7692C25.8394 12.7692 25.3025 12.8022 24.7744 12.8644C25.7794 12.9831 26.7511 13.2106 27.6769 13.5357C27.1198 14.6235 27.296 15.99 28.2068 16.9008C28.3264 17.0205 28.4543 17.1267 28.588 17.221C28.1415 18.2746 28.347 19.5387 29.2058 20.3976C30.0647 21.2565 31.3289 21.4619 32.3824 21.0154C32.4767 21.149 32.5829 21.277 32.7025 21.3966C33.605 22.2991 34.955 22.4801 36.0379 21.9412C36.5186 23.3339 36.7802 24.8286 36.7802 26.3846C36.7802 33.3595 31.5352 39.1084 24.7744 39.9049C25.3024 39.9671 25.8394 40 26.3841 40C33.9037 40 39.9996 33.9041 39.9996 26.3845C39.9996 24.8286 39.7379 23.3339 39.2573 21.9412Z' fill='#E89D6B'/>
                    <Path d='M14.1976 13.6177C14.9861 13.6177 15.6252 12.9785 15.6252 12.1901C15.6252 11.4016 14.9861 10.7625 14.1976 10.7625C13.4092 10.7625 12.77 11.4016 12.77 12.1901C12.77 12.9785 13.4092 13.6177 14.1976 13.6177Z' fill='#9B4F1D'/>
                    <Path d='M20.5985 9.31737C21.387 9.31737 22.0261 8.67821 22.0261 7.88976C22.0261 7.10132 21.387 6.46216 20.5985 6.46216C19.8101 6.46216 19.1709 7.10132 19.1709 7.88976C19.1709 8.67821 19.8101 9.31737 20.5985 9.31737Z' fill='#9B4F1D'/>
                    <Path d='M6.30261 9.88658C7.09105 9.88658 7.73021 9.24742 7.73021 8.45898C7.73021 7.67053 7.09105 7.03137 6.30261 7.03137C5.51416 7.03137 4.875 7.67053 4.875 8.45898C4.875 9.24742 5.51416 9.88658 6.30261 9.88658Z' fill='#9B4F1D'/>
                    <Path d='M6.30261 19.9744C7.09105 19.9744 7.73021 19.3352 7.73021 18.5468C7.73021 17.7583 7.09105 17.1191 6.30261 17.1191C5.51416 17.1191 4.875 17.7583 4.875 18.5468C4.875 19.3352 5.51416 19.9744 6.30261 19.9744Z' fill='#9B4F1D'/>
                    <Path d='M17.0386 25.3866C17.0386 24.208 17.9939 23.2527 19.1724 23.2527C20.3509 23.2527 21.3063 24.208 21.3063 25.3866C21.3063 26.565 20.3509 27.5204 19.1724 27.5204C17.9939 27.5204 17.0386 26.5651 17.0386 25.3866ZM21.4545 34.2392C20.666 34.2392 20.0269 33.6 20.0269 32.8116C20.0269 32.0231 20.6661 31.384 21.4545 31.384C22.243 31.384 22.8821 32.0232 22.8821 32.8116C22.8822 33.6 22.243 34.2392 21.4545 34.2392ZM25.4298 20.7742C24.6413 20.7742 24.0022 20.135 24.0022 19.3466C24.0022 18.5581 24.6414 17.9189 25.4298 17.9189C26.2183 17.9189 26.8574 18.5581 26.8574 19.3466C26.8574 20.135 26.2183 20.7742 25.4298 20.7742ZM33.1085 30.6522C31.9301 30.6522 30.9747 29.6969 30.9747 28.5184C30.9747 27.3399 31.93 26.3845 33.1085 26.3845C34.287 26.3845 35.2424 27.3399 35.2424 28.5184C35.2424 29.6969 34.2869 30.6522 33.1085 30.6522Z' fill='#9B4F1D'/>
                </G>
                <defs>
                    <clipPath id='clip0_19815_244475'>
                        <rect width='40' height='40' fill='white'/>
                    </clipPath>
                </defs>
            </Svg>
        );

    case 'excel-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 24 24'>
                <Path d='M23.25 20.2505H12.75C12.336 20.2505 12 19.9145 12 19.5005C12 19.0865 12 4.91452 12 4.50052C12 4.08652 12.336 3.75052 12.75 3.75052H23.25C23.664 3.75052 24 4.08652 24 4.50052V19.5005C24 19.9145 23.664 20.2505 23.25 20.2505Z' fill='#ECEFF1'/>
                <Path d='M15.75 8.25052H12.75C12.336 8.25052 12 7.91452 12 7.50052C12 7.08652 12.336 6.75052 12.75 6.75052H15.75C16.164 6.75052 16.5 7.08652 16.5 7.50052C16.5 7.91452 16.164 8.25052 15.75 8.25052Z' fill='#388E3C'/>
                <Path d='M15.75 11.2505H12.75C12.336 11.2505 12 10.9145 12 10.5005C12 10.0865 12.336 9.75052 12.75 9.75052H15.75C16.164 9.75052 16.5 10.0865 16.5 10.5005C16.5 10.9145 16.164 11.2505 15.75 11.2505Z' fill='#388E3C'/>
                <Path d='M15.75 14.2505H12.75C12.336 14.2505 12 13.9145 12 13.5005C12 13.0865 12.336 12.7505 12.75 12.7505H15.75C16.164 12.7505 16.5 13.0865 16.5 13.5005C16.5 13.9145 16.164 14.2505 15.75 14.2505Z' fill='#388E3C'/>
                <Path d='M15.75 17.2505H12.75C12.336 17.2505 12 16.9145 12 16.5005C12 16.0865 12.336 15.7505 12.75 15.7505H15.75C16.164 15.7505 16.5 16.0865 16.5 16.5005C16.5 16.9145 16.164 17.2505 15.75 17.2505Z' fill='#388E3C'/>
                <Path d='M20.25 8.25052H18.75C18.336 8.25052 18 7.91452 18 7.50052C18 7.08652 18.336 6.75052 18.75 6.75052H20.25C20.664 6.75052 21 7.08652 21 7.50052C21 7.91452 20.664 8.25052 20.25 8.25052Z' fill='#388E3C'/>
                <Path d='M20.25 11.2505H18.75C18.336 11.2505 18 10.9145 18 10.5005C18 10.0865 18.336 9.75052 18.75 9.75052H20.25C20.664 9.75052 21 10.0865 21 10.5005C21 10.9145 20.664 11.2505 20.25 11.2505Z' fill='#388E3C'/>
                <Path d='M20.25 14.2505H18.75C18.336 14.2505 18 13.9145 18 13.5005C18 13.0865 18.336 12.7505 18.75 12.7505H20.25C20.664 12.7505 21 13.0865 21 13.5005C21 13.9145 20.664 14.2505 20.25 14.2505Z' fill='#388E3C'/>
                <Path d='M20.25 17.2505H18.75C18.336 17.2505 18 16.9145 18 16.5005C18 16.0865 18.336 15.7505 18.75 15.7505H20.25C20.664 15.7505 21 16.0865 21 16.5005C21 16.9145 20.664 17.2505 20.25 17.2505Z' fill='#388E3C'/>
                <Path d='M13.2285 0.923083C13.0575 0.780583 12.828 0.719083 12.612 0.764083L0.612 3.01408C0.2565 3.08008 0 3.38908 0 3.75058V20.2506C0 20.6106 0.2565 20.9211 0.612 20.9871L12.612 23.2371C12.657 23.2461 12.7035 23.2506 12.75 23.2506C12.924 23.2506 13.0935 23.1906 13.2285 23.0781C13.401 22.9356 13.5 22.7226 13.5 22.5006V1.50058C13.5 1.27708 13.401 1.06558 13.2285 0.923083Z' fill='#2c4135'/>
                <Path d='M10.314 14.507L7.94254 11.7965L10.341 8.71246C10.596 8.38546 10.536 7.91446 10.2105 7.65946C9.88504 7.40446 9.41404 7.46446 9.15754 7.78996L6.93604 10.646L5.06404 8.50696C4.78954 8.19196 4.31554 8.16346 4.00654 8.43646C3.69454 8.70946 3.66304 9.18346 3.93604 9.49396L5.99854 11.852L3.90754 14.54C3.65254 14.867 3.71254 15.338 4.03804 15.593C4.17604 15.6995 4.33954 15.7505 4.50004 15.7505C4.72354 15.7505 4.94404 15.6515 5.09254 15.461L7.00504 13.001L9.18604 15.4925C9.33454 15.6635 9.54154 15.7505 9.75004 15.7505C9.92554 15.7505 10.101 15.689 10.2435 15.5645C10.5555 15.2915 10.587 14.8175 10.314 14.507Z' fill='#FAFAFA'/>
            </Svg>
        );
    case 'plug-user-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 100 100'>
                <Circle cx='50' cy='50' r='50' fill='#8F8FAB' fill-opacity='0.1'/>
                <Path d='M86.2728 79.2375C84.1013 77.5188 81.4556 76.2812 78.5853 75.6813L64.9012 72.9312C63.466 72.6562 62.4302 71.3687 62.4302 69.8687V66.725C63.31 65.4875 64.1399 63.8437 65.0073 62.125C65.6812 60.7937 66.6983 58.7875 67.2037 58.2687C69.9742 55.4813 72.6511 52.35 73.481 48.3125C74.2548 44.525 73.4935 42.5375 72.6012 40.9375C72.6012 36.9438 72.4764 31.9437 71.5342 28.3062C71.4219 23.3812 70.5296 20.6125 68.2832 18.1875C66.6983 16.4688 64.3646 16.0687 62.4863 15.75C61.75 15.625 60.7329 15.45 60.3585 15.25C57.0327 13.45 53.7443 12.5688 49.8194 12.5C41.6014 12.8375 31.4991 18.075 28.117 27.4125C27.0687 30.2563 27.1748 34.925 27.2622 38.675L27.1811 40.9312C26.3761 42.5062 25.5836 44.5063 26.3636 48.3063C27.1873 52.35 29.8642 55.4875 32.6846 58.3125C33.1464 58.7875 34.1885 60.8125 34.8748 62.15C35.7547 63.8625 36.5908 65.5 37.4706 66.7313V69.875C37.4706 71.3688 36.4286 72.6562 34.9872 72.9375L21.2906 75.6875C18.439 76.2937 15.7933 77.5188 13.628 79.2375C12.9541 79.7812 12.5298 80.575 12.4612 81.4375C12.3925 82.3 12.6796 83.1438 13.2599 83.7875C22.5823 94.0875 35.9543 100 49.9504 100C63.9465 100 77.3248 94.0938 86.641 83.7875C87.2213 83.1438 87.5145 82.2938 87.4397 81.4313C87.371 80.5687 86.9467 79.775 86.2728 79.2375Z' fill='#D5D5E0'/>
            </Svg>
        );
    case 'plug-product-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 64 64'>
                <Path d='M62 14.7499V49.25C62 52.97 58.97 56 55.25 56H8.74998C8.45005 56 8.15013 55.9698 7.87986 55.9396C4.73009 55.5501 2.27026 52.97 2.03021 49.79C2 49.6099 2 49.4297 2 49.25V14.7499C2 11.03 5.03002 8 8.74998 8H55.25C58.97 8 62 11.03 62 14.7499Z' fill='#f6f0e6'/>
                <Path d='M22 22C22 25.3134 19.3138 28 16.0003 28C12.6862 28 10 25.3134 10 22C10 18.6866 12.6862 16 16.0003 16C19.3138 16 22 18.6866 22 22Z' fill='#dcc6a3'/>
                <Path d='M62 41.0487V49.3518C62 53.0157 58.9767 56 55.265 56H8.86818C8.56892 56 8.26966 55.9702 8 55.9405L38.8315 25.507C40.8671 23.4977 44.2192 23.4977 46.2549 25.507L62 41.0487Z' fill='#9e8a6a'/>
                <Path d='M44 56H8.69594C8.39708 56 8.09821 55.9725 7.82891 55.945C4.69031 55.5908 2.2392 53.2442 2 50.352L16.1992 37.3913C18.2322 35.5362 21.5799 35.5362 23.6128 37.3913L44 56Z' fill='#bda57f'/>
            </Svg>
        );
    case 'plug-file-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 56 56' fill='none'>
                <Path fillRule='evenodd' clipRule='evenodd' d='M15.68 0H43.68H44.8L56 11.2V12.32V47.04C56 49.5141 53.9941 51.52 51.52 51.52H48.16H44.8H22.4H19.04H15.68C13.2048 51.52 11.2 49.5152 11.2 47.04V42.56V28V20.16H4.48C2.00592 20.16 0 18.1541 0 15.68V10.08C0 7.60592 2.00592 5.6 4.48 5.6H11.2V4.48C11.2 2.00592 13.2059 0 15.68 0ZM38.08 10.08C38.08 7.60592 36.0741 5.6 33.6 5.6C36.0752 5.6 38.08 7.6048 38.08 10.08ZM38.08 15.68C38.08 18.1552 36.0752 20.16 33.6 20.16C36.0741 20.16 38.08 18.1541 38.08 15.68Z' fill='#f6f0e6'/>
                <Path d='M38.08 10.08V15.68C38.08 18.1541 36.0741 20.16 33.6 20.16H4.48C2.00592 20.16 0 18.1541 0 15.68V10.08C0 7.6059 2.00592 5.59998 4.48 5.59998H33.6C36.0741 5.59998 38.08 7.6059 38.08 10.08Z' fill='#9e8a6a'/>
                <Path d='M44.8002 0H43.6802V7.84C43.6802 10.3141 45.6861 12.32 48.1602 12.32H56.0002V11.2L44.8002 0Z' fill='#e7e0d3'/>
            </Svg>
        );
    case 'plug-grid-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 48 48'>
                <rect width='48' height='48' rx='8' fill='#F0F1F5'/>
                <Path d='M33.0375 9.0375C29.7705 9.0375 27.1126 11.6955 27.1126 14.9625C27.1126 18.2295 29.7706 20.8876 33.0375 20.8876C36.3046 20.8876 38.9624 18.2296 38.9624 14.9626C38.9624 11.6956 36.3046 9.0375 33.0375 9.0375Z' fill='#D0D1D8'/>
                <Path fillRule='evenodd' clipRule='evenodd' d='M0.185905 41.7225C0.973758 45.3129 4.17308 48 8 48H40C43.8009 48 46.9827 45.3493 47.7977 41.7957L45.1875 38.1909L36.3772 27.6186C36.1359 27.329 35.7885 27.1484 35.4129 27.1173C35.0372 27.0861 34.6649 27.2072 34.3793 27.4531L27.4721 33.4002L15.2957 18.5883C15.0014 18.2303 14.5493 18.0404 14.0883 18.0802C13.6267 18.1202 13.2143 18.3848 12.9859 18.7881L2.8125 36.7411L0.185905 41.7225ZM33.0375 9.0375C29.7705 9.0375 27.1126 11.6955 27.1126 14.9625C27.1126 18.2295 29.7706 20.8876 33.0375 20.8876C36.3046 20.8876 38.9624 18.2296 38.9624 14.9626C38.9624 11.6956 36.3046 9.0375 33.0375 9.0375Z' fill='#D0D1D8'/>
                <Path fillRule='evenodd' clipRule='evenodd' d='M47.7977 41.7957L45.1875 38.1909L36.3772 27.6186C36.1359 27.329 35.7885 27.1484 35.4129 27.1173C35.0372 27.0861 34.6649 27.2072 34.3793 27.4531L27.4721 33.4002L15.2957 18.5883C15.0014 18.2303 14.5493 18.0404 14.0883 18.0802C13.6267 18.1202 13.2143 18.3848 12.9859 18.7881L2.8125 36.7411L0.185905 41.7225C0.973758 45.3129 4.17308 48 8 48H40C43.8009 48 46.9827 45.3493 47.7977 41.7957Z' fill='#D0D1D8'/>
            </Svg>
        );
    case 'plug-load-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 72 72'>
                <Path d='M18.7486 55.5935C13.9458 55.5935 9.67367 52.5183 8.12237 47.9402L8.01744 47.5952C7.65159 46.3829 7.49833 45.3633 7.49833 44.3432V23.8884L0.220255 48.183C-0.715803 51.7564 1.41724 55.4611 4.99613 56.4482L51.3865 68.8719C51.9655 69.0219 52.5445 69.0938 53.1147 69.0938C56.1025 69.0938 58.8327 67.1107 59.5979 64.1883L62.3006 55.5935H18.7486Z' fill='#f6f0e6'/>
                <Path d='M72.0002 9.84201V44.3432C72.0002 48.0633 68.9701 51.0934 65.25 51.0934H18.7487C18.4488 51.0934 18.1488 51.0632 17.8786 51.033C14.7287 50.6435 12.2688 48.0633 12.0287 44.8832C11.9985 44.703 11.9985 44.5228 11.9985 44.3432V9.84201C11.9985 6.12195 15.0286 3.09184 18.7487 3.09184H65.25C68.9701 3.09184 72.0002 6.12195 72.0002 9.84201Z' fill='#f6f0e6'/>
                <Path d='M32.9991 18.0924C32.9991 21.406 30.3129 24.0927 26.9994 24.0927C23.6852 24.0927 20.999 21.406 20.999 18.0924C20.999 14.7788 23.6852 12.0921 26.9994 12.0921C30.3129 12.0921 32.9991 14.7788 32.9991 18.0924Z' fill='#dcc6a3'/>
                <Path d='M72 35.9126V44.3432C72 48.0633 68.9699 51.0934 65.2499 51.0934H18.7486C18.4486 51.0934 18.1487 51.0632 17.8784 51.0329L48.7793 20.1326C50.8195 18.0924 54.1792 18.0924 56.2194 20.1326L72 35.9126Z' fill='#9e8a6a'/>
                <Path d='M54.1794 51.0934H18.7488C18.4488 51.0934 18.1489 51.0632 17.8786 51.033C14.7288 50.6435 12.2689 48.0633 12.0288 44.8832L26.279 30.6325C28.3192 28.5928 31.6789 28.5928 33.7191 30.6325L54.1794 51.0934Z' fill='#bda57f'/>
            </Svg>
        );

    case 'plug-load-file-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 72 72'>
                <Path d='M71.9997 8.25V51.75C71.9997 56.31 68.3097 60 63.7497 60H32.2498C29.1598 60 26.4598 58.29 25.0498 55.77C24.3898 54.6 23.9998 53.22 23.9998 51.75V8.25C23.9998 3.69 27.6898 0 32.2498 0H63.7497C68.3097 0 71.9997 3.69 71.9997 8.25Z' fill='#f6f0e6'/>
                <Path d='M58.9236 20.6864H37.4981C36.5112 20.6864 35.7126 19.8878 35.7126 18.9009C35.7126 17.9141 36.5112 17.1155 37.4981 17.1155H58.9236C59.9105 17.1155 60.709 17.9141 60.709 18.9009C60.709 19.8878 59.9105 20.6864 58.9236 20.6864Z' fill='#9e8a6a'/>
                <Path d='M58.9236 27.8282H37.4981C36.5112 27.8282 35.7126 27.0297 35.7126 26.0428C35.7126 25.0559 36.5112 24.2573 37.4981 24.2573H58.9236C59.9105 24.2573 60.709 25.0559 60.709 26.0428C60.709 27.0297 59.9105 27.8282 58.9236 27.8282Z' fill='#9e8a6a'/>
                <Path d='M58.9236 34.97H37.4981C36.5112 34.97 35.7126 34.1714 35.7126 33.1845C35.7126 32.1976 36.5112 31.399 37.4981 31.399H58.9236C59.9105 31.399 60.709 32.1976 60.709 33.1845C60.709 34.1714 59.9105 34.97 58.9236 34.97Z' fill='#9e8a6a'/>
                <Path d='M51.7818 42.1118H37.4981C36.5112 42.1118 35.7126 41.3132 35.7126 40.3264C35.7126 39.3395 36.5112 38.5409 37.4981 38.5409H51.7818C52.7686 38.5409 53.5672 39.3395 53.5672 40.3264C53.5672 41.3132 52.7686 42.1118 51.7818 42.1118Z' fill='#9e8a6a'/>
                <Path d='M48.2107 42.1118H51.7816C52.7685 42.1118 53.5671 41.3132 53.5671 40.3264C53.5671 39.3395 52.7685 38.5409 51.7816 38.5409H48.2107V42.1118Z' fill='#bda57f'/>
                <Path d='M48.2107 34.97H58.9234C59.9103 34.97 60.7089 34.1714 60.7089 33.1845C60.7089 32.1976 59.9103 31.399 58.9234 31.399H48.2107V34.97Z' fill='#bda57f'/>
                <Path d='M48.2107 27.8282H58.9234C59.9103 27.8282 60.7089 27.0297 60.7089 26.0428C60.7089 25.0559 59.9103 24.2573 58.9234 24.2573H48.2107V27.8282Z' fill='#bda57f'/>
                <Path d='M48.2107 20.6864H58.9234C59.9103 20.6864 60.7089 19.8878 60.7089 18.9009C60.7089 17.9141 59.9103 17.1155 58.9234 17.1155H48.2107V20.6864Z' fill='#bda57f'/>
                <Path d='M32.2498 64.5C25.2208 64.5 19.4998 58.779 19.4998 51.75V9.05701L5.00681 12.375C1.42481 13.317 -0.723193 17.01 0.224807 20.625L12.6508 67.02C13.4758 70.02 16.2328 72.006 19.1908 72.006C19.7458 72.006 20.3098 71.937 20.8678 71.79L51.1078 64.5H32.2498Z' fill='#f6f0e6'/>
            </Svg>
        );

    case 'coin-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 38 38'>
                <Path d='M38 19C38 29.4934 29.4934 38 19 38C13.4683 38 8.48873 35.6362 5.01678 31.8634C4.10478 30.8731 3.29747 29.785 2.61037 28.6179C0.951551 25.7966 0 22.5092 0 19C0 8.50657 8.50657 0 19 0C22.51 0 25.7973 0.951551 28.6179 2.61114C29.785 3.29747 30.8723 4.10477 31.8626 5.01755C35.6355 8.48796 38 13.4683 38 19Z' fill='#F0CD00'/>
                <Path d='M38 19C38 29.2569 29.8734 37.6153 19.7073 37.9868C9.54112 37.6153 1.41455 29.2569 1.41455 19C1.41455 8.7431 9.54112 0.384655 19.7073 0.0131855C29.8734 0.384655 38 8.7431 38 19Z' fill='#FFDE50'/>
                <Path d='M31.8626 5.01756L5.01676 31.8634C4.10476 30.8731 3.29745 29.785 2.61035 28.6179L28.6179 2.61115C29.785 3.29747 30.8723 4.10478 31.8626 5.01756Z' fill='#FFEA94'/>
                <Path d='M36.6896 12.0538L12.0532 36.6902C10.309 36.0046 8.69055 35.0686 7.24268 33.9263L33.9264 7.24249C35.0688 8.69037 36.0048 10.3089 36.6896 12.0538Z' fill='#FFEA94'/>
                <Path d='M33.7642 19C33.7642 27.1537 27.1537 33.7642 19 33.7642C16.1601 33.7642 13.5078 32.9623 11.2565 31.5726C10.2755 30.9677 9.37127 30.2503 8.56008 29.4391C7.96372 28.8436 7.41776 28.196 6.92996 27.505C6.25837 26.5535 5.69613 25.5189 5.26262 24.42C4.62049 22.7954 4.25911 21.0287 4.23739 19.1815C4.23584 19.121 4.23584 19.0605 4.23584 19C4.23584 10.8463 10.8455 4.23584 19 4.23584C19.0605 4.23584 19.1218 4.23584 19.1822 4.23739C21.0295 4.2591 22.7954 4.62127 24.42 5.26261C25.5189 5.6969 26.5535 6.25837 27.505 6.92996C28.196 7.41853 28.8428 7.96372 29.4391 8.56008C30.2503 9.37049 30.9677 10.2755 31.5726 11.2565C32.9623 13.5078 33.7642 16.1601 33.7642 19Z' fill='#FAA300'/>
                <Path d='M29.4391 8.56008L8.56008 29.4391C7.96372 28.8436 7.41776 28.196 6.92996 27.505C6.25837 26.5535 5.69613 25.5189 5.26262 24.42C4.62049 22.7954 4.25911 21.0287 4.23739 19.1815C4.23584 19.121 4.23584 19.0605 4.23584 19C4.23584 10.8463 10.8455 4.23584 19 4.23584C19.0605 4.23584 19.1218 4.23584 19.1822 4.23739C21.0295 4.2591 22.7954 4.62127 24.42 5.26261C25.5189 5.6969 26.5535 6.25837 27.505 6.92996C28.196 7.41853 28.8428 7.96372 29.4391 8.56008Z' fill='#FFBD00'/>
                <Path d='M33.7638 19C33.7638 27.1537 27.1534 33.7642 18.9997 33.7642C18.7197 33.7642 18.4421 33.7564 18.166 33.7409C25.932 33.3089 32.0957 26.8738 32.0957 19C32.0957 11.1262 25.932 4.69106 18.166 4.2591C18.4421 4.24359 18.7197 4.23584 18.9997 4.23584C27.1534 4.23584 33.7638 10.8463 33.7638 19Z' fill='#F68E00'/>
                <Path d='M18.5134 17.8655V11.8862C19.4453 12.4003 20.0781 13.3925 20.0781 14.53C20.0781 15.3323 20.7285 15.9827 21.5308 15.9827C22.3332 15.9827 22.9836 15.3323 22.9836 14.53C22.9836 11.7652 21.0792 9.43726 18.5134 8.78785V7.60535C18.5134 6.80301 17.863 6.15259 17.0607 6.15259C16.2583 6.15259 15.6079 6.80301 15.6079 7.60535V8.78777C13.0421 9.43726 11.1377 11.7651 11.1377 14.5299C11.1377 16.1807 11.6225 17.4681 12.6199 18.4654C13.3471 19.1926 14.3131 19.7308 15.6079 20.1253V26.1139C14.676 25.5998 14.0432 24.6077 14.0432 23.4703C14.0432 22.6679 13.3928 22.0175 12.5905 22.0175C11.7881 22.0175 11.1377 22.6679 11.1377 23.4703C11.1377 26.235 13.0421 28.5629 15.6079 29.2124V30.3948C15.6079 31.1971 16.2583 31.8476 17.0607 31.8476C17.863 31.8476 18.5134 31.1971 18.5134 30.3948V29.2124C21.0792 28.5629 22.9836 26.2351 22.9836 23.4703C22.9836 21.8423 22.478 20.5113 21.4808 19.5142C20.7561 18.7894 19.7781 18.246 18.5134 17.8655ZM14.6745 16.411C14.3806 16.1171 14.0433 15.6377 14.0433 14.5299C14.0433 13.3924 14.6761 12.4003 15.608 11.8862V17.029C15.2161 16.8476 14.9059 16.6424 14.6745 16.411ZM18.5134 26.1139V20.9551C19.776 21.5431 20.0781 22.3843 20.0781 23.4703C20.0781 24.6077 19.4453 25.5998 18.5134 26.1139Z' fill='#F68E00'/>
                <Path d='M20.4524 17.8655V11.8862C21.3842 12.4003 22.0171 13.3925 22.0171 14.53C22.0171 15.3323 22.6675 15.9827 23.4698 15.9827C24.2722 15.9827 24.9226 15.3323 24.9226 14.53C24.9226 11.7652 23.0182 9.43726 20.4524 8.78785V7.60535C20.4524 6.80301 19.802 6.15259 18.9996 6.15259C18.1973 6.15259 17.5469 6.80301 17.5469 7.60535V8.78777C14.9811 9.43726 13.0767 11.7651 13.0767 14.5299C13.0767 16.1807 13.5615 17.4681 14.5589 18.4654C15.2861 19.1926 16.2521 19.7308 17.5469 20.1253V26.1139C16.615 25.5998 15.9822 24.6077 15.9822 23.4703C15.9822 22.6679 15.3318 22.0175 14.5294 22.0175C13.7271 22.0175 13.0767 22.6679 13.0767 23.4703C13.0767 26.235 14.9811 28.5629 17.5469 29.2124V30.3948C17.5469 31.1971 18.1973 31.8476 18.9996 31.8476C19.802 31.8476 20.4524 31.1971 20.4524 30.3948V29.2124C23.0182 28.5629 24.9226 26.2351 24.9226 23.4703C24.9226 21.8423 24.417 20.5113 23.4198 19.5142C22.6951 18.7894 21.717 18.246 20.4524 17.8655ZM16.6134 16.411C16.3195 16.1171 15.9822 15.6377 15.9822 14.5299C15.9822 13.3924 16.615 12.4003 17.5469 11.8862V17.029C17.1551 16.8476 16.8449 16.6424 16.6134 16.411ZM20.4524 26.1139V20.9551C21.715 21.5431 22.0171 22.3843 22.0171 23.4703C22.017 24.6077 21.3842 25.5998 20.4524 26.1139Z' fill='#FFEA94'/>
            </Svg>
        );

    case 'money-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 56 56' fill='none'>
                <Path d='M17.498 50.016L41.377 19.8149L23.8823 5.98244L0.00326433 36.1835L17.498 50.016Z' fill='#66BB6A'/>
                <Path d='M35.5774 18.8595L26.1445 11.4017C25.1293 11.8247 24.0119 11.9554 22.9265 11.7781L5.42188 33.9184C5.84483 34.9336 5.97553 36.051 5.79823 37.1364L15.2312 44.5943C16.2463 44.1714 17.3638 44.0407 18.4491 44.218L35.9538 22.0777C35.5307 21.0623 35.4 19.9449 35.5774 18.8595Z' fill='#AED581'/>
                <Path d='M3.43004 36.7165C3.94585 36.7165 4.36399 36.2984 4.36399 35.7826C4.36399 35.2668 3.94585 34.8486 3.43004 34.8486C2.91424 34.8486 2.49609 35.2668 2.49609 35.7826C2.49609 36.2984 2.91424 36.7165 3.43004 36.7165Z' fill='#AED581'/>
                <Path d='M24.2816 10.3427C24.7974 10.3427 25.2156 9.92461 25.2156 9.4088C25.2156 8.893 24.7974 8.47485 24.2816 8.47485C23.7658 8.47485 23.3477 8.893 23.3477 9.4088C23.3477 9.92461 23.7658 10.3427 24.2816 10.3427Z' fill='#AED581'/>
                <Path d='M37.9476 21.1472C38.4634 21.1472 38.8816 20.729 38.8816 20.2132C38.8816 19.6974 38.4634 19.2793 37.9476 19.2793C37.4318 19.2793 37.0137 19.6974 37.0137 20.2132C37.0137 20.729 37.4318 21.1472 37.9476 21.1472Z' fill='black'/>
                <Path d='M17.4956 50.0184L52.1543 33.2378L42.4337 13.1608L7.775 29.9415L17.4956 50.0184Z' fill='#58A15B'/>
                <Path d='M47.3496 29.8529L42.1094 19.0299C41.0114 18.9682 39.9486 18.5988 39.049 17.9662L13.6457 30.2656C13.584 31.3636 13.2147 32.4264 12.582 33.326L17.8223 44.1491C18.9203 44.2108 19.9831 44.5801 20.8826 45.2128L46.2858 32.9133C46.3475 31.8151 46.7169 30.7525 47.3496 29.8529Z' fill='#98BA71'/>
                <Path d='M11.0394 32.009C11.5552 32.009 11.9734 31.5909 11.9734 31.0751C11.9734 30.5593 11.5552 30.1411 11.0394 30.1411C10.5236 30.1411 10.1055 30.5593 10.1055 31.0751C10.1055 31.5909 10.5236 32.009 11.0394 32.009Z' fill='#98BA71'/>
                <Path d='M41.2992 17.3579C41.815 17.3579 42.2331 16.9397 42.2331 16.4239C42.2331 15.9081 41.815 15.49 41.2992 15.49C40.7834 15.49 40.3652 15.9081 40.3652 16.4239C40.3652 16.9397 40.7834 17.3579 41.2992 17.3579Z' fill='#98BA71'/>
                <Path d='M55.9995 27.7118H17.4961V50.0158H55.9995V27.7118Z' fill='#66BB6A'/>
                <Path d='M53.1519 44.8767V32.8517C52.1904 32.3178 51.3949 31.5222 50.8608 30.5607H22.6367C22.1027 31.5221 21.3071 32.3177 20.3457 32.8516V44.8767C21.3071 45.4106 22.1027 46.2062 22.6367 47.1676H50.8609C51.3949 46.2062 52.1905 45.4106 53.1519 44.8767Z' fill='#AED581'/>
                <Path d='M19.9379 31.0874C20.4537 31.0874 20.8718 30.6692 20.8718 30.1534C20.8718 29.6376 20.4537 29.2195 19.9379 29.2195C19.422 29.2195 19.0039 29.6376 19.0039 30.1534C19.0039 30.6692 19.422 31.0874 19.9379 31.0874Z' fill='#AED581'/>
                <Path d='M53.5589 31.0874C54.0747 31.0874 54.4929 30.6692 54.4929 30.1534C54.4929 29.6376 54.0747 29.2195 53.5589 29.2195C53.0431 29.2195 52.625 29.6376 52.625 30.1534C52.625 30.6692 53.0431 31.0874 53.5589 31.0874Z' fill='#AED581'/>
                <Path d='M19.9379 48.5083C20.4537 48.5083 20.8718 48.0901 20.8718 47.5743C20.8718 47.0585 20.4537 46.6404 19.9379 46.6404C19.422 46.6404 19.0039 47.0585 19.0039 47.5743C19.0039 48.0901 19.422 48.5083 19.9379 48.5083Z' fill='#AED581'/>
                <Path d='M53.5589 48.5083C54.0747 48.5083 54.4929 48.0901 54.4929 47.5743C54.4929 47.0585 54.0747 46.6404 53.5589 46.6404C53.0431 46.6404 52.625 47.0585 52.625 47.5743C52.625 48.0901 53.0431 48.5083 53.5589 48.5083Z' fill='#AED581'/>
                <Path d='M36.7497 44.4715C39.8464 44.4715 42.3567 41.9611 42.3567 38.8644C42.3567 35.7677 39.8464 33.2573 36.7497 33.2573C33.6529 33.2573 31.1426 35.7677 31.1426 38.8644C31.1426 41.9611 33.6529 44.4715 36.7497 44.4715Z' fill='#66BB6A'/>
                <Path d='M26.9909 40.7577C28.0365 40.7577 28.8842 39.9101 28.8842 38.8645C28.8842 37.8188 28.0365 36.9712 26.9909 36.9712C25.9453 36.9712 25.0977 37.8188 25.0977 38.8645C25.0977 39.9101 25.9453 40.7577 26.9909 40.7577Z' fill='#66BB6A'/>
                <Path d='M46.5065 40.7577C47.5522 40.7577 48.3998 39.9101 48.3998 38.8645C48.3998 37.8188 47.5522 36.9712 46.5065 36.9712C45.4609 36.9712 44.6133 37.8188 44.6133 38.8645C44.6133 39.9101 45.4609 40.7577 46.5065 40.7577Z' fill='#66BB6A'/>
                <Path d='M36.8387 34.7783C37.1121 34.7783 37.329 34.9952 37.329 35.2686V35.4007C37.8381 35.476 38.2909 35.627 38.6963 35.8532C38.8755 35.957 39.0358 36.1362 39.0358 36.419C39.0358 36.7774 38.7528 37.0508 38.3945 37.0508C38.2814 37.0508 38.1682 37.0225 38.0645 36.9659C37.8004 36.8245 37.5365 36.7113 37.2817 36.6452V38.0786C38.7151 38.4652 39.328 39.0498 39.328 40.1059C39.328 41.1622 38.5171 41.8411 37.329 41.9731V42.4728C37.329 42.7462 37.1121 42.9631 36.8387 42.9631C36.5652 42.9631 36.3483 42.7462 36.3483 42.4728V41.9637C35.6788 41.8788 35.047 41.643 34.4906 41.3036C34.2925 41.181 34.1699 40.9924 34.1699 40.7378C34.1699 40.37 34.4529 40.0965 34.8206 40.0965C34.9432 40.0965 35.0752 40.1438 35.1884 40.2191C35.5845 40.4738 35.9617 40.6528 36.3954 40.7472V39.2478C35.0375 38.88 34.368 38.3614 34.368 37.2392C34.368 36.2019 35.1601 35.5041 36.3482 35.3815V35.2683C36.3482 34.9952 36.5651 34.7783 36.8387 34.7783ZM36.3955 37.8337V36.5607C35.9429 36.6267 35.7448 36.8529 35.7448 37.1547C35.7447 37.4471 35.8768 37.645 36.3955 37.8337ZM37.2818 39.4839V40.7947C37.7251 40.7286 37.9513 40.5212 37.9513 40.1817C37.9513 39.8705 37.791 39.6631 37.2818 39.4839Z' fill='#AED581'/>
            </Svg>
        );
    case 'money-withdraw-id-v2':
        return (
            <Svg {...dataStyles} viewBox='0 0 56 56' fill='none'>
                <path d='M28 2.40625C13.2005 2.40625 1.20312 14.4036 1.20312 29.2031C1.20312 31.3806 1.46344 33.497 1.95377 35.5237C2.09661 36.1142 8.71938 35.4605 12.1406 35.4605C24.1283 35.4605 34.2766 43.3325 37.7004 54.189C47.7047 50.3021 54.7969 40.5814 54.7969 29.2031C54.7969 14.4036 42.7995 2.40625 28 2.40625Z' fill='#EFEEEC'/>
                <path d='M9.05931 32.3599H8.84056C7.52533 32.3599 6.36541 33.0233 5.67656 34.0337C5.0505 34.9521 3.99755 35.4673 2.88608 35.4605C2.87809 35.4605 2.87011 35.4603 2.86213 35.4603H2.64338C2.40811 35.4603 2.17831 35.4827 1.95475 35.5233C3.21923 40.7521 6.01562 45.3826 9.84408 48.9114C9.91539 48.9771 9.98692 48.4873 10.0589 48.5522C10.2028 48.682 10.3483 48.8104 10.4951 48.9371C11.8977 48.369 12.8874 47.5498 12.8874 45.9435V36.1879C12.8874 34.0738 11.1735 32.3599 9.05931 32.3599Z' fill='#306949'/>
                <path d='M28 10.9375C27.3959 10.9375 26.9062 10.4478 26.9062 9.84375V1.09375C26.9062 0.489672 27.3959 0 28 0C28.6041 0 29.0938 0.489672 29.0938 1.09375V9.84375C29.0938 10.4478 28.6041 10.9375 28 10.9375Z' fill='#9FACBA'/>
                <path d='M34.125 15.3125H21.875C21.2709 15.3125 20.7812 14.8228 20.7812 14.2188V5.46875C20.7812 4.86467 21.2709 4.375 21.875 4.375H34.125C34.7291 4.375 35.2188 4.86467 35.2188 5.46875V14.2188C35.2188 14.8228 34.7291 15.3125 34.125 15.3125Z' fill='#C5D3DD'/>
                <path d='M14.4922 12.0312V51.6366C15.9879 52.5114 17.5759 53.2455 19.2381 53.8207C19.4356 53.8891 19.6342 54.6679 19.834 54.7318C20.5266 54.9533 21.2314 55.1474 21.9473 55.3128C22.1371 55.3567 22.3277 54.214 22.519 54.2538C24.2882 54.6219 26.1215 54.8154 28 54.8154C28.4316 54.8154 28.8608 55.9896 29.2873 55.9695C35.7845 55.6621 41.6761 53.041 46.1562 48.9116V12.0312C46.1562 11.4272 45.6666 10.9375 45.0625 10.9375H15.5859C14.9819 10.9375 14.4922 11.4272 14.4922 12.0312Z' fill='#C5D3DD'/>
                <path d='M15.3355 12.5463C15.3355 11.6577 16.0557 10.9375 16.9443 10.9375H10.9375C10.3334 10.9375 9.84375 11.4272 9.84375 12.0312V48.9117C11.0577 50.0306 12.3753 51.0385 13.7805 51.9198C13.8356 51.9543 13.8908 51.3484 13.9462 51.3826C14.4004 51.663 14.8637 51.93 15.3355 52.1836V12.5463Z' fill='#B6C4CF'/>
                <path d='M15.3968 13.125H8.75C8.14592 13.125 7.65625 12.6353 7.65625 12.0312V9.625C7.65625 9.02092 8.14592 8.53125 8.75 8.53125H15.3968C16.0009 8.53125 16.4906 9.02092 16.4906 9.625V12.0312C16.4906 12.6353 16.0008 13.125 15.3968 13.125Z' fill='#C5D3DD'/>
                <path d='M47.25 13.125H14.5622C13.9581 13.125 13.4684 12.6353 13.4684 12.0312V9.625C13.4684 9.02092 13.9581 8.53125 14.5622 8.53125H47.25C47.8541 8.53125 48.3438 9.02092 48.3438 9.625V12.0312C48.3438 12.6353 47.8541 13.125 47.25 13.125Z' fill='#D9E7EC'/>
                <path d='M15.4219 23.566C14.5158 23.566 13.7812 22.8315 13.7812 21.9254V16.9531C13.7812 16.0471 14.5158 15.3125 15.4219 15.3125C16.3279 15.3125 17.0625 16.0471 17.0625 16.9531V21.9254C17.0625 22.8315 16.3279 23.566 15.4219 23.566Z' fill='#D9E7EC'/>
                <path d='M21.7109 23.566C20.8049 23.566 20.0703 22.8315 20.0703 21.9254V16.9531C20.0703 16.0471 20.8049 15.3125 21.7109 15.3125C22.617 15.3125 23.3516 16.0471 23.3516 16.9531V21.9254C23.3516 22.8315 22.617 23.566 21.7109 23.566Z' fill='white'/>
                <path d='M28 23.566C27.0939 23.566 26.3594 22.8315 26.3594 21.9254V16.9531C26.3594 16.0471 27.0939 15.3125 28 15.3125C28.9061 15.3125 29.6406 16.0471 29.6406 16.9531V21.9254C29.6406 22.8315 28.9061 23.566 28 23.566Z' fill='white'/>
                <path d='M34.2891 23.566C33.383 23.566 32.6484 22.8315 32.6484 21.9254V16.9531C32.6484 16.0471 33.383 15.3125 34.2891 15.3125C35.1951 15.3125 35.9297 16.0471 35.9297 16.9531V21.9254C35.9297 22.8315 35.1951 23.566 34.2891 23.566Z' fill='white'/>
                <path d='M40.5781 23.566C39.6721 23.566 38.9375 22.8315 38.9375 21.9254V16.9531C38.9375 16.0471 39.6721 15.3125 40.5781 15.3125C41.4842 15.3125 42.2188 16.0471 42.2188 16.9531V21.9254C42.2188 22.8315 41.4842 23.566 40.5781 23.566Z' fill='white'/>
                <path d='M15.4219 34.3988C14.5158 34.3988 13.7812 33.6642 13.7812 32.7582V27.7859C13.7812 26.8798 14.5158 26.1452 15.4219 26.1452C16.3279 26.1452 17.0625 26.8798 17.0625 27.7859V32.7582C17.0625 33.6642 16.3279 34.3988 15.4219 34.3988Z' fill='#D9E7EC'/>
                <path d='M21.7109 34.3988C20.8049 34.3988 20.0703 33.6642 20.0703 32.7582V27.7859C20.0703 26.8798 20.8049 26.1452 21.7109 26.1452C22.617 26.1452 23.3516 26.8798 23.3516 27.7859V32.7582C23.3516 33.6642 22.617 34.3988 21.7109 34.3988Z' fill='white'/>
                <path d='M28 34.3988C27.0939 34.3988 26.3594 33.6642 26.3594 32.7582V27.7859C26.3594 26.8798 27.0939 26.1452 28 26.1452C28.9061 26.1452 29.6406 26.8798 29.6406 27.7859V32.7582C29.6406 33.6642 28.9061 34.3988 28 34.3988Z' fill='white'/>
                <path d='M34.2891 34.3988C33.383 34.3988 32.6484 33.6642 32.6484 32.7582V27.7859C32.6484 26.8798 33.383 26.1452 34.2891 26.1452C35.1951 26.1452 35.9297 26.8798 35.9297 27.7859V32.7582C35.9297 33.6642 35.1951 34.3988 34.2891 34.3988Z' fill='white'/>
                <path d='M40.5781 34.3988C39.6721 34.3988 38.9375 33.6642 38.9375 32.7582V27.7859C38.9375 26.8798 39.6721 26.1452 40.5781 26.1452C41.4842 26.1452 42.2188 26.8798 42.2188 27.7859V32.7582C42.2188 33.6642 41.4842 34.3988 40.5781 34.3988Z' fill='white'/>
                <path d='M15.4219 45.2315C14.5158 45.2315 13.7812 44.4969 13.7812 43.5909V38.6186C13.7812 37.7125 14.5158 36.9779 15.4219 36.9779C16.3279 36.9779 17.0625 37.7125 17.0625 38.6186V43.5909C17.0625 44.4969 16.3279 45.2315 15.4219 45.2315Z' fill='#D9E7EC'/>
                <path d='M21.7109 45.2315C20.8049 45.2315 20.0703 44.4969 20.0703 43.5909V38.6186C20.0703 37.7125 20.8049 36.9779 21.7109 36.9779C22.617 36.9779 23.3516 37.7125 23.3516 38.6186V43.5909C23.3516 44.4969 22.617 45.2315 21.7109 45.2315Z' fill='white'/>
                <path d='M16.8077 48.3106C15.1362 48.3106 13.7812 49.6655 13.7812 51.337V51.9185C15.6558 53.0943 17.6858 54.0445 19.8341 54.7311V51.337C19.8341 49.6655 18.4791 48.3106 16.8077 48.3106Z' fill='#9FACBA'/>
                <path d='M24.9736 48.3106C23.3021 48.3106 21.9472 49.6655 21.9472 51.337V55.3136C23.8923 55.7627 25.9184 56 28 56V51.337C28 49.6655 26.6451 48.3106 24.9736 48.3106Z' fill='#9FACBA'/>
                <path d='M42.6327 40.7301C42.1796 40.7301 41.8124 40.3629 41.8124 39.9098V33.5287C41.8124 33.4532 41.751 33.3918 41.6756 33.3918H35.1667C35.0913 33.3918 35.0299 33.4533 35.0299 33.5287V39.9098C35.0299 40.3629 34.6627 40.7301 34.2095 40.7301C33.7564 40.7301 33.3892 40.3629 33.3892 39.9098V33.5287C33.3892 32.5486 34.1866 31.7512 35.1667 31.7512H41.6756C42.6557 31.7512 43.453 32.5487 43.453 33.5287V39.9098C43.453 40.3629 43.0859 40.7301 42.6327 40.7301Z' fill='#41135A'/>
                <path d='M32.398 56H27.0716C26.4675 56 25.9779 55.5103 25.9779 54.9062V39.2263C25.9779 38.6223 26.4675 38.1326 27.0716 38.1326H32.398C33.002 38.1326 33.4917 38.6223 33.4917 39.2263V54.9062C33.4917 55.5103 33.002 56 32.398 56Z' fill='#481664'/>
                <path d='M49.7708 56H30.4776C29.8735 56 29.3838 55.5103 29.3838 54.9062V39.2263C29.3838 38.6223 29.8735 38.1326 30.4776 38.1326H49.7709C50.375 38.1326 50.8646 38.6223 50.8646 39.2263V54.9062C50.8645 55.5103 50.3748 56 49.7708 56Z' fill='#5C1F7D'/>
                <path d='M29.7337 43.756H26.3697C25.7656 43.756 25.2759 43.2664 25.2759 42.6623V36.5542C25.2759 35.9502 25.7656 35.4605 26.3697 35.4605H29.7337C30.3378 35.4605 30.8275 35.9502 30.8275 36.5542V42.6623C30.8275 43.2664 30.3378 43.756 29.7337 43.756Z' fill='#6F2E93'/>
                <path d='M50.4727 43.756H29.1082C28.5041 43.756 28.0144 43.2664 28.0144 42.6623V36.5542C28.0144 35.9502 28.5041 35.4605 29.1082 35.4605H50.4727C51.0768 35.4605 51.5665 35.9502 51.5665 36.5542V42.6623C51.5665 43.2664 51.0768 43.756 50.4727 43.756Z' fill='#7D37A4'/>
                <path d='M40.843 45.2315H35.9994C35.468 45.2315 35.0373 44.8008 35.0373 44.2694V43.4548C35.0373 42.9235 35.468 42.4927 35.9994 42.4927H40.843C41.3744 42.4927 41.8051 42.9235 41.8051 43.4548V44.2694C41.8051 44.8008 41.3744 45.2315 40.843 45.2315Z' fill='#FFCD6B'/>
            </Svg>

        )

    case 'money-withdraw-person':
        return (
            <svg {...dataStyles} viewBox='0 0 56 56' fill='none' >
                <path d='M4.64319 42.3444H51.3569C53.5464 38.4614 54.7972 33.9784 54.7972 29.2029C54.7972 14.4032 42.7998 2.40579 28.0001 2.40579C13.2005 2.40579 1.20302 14.4032 1.20302 29.2029C1.20291 33.9785 2.45361 38.4614 4.64319 42.3444Z' fill='#E8E6E4'/>
                <path d='M25.6802 37.7144C25.6802 37.7144 27.3043 38.4269 28 39.4489C28.6957 38.4269 30.3198 37.7144 30.3198 37.7144L36.8594 34.3884C36.3109 34.219 35.7528 34.0677 35.1824 33.936C34.0721 32.8256 33.7905 30.7205 33.7399 29.2476C33.1604 29.9226 32.5523 30.4872 31.9712 30.9383C30.8389 31.8176 29.4418 32.2951 28.0028 32.2951C26.5639 32.2951 25.1667 31.8176 24.0346 30.9383C23.4535 30.4871 22.8454 29.9225 22.2658 29.2476C22.2152 30.7205 21.9336 32.8256 20.8232 33.936C20.2515 34.0679 19.6924 34.2197 19.1427 34.3894L25.6802 37.7144Z' fill='#FFAA7B'/>
                <path d='M27.7312 32.2894C26.3888 32.2332 25.0953 31.7621 24.0346 30.9384C23.4535 30.4871 22.8453 29.9225 22.2658 29.2475C22.2217 30.5286 22.0023 32.2875 21.2154 33.4546C22.3346 35.7137 23.7848 37.6038 25.2515 36.6151C27.0603 35.3959 27.5967 33.5838 27.7312 32.2894Z' fill='#FC9460'/>
                <path d='M25.9102 37.3677L23.0087 35.8919C21.774 36.2674 20.0881 37.2477 20.0286 39.8067C19.1949 39.9511 14.9967 40.8745 14.9967 44.7968V52.637C18.8475 54.7789 23.2808 56 28 56C28.0936 56 28.1866 55.9974 28.28 55.9965C28.28 50.0249 28.28 40.6384 28.28 39.7376C28.28 38.4068 25.9102 37.3677 25.9102 37.3677Z' fill='#40584A'/>
                <path d='M15.1607 52.7271C15.1607 50.6719 15.1607 47.4414 15.1607 44.7968C15.1607 40.5593 20.191 39.9474 20.191 39.9474C20.191 37.2571 21.9975 36.3219 23.2145 35.9967L19.7176 34.2181C12.2301 36.6597 8.65397 37.636 6.78934 38.967C8.73053 40.2919 11.7834 43.044 11.7834 47.2546V50.5364C12.8492 51.3478 13.9782 52.0802 15.1607 52.7271Z' fill='#2C4135'/>
                <path d='M36.2824 34.2181L30.0898 37.3678C30.0898 37.3678 27.72 38.4068 27.72 39.7376V55.9965C27.8134 55.9975 27.9064 56 28 56C29.2569 56 30.4933 55.9121 31.7042 55.7446L31.7034 55.7441L39.3358 53.4891V53.4893C41.0721 52.6773 42.7073 51.6855 44.2165 50.5363C44.2165 49.395 44.2165 48.2817 44.2165 47.2546C44.2165 43.044 47.2695 40.292 49.2105 38.967C47.346 37.636 43.77 36.6597 36.2824 34.2181Z' fill='#557261'/>
                <path d='M34.3676 31.4043C34.2417 31.3348 34.0852 31.4075 34.0565 31.5483C33.8694 32.4649 33.0574 35.3523 30.042 37.066C29.7451 37.2347 29.674 37.6309 29.8985 37.8883L32.9455 41.3781C33.5781 42.1067 34.7429 41.9756 35.1995 41.1255C35.9715 39.6883 37.047 37.5008 37.843 35.1491C37.9855 34.728 37.8842 34.2624 37.5838 33.9347C36.657 32.9237 35.5712 32.0686 34.3676 31.4043Z' fill='#698976'/>
                <path d='M25.9597 37.0661C25.2342 36.6537 24.6374 36.1732 24.145 35.6688C23.4113 35.7311 20.2202 36.2018 20.0375 39.6374C20.3093 40.1906 20.5696 40.6927 20.8022 41.1258C21.2589 41.9758 22.4236 42.107 23.0562 41.3783L26.1031 37.8885C26.3278 37.631 26.2566 37.2347 25.9597 37.0661Z' fill='#557261'/>
                <path d='M21.9452 31.5484C21.9164 31.4076 21.7599 31.3348 21.6342 31.4044C20.4306 32.0687 19.3447 32.9238 18.418 33.9348C18.1176 34.2625 18.0162 34.7281 18.1588 35.1492C18.7605 36.9271 19.5218 38.6103 20.1911 39.9472C20.1912 35.8916 24.2985 35.8231 24.2985 35.8231L24.2986 35.8228C22.6231 34.1967 22.0919 32.267 21.9452 31.5484Z' fill='#40584A'/>
                <path d='M43.9977 50.7021C46.9964 48.4671 49.5103 45.6191 51.3568 42.3444C51.1505 41.7308 50.9272 41.1677 50.6847 40.6733C50.3354 39.9608 49.8505 39.4021 49.1027 38.8915C47.1693 40.1861 43.9977 42.9635 43.9977 47.2545V50.7021Z' fill='#698976'/>
                <path d='M6.89719 38.8915C6.14939 39.4022 5.66443 39.9608 5.31519 40.6733C5.07282 41.1677 4.84947 41.7308 4.64308 42.3444C6.48955 45.6191 9.00354 48.4672 12.0022 50.7021C12.0022 49.5027 12.0022 48.3315 12.0022 47.2545C12.0023 42.9635 8.83061 40.1861 6.89719 38.8915Z' fill='#40584A'/>
                <path d='M38.0233 46.0059H32.9374C32.2155 46.0059 31.6249 46.5965 31.6249 47.3184V55.3027C31.6249 55.4576 31.6535 55.6058 31.7034 55.7441C34.3961 55.3717 36.9608 54.5997 39.3358 53.4891V47.3184C39.3358 46.5965 38.7452 46.0059 38.0233 46.0059Z' fill='#698976'/>
                <path d='M46.3189 9.64752C43.7748 11.5281 40.6094 14.6985 41.9513 18.6706C44.0294 24.8223 34.6232 28.7017 45.1232 33.1604C49.3214 34.9432 51.7608 36.8033 53.1781 38.3941C54.2251 35.5273 54.7972 32.4321 54.7972 29.2029C54.7971 21.4886 51.5361 14.5368 46.3189 9.64752Z' fill='#EFEEEC'/>
                <path d='M9.68111 9.64752C12.2252 11.5281 15.3906 14.6985 14.0487 18.6706C11.9705 24.8223 21.3768 28.7017 10.8768 33.1604C6.67855 34.9432 4.23905 36.8033 2.82187 38.3941C1.77494 35.5273 1.2028 32.4321 1.2028 29.2029C1.20291 21.4886 4.46392 14.5368 9.68111 9.64752Z' fill='#EFEEEC'/>
                <path d='M18.0329 17.2239H38.0781C38.1137 15.7796 38.3545 11.9568 39.864 9.23353C40.0739 8.85477 40.1114 8.4048 39.9653 7.99716C39.4176 6.47006 37.7815 2.90006 34.2135 1.94785C34.0234 1.8971 33.9732 1.65122 34.1278 1.52971L34.6626 1.1096C34.905 0.919065 34.8119 0.532096 34.5091 0.473799C31.8766 -0.0339195 23.1337 -1.24886 20.4179 4.3355C20.2558 4.66877 19.9503 4.90874 19.5916 5.0016C18.8013 5.20613 17.38 5.82257 16.4536 7.6698C16.2724 8.03106 16.2415 8.45008 16.3714 8.83278C16.8216 10.1596 17.9185 13.7224 18.0329 17.2239Z' fill='#56415E'/>
                <path d='M27.0022 8.39692C23.8006 3.51781 29.4008 0.00195312 29.4008 0.00195312C26.0045 0.0459219 22.0811 0.915672 20.4179 4.3355C20.2558 4.66877 19.9503 4.90873 19.5916 5.00159C18.8013 5.20612 17.38 5.82256 16.4536 7.6698C16.2724 8.03106 16.2415 8.45008 16.3714 8.83278C16.8216 10.1596 17.9185 13.7224 18.0329 17.2239H22.6988C25.6519 14.8318 28.9046 11.2962 27.0022 8.39692Z' fill='#45304C'/>
                <path d='M38.8562 16.6397C38.4075 16.2285 37.4042 16.437 37.0176 16.6887C37.1023 17.216 37.1071 17.7555 37.0339 18.2881L36.5778 21.6101C36.5778 21.8778 36.5694 22.1405 36.5539 22.3987C37.6661 22.7341 38.328 21.7356 38.421 21.0504C38.4611 20.7554 38.5266 20.4647 38.6175 20.181C38.9133 19.2572 39.9806 17.6702 38.8562 16.6397Z' fill='#FFAA7B'/>
                <path d='M17.1439 16.6397C17.5926 16.2285 18.5959 16.437 18.9825 16.6887C18.8978 17.216 18.893 17.7555 18.9662 18.2881L19.4223 21.6101C19.4223 21.8778 19.4307 22.1405 19.4462 22.3987C18.334 22.7341 17.6722 21.7356 17.5791 21.0504C17.539 20.7554 17.4735 20.4647 17.3826 20.181C17.0868 19.2572 16.0195 17.6702 17.1439 16.6397Z' fill='#FC9460'/>
                <path d='M36.8525 15.6625C36.7498 15.3946 36.6204 15.1234 36.4573 14.8676C36.1811 14.4342 36.0596 13.9217 36.0967 13.4107C36.2031 11.9409 36.146 9.06619 34.2166 8.19797C32.5773 7.46035 30.5665 7.88746 29.2842 8.3138C28.4821 8.58057 27.6157 8.58965 26.8088 8.34257C25.3478 9.46388 25.3134 11.9886 25.4122 13.3329C25.4499 13.8468 25.3235 14.3607 25.0458 14.7965C24.8858 15.0476 24.7577 15.3134 24.6553 15.5764C24.3231 16.4298 24.2262 17.3553 24.3507 18.2616L24.8129 21.6282C24.8129 26.4683 27.3966 29.7581 29.5943 31.4648C29.9187 31.7167 30.2657 31.9333 30.6281 32.1183C31.103 31.9077 31.5535 31.6402 31.9682 31.3181C34.1373 29.6338 36.6871 26.3869 36.6871 21.6101L37.1432 18.2881C37.2649 17.4025 37.1727 16.4981 36.8525 15.6625Z' fill='#FFC7AB'/>
                <path d='M29.6099 31.3181C27.4409 29.6338 24.891 26.3869 24.891 21.6101L24.4348 18.2874C24.312 17.393 24.4076 16.4795 24.7354 15.6373C24.8364 15.3777 24.9628 15.1154 25.1208 14.8675C25.3949 14.4375 25.5197 13.9303 25.4825 13.4231C25.3819 12.0542 25.4211 9.44527 27.0022 8.39681C26.906 8.37252 26.8105 8.34518 26.7159 8.31368C25.4335 7.88734 23.4228 7.46023 21.7836 8.19785C19.8485 9.06848 19.7967 11.9572 19.9044 13.4231C19.9417 13.9303 19.8168 14.4375 19.5427 14.8675C19.3848 15.1154 19.2583 15.3777 19.1573 15.6373C18.8295 16.4795 18.7339 17.393 18.8567 18.2874L19.3129 21.6101C19.3129 26.3869 21.8629 29.6337 24.0318 31.3181C25.1639 32.1972 26.5611 32.6748 28 32.6748C28.9718 32.6748 29.9241 32.4561 30.7891 32.044C30.3733 31.8458 29.9775 31.6036 29.6099 31.3181Z' fill='#FFAA7B'/>
            </svg>


        )
    case 'money-withdraw-id':
        return (
            <Svg {...dataStyles} viewBox='0 0 56 56' fill='none' >
                <path d='M38.4086 52.2519C47.3934 47.9254 53.5942 38.7341 53.5942 28.0944C53.5942 13.2948 41.5967 1.2973 26.7971 1.2973C19.6961 1.2973 13.2414 4.06044 8.44627 8.56877C8.44627 8.56965 8.20083 42.993 38.4086 52.2519Z' fill='#EFEEEC'/>
                <path d='M33.3382 8.31854C34.054 9.85001 35.317 11.028 36.8277 11.6632H40.5895C40.8401 11.3842 40.9937 11.0165 40.9937 10.6119C40.9937 9.74162 40.2883 9.03615 39.418 9.03615C38.0417 9.03615 36.7759 8.2306 36.1931 6.98384L36.0986 6.7816C35.73 5.99323 34.7917 5.65296 34.0036 6.02156C33.2152 6.39015 32.8748 7.32804 33.2435 8.11653L33.3382 8.31854Z' fill='#EDC85F'/>
                <path d='M16.3408 11.6631C17.8516 11.0279 19.1145 9.85 19.8303 8.31842L19.9248 8.1164C20.2934 7.32792 19.9532 6.39003 19.1648 6.02143C18.3766 5.65284 17.4385 5.99311 17.0698 6.78148L16.9753 6.98372C16.3925 8.23048 15.1267 9.03603 13.7504 9.03603C12.8801 9.03603 12.1746 9.7415 12.1746 10.6118C12.1746 11.0164 12.3284 11.3841 12.5789 11.6631H16.3408Z' fill='#EDC85F'/>
                <path d='M48.9086 11.6631V9.7089C48.9086 8.91243 48.2569 8.26077 47.4605 8.26077H45.6102C44.8139 8.26077 44.1622 8.91243 44.1622 9.7089V11.6631H48.9086Z' fill='#EDC85F'/>
                <path d='M5.62888 11.6631H9.00638V9.70891C9.00638 9.24669 8.78609 8.83435 8.44627 8.56879C7.42919 9.52505 6.48681 10.5594 5.62888 11.6631Z' fill='#EDC85F'/>
                <path d='M52.7999 28.4241L32.2407 36.0573C31.9266 36.1739 31.6096 36.2798 31.2904 36.3764V37.2573C31.2904 38.3153 30.4328 39.1729 29.3748 39.1729H28.2751C28.4262 39.2649 28.5902 39.35 28.7684 39.4271C28.128 40.3383 25.31 44.6983 26.4399 49.3677C26.9398 51.4339 28.1295 53.2806 29.9907 54.8914H46.9357C51.8581 54.8914 55.8855 50.864 55.8855 45.9416V24.8732C55.5333 26.4813 54.3874 27.8346 52.7999 28.4241Z' fill='#557261'/>
                <path d='M29.024 39.354C28.8755 39.2975 28.7391 39.2368 28.6126 39.173H23.7935C22.7356 39.173 21.878 38.3154 21.878 37.2574V36.3764C21.5588 36.2798 21.2418 36.1739 20.9277 36.0573L0.368596 28.4241C0.243252 28.3776 0.121846 28.3246 0.00218964 28.2689C0.0962521 42.9879 12.0554 54.8913 26.7965 54.8915H30.2434C22.2324 48.135 29.024 39.354 29.024 39.354Z' fill='#2C4135'/>
                <path d='M0.607688 24.0637V22.4033C0.210875 24.2377 0 26.1413 0 28.0944C0 28.2345 0.00317188 28.3739 0.00525 28.5135C0.123594 28.5686 0.244672 28.6194 0.368594 28.6654L20.9278 36.2987C21.2419 36.4153 21.5589 36.5212 21.8781 36.6178V33.0036C21.8781 32.9967 21.879 32.9901 21.879 32.9834L1.5657 25.4413C0.992687 25.2286 0.607688 24.675 0.607688 24.0637Z' fill='#698976'/>
                <path d='M53.1414 11.4218H52.5608V24.0638C52.5608 24.675 52.1758 25.2287 51.6029 25.4414L31.2896 32.9835C31.2897 32.9902 31.2906 32.9968 31.2906 33.0037V36.6179C31.6096 36.5213 31.9266 36.4155 32.2407 36.2988L52.7999 28.6654C54.7236 27.9511 56 26.1157 56 24.0637V14.2804C56 12.7016 54.7202 11.4218 53.1414 11.4218Z' fill='#698976'/>
                <path d='M28.5123 11.4217C26.4769 12.5254 24.3132 14.2552 24.3132 16.6229L24.3133 26.0688C24.3097 26.1937 24.251 29.1016 26.4371 30.0963L26.6027 31.0879H29.3747C30.4311 31.0879 31.2904 31.9471 31.2904 33.0035V33.176L51.6658 25.6109C52.3094 25.372 52.7417 24.7502 52.7417 24.0637V11.4217H28.5123Z' fill='#557261'/>
                <path d='M24.4773 16.6229C24.4773 14.239 26.806 12.4994 28.8827 11.4217H5.81886C3.12102 14.8117 1.22697 18.8696 0.426674 23.3083V24.0637C0.426674 24.7502 0.859033 25.372 1.50271 25.6109V25.6111L21.8781 33.1761V33.0036C21.8781 31.9473 22.7373 31.088 23.7937 31.088H26.7691L26.5843 29.9818C24.3906 29.0566 24.4773 26.0737 24.4773 26.0737C24.4773 26.0737 24.4773 20.9251 24.4773 16.6229Z' fill='#40584A'/>
                <path d='M29.3748 30.907H26.5725L27.3721 35.693C27.2546 35.9315 26.8193 36.9249 27.1849 37.9174C27.4088 38.5253 27.8871 39.0074 28.6085 39.354H29.3748C30.528 39.354 31.4715 38.4105 31.4715 37.2574V33.0036C31.4715 31.8505 30.528 30.907 29.3748 30.907Z' fill='#EDC85F'/>
                <path d='M23.7937 39.354H29.024C26.1529 38.2595 27.5428 35.7196 27.5428 35.7196L26.7388 30.907H23.7937C22.6405 30.907 21.697 31.8505 21.697 33.0036V37.2574C21.697 38.4105 22.6405 39.354 23.7937 39.354Z' fill='#D1AB3E'/>
                <path d='M26.5843 29.9818C25.7114 29.9818 25.0038 30.6893 25.0038 31.5623V34.4633C25.0038 35.3361 25.7113 36.0438 26.5843 36.0438C27.4572 36.0438 28.1647 35.3362 28.1647 34.4633V31.5623C28.1647 30.6894 27.4572 29.9818 26.5843 29.9818Z' fill='#FFE088'/>
                <path d='M18.2896 7.85215C17.611 7.36883 17.4528 6.427 17.9362 5.74844C22.3385 -0.432127 30.8213 -0.444268 35.2323 5.74844C35.7155 6.427 35.5573 7.36883 34.8788 7.85215C34.2003 8.33548 33.2584 8.17722 32.7751 7.49865C29.5682 2.99623 23.5938 3.00553 20.3934 7.49876C19.9091 8.17831 18.9669 8.33472 18.2896 7.85215Z' fill='#2C4135'/>
            </Svg>


        )

    case 'money-coin-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 56 56' fill='none'>
                <Path d='M51.2716 6.5625H4.6104C2.0747 6.5625 0 8.6372 0 11.1733V37.8762C0 40.4119 2.0747 42.4866 4.6104 42.4866H32.2489V24.9067C32.2489 23.4019 37.5395 22.1817 44.0656 22.1817C50.5914 22.1817 55.882 23.4019 55.882 24.9067V11.1729C55.882 8.6372 53.8073 6.5625 51.2716 6.5625V6.5625Z' fill='#9AD14B'/>
                <Path d='M2.24902 37.8762V11.1733C2.24902 8.63721 4.32373 6.5625 6.85944 6.5625H4.61041C2.07471 6.5625 0 8.63721 0 11.1733V37.8762C0 40.4119 2.07471 42.4866 4.61041 42.4866H6.85944C4.32373 42.4866 2.24902 40.4119 2.24902 37.8762Z' fill='#7FBB37'/>
                <Path d='M52.1572 18.2191C52.1572 17.8922 51.8923 17.6269 51.5651 17.6269H49.8005C47.0598 17.6269 44.8176 15.3847 44.8176 12.6439V10.8794C44.8176 10.5521 44.5522 10.2872 44.2254 10.2872H11.6564C11.3296 10.2872 11.0643 10.5521 11.0643 10.8794V12.6439C11.0643 15.3847 8.82208 17.6269 6.0813 17.6269H4.31677C3.9895 17.6269 3.72461 17.8922 3.72461 18.2191V30.8301C3.72461 31.1569 3.9895 31.4222 4.31677 31.4222H6.0813C8.82208 31.4222 11.0643 33.6644 11.0643 36.4052V38.1697C11.0643 38.4966 11.3296 38.7619 11.6564 38.7619H32.2488V24.9063C32.2488 23.4015 37.5394 22.1813 44.0656 22.1813C47.1969 22.1813 50.0428 22.4625 52.1572 22.9209V18.2191Z' fill='#76AB29'/>
                <Path d='M13.3133 38.1702V36.4052C13.3133 33.6649 11.0711 31.4222 8.33032 31.4222H6.56537C6.23853 31.4222 5.97321 31.1573 5.97321 30.8301V18.2195C5.97321 17.8922 6.23853 17.6273 6.56537 17.6273H8.33032C11.0711 17.6273 13.3133 15.3847 13.3133 12.6443V10.8794C13.3133 10.5521 13.5786 10.2872 13.9055 10.2872H11.6564C11.3296 10.2872 11.0643 10.5521 11.0643 10.8794V12.6439C11.0643 15.3847 8.82208 17.6269 6.0813 17.6269H4.31677C3.9895 17.6269 3.72461 17.8922 3.72461 18.2191V30.8301C3.72461 31.1569 3.9895 31.4222 4.31677 31.4222H6.0813C8.82208 31.4222 11.0643 33.6644 11.0643 36.4052V38.1697C11.0643 38.4966 11.3296 38.7619 11.6564 38.7619H13.9055C13.5786 38.7623 13.3133 38.497 13.3133 38.1702Z' fill='#6C9B1B'/>
                <Path d='M27.941 15.1771C22.7786 15.1771 18.5938 19.3624 18.5938 24.5248C18.5938 29.6873 22.7786 33.8721 27.941 33.8721C29.4954 33.8721 30.9595 33.4906 32.249 32.8198V24.9068C32.249 24.0015 34.1639 23.1995 37.1097 22.7039C36.2625 18.4131 32.4801 15.1771 27.941 15.1771Z' fill='#9AD14B'/>
                <Path d='M20.8428 24.5248C20.8428 19.7422 24.4355 15.8009 29.0685 15.2468C28.699 15.2019 28.323 15.1771 27.941 15.1771C22.7786 15.1771 18.5938 19.3624 18.5938 24.5244C18.5938 29.6868 22.7786 33.8721 27.941 33.8721C28.3247 33.8721 28.7032 33.8486 29.0745 33.8033C24.4385 33.2518 20.8428 29.3096 20.8428 24.5248Z' fill='#7FBB37'/>
                <Path d='M27.5869 29.7684C25.8407 29.7253 24.5273 28.787 24.5273 27.9791C24.5273 27.575 24.8879 26.9687 25.3353 26.9687C25.8839 26.9687 26.3167 27.9069 27.5869 28.0509V25.1794C26.2735 24.6885 24.7871 24.1258 24.7871 22.2784C24.7871 20.4455 26.1436 19.6521 27.5869 19.4645V19.0604C27.5869 18.8728 27.8035 18.6993 28.0773 18.6993C28.323 18.6993 28.5682 18.8724 28.5682 19.0604V19.4355C29.506 19.4645 31.036 19.7384 31.036 20.5758C31.036 20.9078 30.8049 21.5713 30.2708 21.5713C29.8671 21.5713 29.5351 21.1094 28.5682 21.0372V23.5772C29.8525 24.0536 31.2958 24.703 31.2958 26.6658C31.2958 28.4406 30.1845 29.4655 28.5682 29.7253V30.1726C28.5682 30.3602 28.3226 30.5332 28.0773 30.5332C27.803 30.5332 27.5869 30.3602 27.5869 30.1726V29.7684ZM27.7022 23.2598V21.0808C26.9806 21.1962 26.5478 21.5427 26.5478 22.0908C26.5478 22.7261 27.0383 23 27.7022 23.2598ZM28.4525 25.5255V28.0368C29.0873 27.9069 29.5347 27.5318 29.5347 26.8533C29.5347 26.1462 29.0732 25.7997 28.4525 25.5255Z' fill='#6C9B1B'/>
                <Path d='M32.2285 41.26V46.7083C32.2285 48.213 37.5499 49.4324 44.1145 49.4324C50.6791 49.4324 56.0005 48.213 56.0005 46.7083V41.26C56.0005 44.5558 32.2285 44.5549 32.2285 41.26Z' fill='#F3A334'/>
                <Path d='M34.7544 42.8433C33.1719 42.4323 32.2285 41.8936 32.2285 41.2604V46.7086C32.2285 47.3418 33.1719 47.925 34.7544 48.3877V42.8433Z' fill='#E87E04'/>
                <Path d='M32.2285 35.8118V41.2604C32.2285 42.7648 37.5499 43.9841 44.1145 43.9841C50.6791 43.9841 56.0005 42.7648 56.0005 41.26V35.8118C56.0005 39.1071 32.2285 39.1067 32.2285 35.8118Z' fill='#FFC964'/>
                <Path d='M34.7544 37.3951C33.1719 36.9837 32.2285 36.4454 32.2285 35.8118V41.2604C32.2285 41.8936 33.1719 42.4768 34.7544 42.9395V37.3951Z' fill='#F3A334'/>
                <Path d='M32.2285 30.3635V35.8118C32.2285 37.3165 37.5499 38.5359 44.1145 38.5359C50.6791 38.5359 56.0005 37.3165 56.0005 35.8118V30.3635C56.0005 33.6589 32.2285 33.6584 32.2285 30.3635Z' fill='#F3A334'/>
                <Path d='M34.7544 31.9469C33.1719 31.5355 32.2285 30.9971 32.2285 30.3635V35.8118C32.2285 36.4454 33.1719 37.0286 34.7544 37.4913V31.9469Z' fill='#E87E04'/>
                <Path d='M32.2285 24.9153V30.3635C32.2285 31.8683 37.5499 33.0876 44.1145 33.0876C50.6791 33.0876 56.0005 31.8683 56.0005 30.3635V24.9153C56.0005 28.2106 32.2285 28.2098 32.2285 24.9153Z' fill='#FFC964'/>
                <Path d='M34.7544 26.4987C33.1719 26.0872 32.2285 25.5489 32.2285 24.9153V30.3635C32.2285 30.9971 33.1719 31.5803 34.7544 32.0426V26.4987Z' fill='#F3A334'/>
                <Path d='M56.0005 24.9153C56.0005 23.4109 50.6791 22.1912 44.1145 22.1912C37.5499 22.1912 32.2285 23.4109 32.2285 24.9153C32.2285 26.4196 37.5499 27.6394 44.1145 27.6394C50.6791 27.6394 56.0005 26.4196 56.0005 24.9153Z' fill='#FFE183'/>
                <Path d='M34.7544 25.1315C34.7544 23.6113 39.4182 22.3608 45.3997 22.207C44.9775 22.1967 44.549 22.1912 44.1145 22.1912C37.5499 22.1912 32.2285 23.4109 32.2285 24.9153C32.2285 25.5489 33.1719 26.1317 34.7544 26.5944V25.1315Z' fill='#FFC964'/>
            </Svg>
        );

    case 'money-exchenge-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 56 56'>
                <Path d='M23.4874 28.6699C22.0929 28.6699 20.9222 28.2119 20.662 26.8909H3.69995C2.11615 26.8909 0.820312 28.1863 0.820312 29.7701V46.4468C0.820312 48.0306 2.11615 49.3264 3.69995 49.3264H32.8411C34.4245 49.3264 35.7203 48.0306 35.7203 46.4468C35.7203 46.1712 35.7203 29.4761 35.7203 29.2232C35.7203 29.0339 35.7011 28.8489 35.6661 28.6699C29.3809 28.6699 23.9886 28.6699 23.4874 28.6699Z' fill='#FFE183'/>
                <Path d='M0.820312 24.8709C0.367432 24.8709 0 24.5035 0 24.0506V15.5578C0 13.9543 1.30396 12.6504 2.90741 12.6504H10.7901C11.243 12.6504 11.6104 13.0174 11.6104 13.4707C11.6104 13.9236 11.243 14.291 10.7901 14.291H2.90741C2.20886 14.291 1.64062 14.8593 1.64062 15.5578V24.0506C1.64062 24.5035 1.27362 24.8709 0.820312 24.8709Z' fill='#4B98D4'/>
                <Path d='M53.0927 43.4573H45.21C44.7571 43.4573 44.3896 43.0899 44.3896 42.637C44.3896 42.1837 44.7571 41.8167 45.21 41.8167H53.0927C53.7912 41.8167 54.3594 41.2485 54.3594 40.5499V32.0571C54.3594 31.6042 54.7264 31.2368 55.1797 31.2368C55.6326 31.2368 56.0001 31.6042 56.0001 32.0571V40.5499C56.0001 42.153 54.6957 43.4573 53.0927 43.4573Z' fill='#4B98D4'/>
                <Path d='M12.0013 17.5303L15.3325 14.1991C15.735 13.7966 15.735 13.1447 15.3325 12.7426L12.0013 9.41096C11.3523 8.76198 10.2432 9.22169 10.2432 10.1394V16.8019C10.2432 17.7196 11.3523 18.1793 12.0013 17.5303Z' fill='#4B98D4'/>
                <Path d='M40.6674 43.365L43.9987 46.6966C44.6472 47.3456 45.7568 46.8859 45.7568 45.9682V39.3057C45.7568 38.388 44.6472 37.9282 43.9987 38.5772L40.6674 41.9085C40.265 42.3105 40.265 42.9629 40.6674 43.365Z' fill='#4B98D4'/>
                <Path d='M12.4307 16.8024V10.1395C12.4307 10.0459 12.4426 9.95747 12.464 9.87415L12.0013 9.41102C11.3523 8.76246 10.2432 9.22175 10.2432 10.1395V16.8024C10.2432 17.7197 11.3523 18.1794 12.0013 17.5304L12.464 17.0677C12.4426 16.9844 12.4307 16.8955 12.4307 16.8024Z' fill='#2472B2'/>
                <Path d='M42.8549 43.365C42.4525 42.9629 42.4525 42.311 42.8549 41.9085L45.7235 39.0404C45.5346 38.306 44.5815 37.9945 43.9987 38.5773L40.6674 41.9085C40.265 42.311 40.265 42.9629 40.6674 43.365L43.9987 46.6966C44.5815 47.2794 45.5351 46.9679 45.7235 46.2335L42.8549 43.365Z' fill='#2472B2'/>
                <Path d='M52.3005 6.78125H23.1593C21.5755 6.78125 20.2797 8.07709 20.2797 9.66046V26.3376C20.2797 27.921 21.5755 29.2168 23.1593 29.2168H52.3005C53.8839 29.2168 55.1797 27.921 55.1797 26.3376C55.1797 26.0616 55.1797 9.91339 55.1797 9.66046C55.1797 8.07709 53.8839 6.78125 52.3005 6.78125Z' fill='#9AD14B'/>
                <Path d='M33.3945 42.0466V34.1707C33.3945 33.9665 33.2287 33.8007 33.0245 33.8007H31.9226C30.2106 33.8007 28.8105 32.4006 28.8105 30.6891V29.5868C28.8105 29.3826 28.6448 29.2168 28.4406 29.2168H8.10022C7.896 29.2168 7.73065 29.3826 7.73065 29.5868V30.6891C7.73065 32.4006 6.33014 33.8007 4.61859 33.8007H3.5163C3.31207 33.8007 3.14673 33.9665 3.14673 34.1707V42.0466C3.14673 42.2393 3.30396 42.4166 3.5163 42.4166H4.61859C6.33014 42.4166 7.73065 43.8167 7.73065 45.5282V46.6305C7.73065 46.8347 7.89642 47.0005 8.10022 47.0005H28.4406C28.6448 47.0005 28.8101 46.8347 28.8101 46.6305V45.5282C28.8101 43.8167 30.2106 42.4166 31.9222 42.4166H33.0245C33.2368 42.4166 33.3945 42.2393 33.3945 42.0466Z' fill='#F3A334'/>
                <Path d='M24.9995 38.1086C24.9995 34.389 21.9839 31.3718 18.2626 31.3718C14.5426 31.3718 11.5262 34.3878 11.5262 38.1086C11.5262 41.8133 14.5294 44.8369 18.2481 44.845H18.2626H18.2772C21.96 44.8369 24.9995 41.853 24.9995 38.1086Z' fill='#FFE183'/>
                <Path d='M19.404 39.088C20.8355 39.088 22 37.9468 22 36.544C22 35.1413 20.8354 34 19.404 34H16.5403C16.223 34 15.9658 34.2521 15.9658 34.563V37.962H15.5745C15.2572 37.962 15 38.2141 15 38.525C15 38.836 15.2572 39.088 15.5745 39.088H15.9658V39.7356H15.5745C15.2572 39.7356 15 39.9877 15 40.2986C15 40.6096 15.2572 40.8616 15.5745 40.8616H15.9658V41.437C15.9658 41.7479 16.223 42 16.5403 42C16.8576 42 17.1148 41.7479 17.1148 41.437V40.8616H19.1243C19.4416 40.8616 19.6988 40.6096 19.6988 40.2986C19.6988 39.9877 19.4416 39.7356 19.1243 39.7356H17.1148V39.088H19.404ZM17.1148 35.126H19.404C20.2019 35.126 20.851 35.7621 20.851 36.544C20.851 37.3259 20.2019 37.962 19.404 37.962H17.1148V35.126Z' fill='#F3A334'/>
                <Path d='M3.00781 46.4472C3.00781 46.1729 3.00781 30.023 3.00781 29.7701C3.00781 28.1867 4.30365 26.8909 5.88702 26.8909H3.69952C2.11615 26.8909 0.820312 28.1867 0.820312 29.7701V46.4472C0.820312 48.0306 2.11615 49.3264 3.69952 49.3264H5.88702C4.30365 49.3264 3.00781 48.0306 3.00781 46.4472Z' fill='#FFC964'/>
                <Path d='M9.91815 46.6305V45.5282C9.91815 43.8167 8.51764 42.4166 6.80609 42.4166H5.7038C5.49188 42.4166 5.33423 42.2397 5.33423 42.0466V34.1707C5.33423 33.9665 5.49957 33.8007 5.70422 33.8007H6.80609C8.51764 33.8007 9.91815 32.4006 9.91815 30.6891V29.5868C9.91815 29.3826 10.0839 29.2168 10.2881 29.2168H8.10022C7.896 29.2168 7.73065 29.3826 7.73065 29.5868V30.6891C7.73065 32.4006 6.33014 33.8007 4.61859 33.8007H3.5163C3.31207 33.8007 3.14673 33.9665 3.14673 34.1707V42.0466C3.14673 42.2388 3.30396 42.4166 3.5163 42.4166H4.61859C6.33014 42.4166 7.73065 43.8167 7.73065 45.5282V46.6305C7.73065 46.8347 7.89642 47.0005 8.10022 47.0005H10.2881C10.0839 47.0005 9.91815 46.8347 9.91815 46.6305Z' fill='#E87E04'/>
                <Path d='M13.7137 38.1086C13.7137 34.7603 16.1567 31.9845 19.3568 31.4615C19.0005 31.4034 18.6352 31.3718 18.2626 31.3718C14.5426 31.3718 11.5262 34.3878 11.5262 38.1086C11.5262 41.8133 14.5294 44.8369 18.2481 44.845H18.2626H18.2772C18.6442 44.8442 19.0039 44.8126 19.3551 44.7549C16.152 44.2307 13.7137 41.4467 13.7137 38.1086Z' fill='#FFC964'/>
                <Path d='M52.8533 21.937V14.0611C52.8533 13.8569 52.6879 13.6915 52.4837 13.6915H51.3818C49.6699 13.6915 48.2698 12.291 48.2698 10.5795V9.47717C48.2698 9.27295 48.104 9.10718 47.8998 9.10718H27.5594C27.3552 9.10718 27.1899 9.27295 27.1899 9.47717V10.5795C27.1899 12.291 25.7894 13.6915 24.0778 13.6915H22.976C22.7717 13.6915 22.606 13.8569 22.606 14.0611V21.937C22.606 22.1296 22.7632 22.3069 22.976 22.3069H24.0778C25.7894 22.3069 27.1899 23.707 27.1899 25.4186V26.5209C27.1899 26.7251 27.3557 26.8909 27.5599 26.8909H47.8998C48.104 26.8909 48.2693 26.7251 48.2693 26.5209V25.4186C48.2693 23.707 49.6699 22.3069 51.3814 22.3069H52.4837C52.696 22.3069 52.8533 22.1296 52.8533 21.937Z' fill='#76AB29'/>
                <Path d='M44.4823 17.999C44.4823 14.2695 41.4595 11.2446 37.728 11.2446C33.9981 11.2446 30.9736 14.2687 30.9736 17.999C30.9736 21.7134 33.9849 24.7452 37.7134 24.7529C37.7186 24.7529 37.7233 24.7533 37.728 24.7533C37.7327 24.7533 37.7378 24.7529 37.7425 24.7529C41.4352 24.7452 44.4823 21.7532 44.4823 17.999Z' fill='#9AD14B'/>
                <Path d='M22.4672 26.3376C22.4672 26.0633 22.4672 9.91382 22.4672 9.66046C22.4672 8.07709 23.763 6.78125 25.3468 6.78125H23.1589C21.5755 6.78125 20.2797 8.07709 20.2797 9.66046V26.3376C20.2797 27.921 21.5755 29.2168 23.1593 29.2168H25.3468C23.763 29.2168 22.4672 27.921 22.4672 26.3376Z' fill='#7FBB37'/>
                <Path d='M29.3774 26.5209V25.4186C29.3774 23.707 27.9769 22.3069 26.2654 22.3069H25.1631C24.9512 22.3069 24.7935 22.1301 24.7935 21.937V14.0611C24.7935 13.8569 24.9589 13.6915 25.1631 13.6915H26.2654C27.9769 13.6915 29.3774 12.291 29.3774 10.5795V9.47717C29.3774 9.27295 29.5428 9.10718 29.747 9.10718H27.5595C27.3553 9.10718 27.1895 9.27295 27.1895 9.47717V10.5795C27.1895 12.291 25.7894 13.6915 24.0775 13.6915H22.9756C22.7714 13.6915 22.6056 13.8569 22.6056 14.0611V21.937C22.6056 22.1296 22.7632 22.3069 22.9756 22.3069H24.0779C25.7894 22.3069 27.1899 23.707 27.1899 25.4186V26.5209C27.1899 26.7251 27.3553 26.8909 27.5595 26.8909H29.747C29.5428 26.8909 29.3774 26.7251 29.3774 26.5209Z' fill='#6C9B1B'/>
                <Path d='M33.1616 17.999C33.1616 14.6412 35.6123 11.8573 38.8222 11.3344C38.4659 11.2762 38.101 11.2446 37.7285 11.2446C33.9982 11.2446 30.9741 14.2687 30.9741 17.999C30.9741 21.7134 33.9849 24.7452 37.7135 24.7529C37.7186 24.7529 37.7233 24.7533 37.728 24.7533C37.7332 24.7533 37.7379 24.7529 37.7426 24.7529C38.1091 24.752 38.4693 24.7208 38.8205 24.6631C35.608 24.1389 33.1616 21.3469 33.1616 17.999Z' fill='#7FBB37'/>
                <Path d='M37.431 21.7985C36.1796 21.7566 35.1581 21.1102 35.1581 20.4428C35.1581 20.0882 35.4708 19.5665 35.8669 19.5665C36.3048 19.5665 36.6594 20.1818 37.431 20.3176V18.6283C36.4719 18.263 35.3456 17.8148 35.3456 16.4801C35.3456 15.1556 36.3257 14.5195 37.431 14.3631V14.0709C37.431 13.9252 37.5981 13.7893 37.8275 13.7893C38.0258 13.7893 38.2236 13.9252 38.2236 14.0709V14.3315C38.8704 14.3524 40.0902 14.5195 40.0902 15.239C40.0902 15.5205 39.9026 16.0939 39.4438 16.0939C39.0999 16.0939 38.9016 15.7602 38.2236 15.7081V17.2308C39.1729 17.585 40.2782 18.0754 40.2782 19.4832C40.2782 20.7761 39.4438 21.5583 38.2236 21.7566V22.0587C38.2236 22.2048 38.0253 22.3402 37.8275 22.3402C37.5981 22.3402 37.431 22.2048 37.431 22.0587V21.7985ZM37.5357 16.9804V15.7397C37.0662 15.8337 36.8679 16.0734 36.8679 16.3237C36.8683 16.6258 37.1392 16.8138 37.5357 16.9804ZM38.1193 18.9099V20.3069C38.4739 20.2236 38.7555 20.0254 38.7555 19.6503C38.7555 19.3059 38.4949 19.0872 38.1193 18.9099Z' fill='#6C9B1B'/>
            </Svg>
        );
    case 'case-coin-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 42 42'>
                <Path d='M23.5801 0.658127H13.5197C11.2327 0.658127 9.37216 2.51884 9.37216 4.80571V8.03995C9.37216 8.38038 9.64803 8.65617 9.98838 8.65617H12.2967C12.637 8.65617 12.9129 8.38038 12.9129 8.03995V5.26804C12.9129 4.66396 13.4043 4.17259 14.0083 4.17259H23.0912C23.6952 4.17259 24.1866 4.66404 24.1866 5.26804V8.03995C24.1866 8.38038 24.4625 8.65617 24.8028 8.65617H27.1111C27.4515 8.65617 27.7273 8.38038 27.7273 8.03995V4.80571C27.7277 2.51868 25.867 0.658127 23.5801 0.658127Z' fill='#494949'/>
                <Path d='M35.2512 7.42383H1.84866C0.829254 7.42383 0 8.25316 0 9.27248V30.8996C0 31.9189 0.829254 32.7483 1.84866 32.7483H35.2511C36.2705 32.7483 37.0998 31.9189 37.0998 30.8996V9.27248C37.0999 8.25316 36.2705 7.42383 35.2512 7.42383Z' fill='#7C3D1E'/>
                <Path d='M35.4005 9.02606H1.69936C0.762316 9.02606 0 9.78838 0 10.7253V16.1739C0 19.7973 2.94779 22.7451 6.57111 22.7451H30.5287C34.1521 22.7451 37.0999 19.7973 37.0999 16.1739V10.7253C37.0999 9.78838 36.3375 9.02606 35.4005 9.02606Z' fill='#68321A'/>
                <Path d='M35.4005 7.42383H1.69936C0.762316 7.42383 0 8.18623 0 9.12319V14.5718C0 18.1951 2.94779 21.1429 6.57111 21.1429H30.5287C34.1521 21.1429 37.0999 18.1951 37.0999 14.5718V9.12319C37.0999 8.18623 36.3375 7.42383 35.4005 7.42383Z' fill='#AA5D24'/>
                <Path d='M33.1201 23.5819C28.2236 23.5819 24.2401 27.5655 24.2401 32.4619C24.2401 37.3584 28.2236 41.3419 33.1201 41.3419C38.0165 41.3419 42 37.3584 42 32.4619C42 27.5655 38.0164 23.5819 33.1201 23.5819Z' fill='#FFD039'/>
                <Path d='M33.1202 23.5819C32.7745 23.5819 32.4336 23.6024 32.098 23.6411C36.5147 24.1497 39.9558 27.9112 39.9558 32.4619C39.9558 37.0126 36.5147 40.7741 32.098 41.2827C32.4336 41.3214 32.7745 41.3419 33.1202 41.3419C38.0167 41.3419 42.0002 37.3584 42.0002 32.4619C42.0002 27.5655 38.0166 23.5819 33.1202 23.5819Z' fill='#FFAE47'/>
                <Path d='M33.112 38.5548C36.4211 38.5548 39.1037 35.8722 39.1037 32.563C39.1037 29.2539 36.4211 26.5713 33.112 26.5713C29.8028 26.5713 27.1202 29.2539 27.1202 32.563C27.1202 35.8722 29.8028 38.5548 33.112 38.5548Z' fill='#F99608'/>
                <Path d='M34.9195 32.6684C34.5424 32.2507 33.9767 32.007 33.2384 31.9445C32.5594 31.8869 32.305 31.5976 32.3602 30.9461C32.3812 30.6977 32.523 30.129 33.3931 30.2019C33.8809 30.2433 34.0696 30.9178 34.0703 30.92C34.1542 31.2499 34.4895 31.4488 34.8195 31.3653C35.1492 31.2814 35.3486 30.946 35.2646 30.6161C35.2549 30.5776 35.1622 30.2302 34.9242 29.8689C34.6187 29.4052 34.2084 29.1114 33.7279 29.0084V28.4334C33.7279 28.093 33.4521 27.8172 33.1117 27.8172C32.7714 27.8172 32.4955 28.093 32.4955 28.4334V29.0807C31.7286 29.3323 31.2058 29.9731 31.1321 30.8419C31.0199 32.1652 31.7871 33.0584 33.1342 33.1725C33.545 33.2074 33.8462 33.3186 34.0047 33.4943C34.1222 33.6244 34.1718 33.8073 34.1523 34.0379C34.1386 34.1997 34.0236 35.0038 33.1071 34.9257C32.3911 34.8651 32.0182 34.1338 32.0067 34.1111C31.8608 33.8059 31.4957 33.6752 31.1888 33.819C30.8807 33.9638 30.7483 34.3308 30.893 34.6389C30.9163 34.6885 31.4105 35.7147 32.4954 36.0545V36.6932C32.4954 37.0337 32.7712 37.3095 33.1116 37.3095C33.4519 37.3095 33.7278 37.0337 33.7278 36.6932V36.1111C34.7239 35.8947 35.3044 35.0356 35.3801 34.1422C35.4292 33.566 35.2699 33.0564 34.9195 32.6684Z' fill='#FFD039'/>
                <Path d='M11.3496 17.4332H7.6168C7.27645 17.4332 7.00058 17.709 7.00058 18.0494V21.3821C7.00058 22.751 8.11432 23.8648 9.48326 23.8648C10.8522 23.8648 11.9658 22.751 11.9658 21.3821V18.0494C11.9658 17.709 11.6899 17.4332 11.3496 17.4332Z' fill='#FFD039'/>
                <Path d='M29.4794 17.4332H25.7466C25.4062 17.4332 25.1304 17.709 25.1304 18.0494V21.3821C25.1304 22.751 26.2441 23.8648 27.613 23.8648C28.982 23.8648 30.0957 22.751 30.0957 21.3821V18.0494C30.0956 17.709 29.8198 17.4332 29.4794 17.4332Z' fill='#FFD039'/>
                <Path d='M11.3497 17.4332H9.22353C9.56387 17.4332 9.83974 17.709 9.83974 18.0494V21.3821C9.83974 22.3707 9.25872 23.2261 8.4202 23.6251C8.74266 23.7786 9.10302 23.8647 9.48332 23.8647C10.8523 23.8647 11.9659 22.751 11.9659 21.382V18.0493C11.9659 17.709 11.69 17.4332 11.3497 17.4332Z' fill='#F4B008'/>
                <Path d='M29.4795 17.4332H27.3534C27.6937 17.4332 27.9696 17.709 27.9696 18.0494V21.3821C27.9696 22.3707 27.3886 23.2261 26.55 23.6252C26.8724 23.7786 27.2329 23.8648 27.6132 23.8648C28.9821 23.8648 30.0958 22.751 30.0958 21.3821V18.0494C30.0958 17.709 29.8199 17.4332 29.4795 17.4332Z' fill='#F4B008'/>
            </Svg>
        );
    case 'case-raise-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 42 42'>
                <Path d='M30.4764 0.213741L20.458 10.8214C20.0468 11.2566 20.3554 11.9724 20.954 11.9724H23.4656V41.797H38.4792V11.9724H40.9908C41.5893 11.9724 41.8979 11.2569 41.4868 10.8214L31.4684 0.213741C31.1993 -0.071125 30.7455 -0.071125 30.4764 0.213741Z' fill='#FFE07D'/>
                <Path d='M37.8329 8.94046L31.9648 2.72691C31.4261 2.15654 30.5187 2.15654 29.98 2.72691L24.1119 8.94046C23.5165 9.57076 23.9635 10.6077 24.8303 10.6077V41.797H37.1145V10.6077C37.9813 10.6077 38.4283 9.57076 37.8329 8.94046Z' fill='#FFEAA8'/>
                <Path d='M20.6406 13.6581H11.8344C9.83242 13.6581 8.2038 15.2869 8.2038 17.2887V20.1198C8.2038 20.4178 8.44528 20.6592 8.7432 20.6592H10.7637C11.0617 20.6592 11.3032 20.4178 11.3032 20.1198V17.6934C11.3032 17.1646 11.7333 16.7345 12.262 16.7345H20.2127C20.7415 16.7345 21.1716 17.1647 21.1716 17.6934V20.1198C21.1716 20.4178 21.4131 20.6592 21.711 20.6592H23.7315C24.0294 20.6592 24.2709 20.4178 24.2709 20.1198V17.2887C24.2712 15.2868 22.6425 13.6581 20.6406 13.6581Z' fill='#494949'/>
                <Path d='M30.857 19.5805H1.61822C0.725885 19.5805 0 20.3064 0 21.1987V40.1299C0 41.0222 0.725885 41.7481 1.61822 41.7481H30.857C31.7493 41.7481 32.4752 41.0222 32.4752 40.1299V21.1987C32.4753 20.3064 31.7493 19.5805 30.857 19.5805Z' fill='#7C3D1E'/>
                <Path d='M30.9877 20.983H1.48753C0.667291 20.983 0 21.6503 0 22.4704V27.2398C0 30.4115 2.58034 32.9918 5.752 32.9918H26.7232C29.8949 32.9918 32.4752 30.4115 32.4752 27.2398V22.4704C32.4752 21.6503 31.8079 20.983 30.9877 20.983Z' fill='#68321A'/>
                <Path d='M30.9877 19.5805H1.48753C0.667291 19.5805 0 20.2478 0 21.068V25.8374C0 29.0091 2.58034 31.5894 5.752 31.5894H26.7232C29.8949 31.5894 32.4752 29.0091 32.4752 25.8374V21.068C32.4752 20.2478 31.8079 19.5805 30.9877 19.5805Z' fill='#AA5D24'/>
                <Path d='M9.9348 28.3421H6.66727C6.36935 28.3421 6.12787 28.5835 6.12787 28.8815V31.7988C6.12787 32.9971 7.10278 33.972 8.30107 33.972C9.49937 33.972 10.4742 32.9971 10.4742 31.7988V28.8815C10.4742 28.5835 10.2326 28.3421 9.9348 28.3421Z' fill='#FFD039'/>
                <Path d='M25.8047 28.3421H22.5371C22.2392 28.3421 21.9977 28.5835 21.9977 28.8815V31.7988C21.9977 32.9971 22.9727 33.972 24.1709 33.972C25.3692 33.972 26.3441 32.9971 26.3441 31.7988V28.8815C26.3441 28.5835 26.1026 28.3421 25.8047 28.3421Z' fill='#FFD039'/>
                <Path d='M9.9349 28.3421H8.07377C8.37169 28.3421 8.61317 28.5835 8.61317 28.8815V31.7988C8.61317 32.6642 8.10457 33.4129 7.37057 33.7622C7.65284 33.8965 7.96829 33.9719 8.30118 33.9719C9.49947 33.9719 10.4743 32.997 10.4743 31.7987V28.8815C10.4743 28.5835 10.2328 28.3421 9.9349 28.3421Z' fill='#F4B008'/>
                <Path d='M25.8048 28.3421H23.9437C24.2416 28.3421 24.4831 28.5835 24.4831 28.8815V31.7988C24.4831 32.6642 23.9745 33.4129 23.2405 33.7623C23.5227 33.8965 23.8382 33.972 24.1711 33.972C25.3694 33.972 26.3443 32.9971 26.3443 31.7988V28.8815C26.3442 28.5835 26.1027 28.3421 25.8048 28.3421Z' fill='#F4B008'/>
            </Svg>
        );
    case 'users-plus-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 42 42'>
                <Path d='M21.5 23C27.8513 23 33 17.8513 33 11.5C33 5.14873 27.8513 0 21.5 0C15.1487 0 10 5.14873 10 11.5C10 17.8513 15.1487 23 21.5 23Z' fill='#7CC394'/>
                <Path d='M28.2453 10.25H22.6897V4.74998C22.6897 4.33573 22.3192 4 21.8621 4H21.0345C20.5774 4 20.2069 4.33573 20.2069 4.74998V10.25H14.7547C14.3379 10.25 14 10.6231 14 11.0833V11.9167C14 12.3768 14.3379 12.75 14.7547 12.75H20.2069V18.25C20.2069 18.6642 20.5774 19 21.0345 19H21.8621C22.3192 19 22.6897 18.6642 22.6897 18.25V12.75H28.2453C28.6621 12.75 29 12.3768 29 11.9167V11.0833C29 10.6231 28.6622 10.25 28.2453 10.25Z' fill='white'/>
                <Path d='M38.3991 24.0096C39.5956 24.0096 40.5656 23.0396 40.5656 21.843C40.5656 20.6465 39.5956 19.6765 38.3991 19.6765C37.2025 19.6765 36.2326 20.6465 36.2326 21.843C36.2326 23.0396 37.2025 24.0096 38.3991 24.0096Z' fill='#633C3E'/>
                <Path d='M39.689 42H30.3134C28.865 42 27.7746 40.6811 28.0472 39.2588L28.4154 37.3346C28.5213 36.7827 28.7354 36.268 29.0394 35.8159C29.5194 35.0955 30.2237 34.5325 31.0652 34.227L35.0279 32.7895L38.9602 34.216C39.8059 34.5233 40.5127 35.0904 40.9935 35.8168V35.8176C41.3017 36.2807 41.5167 36.8073 41.6183 37.3728L41.9603 39.286C42.2134 40.7005 41.1264 42 39.689 42Z' fill='#FEE07D'/>
                <Path d='M37.1963 33.5817L34.9792 35.5171L32.7635 33.5817V30.6106H37.1963V33.5817Z' fill='#ECBD83'/>
                <Path d='M38.5004 25.3699V27.888C38.5004 29.8627 36.8844 31.5256 34.9101 31.4875C32.9969 31.4506 31.458 29.8883 31.458 27.9667V25.3699C31.458 23.4252 33.0345 22.4103 34.9792 22.4103C36.9239 22.4103 38.5004 23.4252 38.5004 25.3699Z' fill='#F7D4AD'/>
                <Path d='M31.3191 38.6002V42H30.3134C28.865 42 27.7746 40.6811 28.0472 39.2588L28.4154 37.3346C28.5213 36.7826 28.7354 36.268 29.0394 35.8159C30.3405 36.0758 31.3191 37.2237 31.3191 38.6002Z' fill='#FFC269'/>
                <Path d='M39.6891 42H38.7198V38.6002C38.7198 37.2263 39.6959 36.0791 40.9936 35.8176C41.3017 36.2807 41.5168 36.8073 41.6183 37.3728L41.9603 39.286C42.2135 40.7005 41.1265 42 39.6891 42Z' fill='#FFC269'/>
                <Path d='M38.5004 25.3699C38.5004 23.4252 36.9239 21.8487 34.9792 21.8487C33.0345 21.8487 31.458 23.4252 31.458 25.3699V26.1504C31.5669 26.1703 31.6789 26.1812 31.7936 26.1812C32.6834 26.1812 33.4271 25.5557 33.6089 24.7203C33.6353 24.599 33.795 24.5684 33.8651 24.6708C34.3378 25.3623 35.5872 26.8053 37.5398 26.3252C37.5825 26.3147 37.6227 26.2949 37.664 26.2784C37.9531 26.1626 38.2671 25.9796 38.5004 25.7969V25.612V25.3699H38.5004Z' fill='#6E4848'/>
                <Path d='M11.6867 42H2.31109C0.862668 42 -0.227691 40.6811 0.0448984 39.2588L0.413137 37.3346C0.529129 36.731 0.774648 36.1723 1.12345 35.6906C1.60005 35.0295 2.27049 34.5139 3.06201 34.227L7.02559 32.7895L10.9578 34.216C11.7544 34.5046 12.4274 35.0253 12.9049 35.6915C13.2588 36.1834 13.5052 36.7557 13.6161 37.3728L13.9581 39.286C14.2111 40.7005 13.1241 42 11.6867 42Z' fill='#B8B6FF'/>
                <Path d='M9.21233 33.5817L9.21151 33.5824C7.94191 34.6907 6.04871 34.6904 4.77953 33.5817V30.6106H9.21233V33.5817Z' fill='#ECBD83'/>
                <Path d='M10.5168 25.3695V27.888C10.5168 29.863 8.9007 31.5257 6.92654 31.4875C5.01332 31.4503 3.47433 29.8884 3.47433 27.9667V25.3696C3.47433 23.425 5.05057 21.8488 6.99512 21.8488C7.96784 21.8488 8.8482 22.2433 9.48485 22.8799C10.091 23.486 10.4778 24.3114 10.5143 25.2282C10.5159 25.2746 10.5168 25.3221 10.5168 25.3695Z' fill='#F7D4AD'/>
                <Path d='M3.39973 38.5232V42H2.31109C0.862668 42 -0.227691 40.6811 0.0448984 39.2588L0.413137 37.3346C0.529129 36.731 0.774648 36.1723 1.12345 35.6906C2.42618 35.975 3.39973 37.1357 3.39973 38.5232Z' fill='#A09EEF'/>
                <Path d='M11.6867 42H10.6361V38.5232C10.6361 37.1383 11.6063 35.9793 12.9049 35.6915C13.2588 36.1833 13.5051 36.7556 13.616 37.3727L13.958 39.2859C14.2111 40.7005 13.1241 42 11.6867 42Z' fill='#A09EEF'/>
                <Path d='M10.5143 25.2281L10.2823 25.3694C10.0334 25.5133 9.76244 25.6462 9.47295 25.7613C8.15315 26.2904 6.86214 26.3099 6.2383 25.8722C6.2383 25.8722 5.58221 25.3051 4.43853 25.5395C4.10327 25.6081 3.77309 25.642 3.47433 25.6546V25.3693C3.47433 23.4248 5.05057 21.8485 6.99512 21.8485C7.96784 21.8485 8.8482 22.243 9.48485 22.8797C10.091 23.4859 10.4778 24.3113 10.5143 25.2281Z' fill='#6E4848'/>
                <Path d='M26.5893 41.8789H15.4292C13.7048 41.8789 12.407 40.3094 12.7313 38.6155L13.1698 36.3256C13.3095 35.5984 13.6076 34.9262 14.0299 34.348C14.5971 33.5709 15.3886 32.9648 16.3232 32.6261L21.0411 30.9153L25.7217 32.6126C26.6647 32.9546 27.4622 33.5683 28.0302 34.3548C28.4568 34.944 28.754 35.6305 28.8861 36.3704L29.2941 38.6485C29.5945 40.3323 28.3002 41.8789 26.5893 41.8789Z' fill='#C2DDFF'/>
                <Path d='M23.6222 31.8577L23.6212 31.8586C22.1099 33.178 19.8563 33.1775 18.3453 31.8577V28.3209H23.6222V31.8577Z' fill='#ECBD83'/>
                <Path d='M25.1747 22.0823V25.08C25.1747 27.4308 23.2505 29.4101 20.9005 29.3652C18.6233 29.3212 16.7913 27.4614 16.7913 25.1739V22.0823C16.7913 19.767 18.6681 17.8902 20.9826 17.8902C22.1407 17.8902 23.1888 18.3592 23.9472 19.1177C24.6719 19.8424 25.1324 20.8311 25.1714 21.9283C25.1739 21.979 25.1747 22.0306 25.1747 22.0823Z' fill='#F7D4AD'/>
                <Path d='M16.7913 37.7122V41.8789H15.4292C13.7048 41.8789 12.407 40.3094 12.7313 38.6155L13.1698 36.3256C13.3095 35.5984 13.6076 34.9262 14.0299 34.348C15.6044 34.6587 16.7913 36.0471 16.7913 37.7122Z' fill='#A4CCFF'/>
                <Path d='M26.5893 41.8789H25.2991V37.7122C25.2991 36.0581 26.4708 34.6765 28.0301 34.3548C28.4567 34.944 28.7539 35.6305 28.886 36.3704L29.294 38.6485C29.5945 40.3323 28.3001 41.8789 26.5893 41.8789Z' fill='#A4CCFF'/>
                <Path d='M25.1747 21.9145L24.8985 22.0828C24.6021 22.2542 24.2795 22.4125 23.9348 22.5496C22.3631 23.1797 20.8258 23.2028 20.0828 22.6816C20.0828 22.6816 19.3016 22.0062 17.9396 22.2854C17.5404 22.3671 17.1473 22.4074 16.7914 22.4225V22.0828C16.7914 19.7672 18.6685 17.8901 20.984 17.8901C22.1423 17.8901 23.1908 18.3599 23.9489 19.118C24.6706 19.8398 25.1313 20.8227 25.1747 21.9145Z' fill='#6E4848'/>
            </Svg>
        );
    case 'basket-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 42 42'>
                <Path d='M2.66858 19.1584L6.76735 37.1452C6.89868 37.7216 7.41138 38.1306 8.00258 38.1306H33.5559C34.1471 38.1306 34.6598 37.7217 34.7911 37.1452L38.8899 19.1584H2.66858ZM13.0862 23.1612L12.4377 33.6765C12.4239 33.8992 12.2393 34.0728 12.0162 34.0728H10.3858C10.1626 34.0728 9.97797 33.8992 9.96427 33.6765L9.31574 23.1612C9.30072 22.9183 9.49374 22.7129 9.73721 22.7129H12.6647C12.9082 22.7129 13.1012 22.9182 13.0862 23.1612ZM19.4718 23.1612L18.8232 33.6765C18.8095 33.8992 18.6249 34.0728 18.4018 34.0728H16.7713C16.5482 34.0728 16.3635 33.8992 16.3498 33.6765L15.7013 23.1612C15.6863 22.9183 15.8794 22.7129 16.1228 22.7129H19.0503C19.2938 22.7129 19.4868 22.9182 19.4718 23.1612ZM25.8573 23.1612L25.2088 33.6765C25.1951 33.8992 25.0104 34.0728 24.7873 34.0728H23.1569C22.9337 34.0728 22.7491 33.8992 22.7354 33.6765L22.0868 23.1612C22.0718 22.9183 22.2649 22.7129 22.5083 22.7129H25.4359C25.6792 22.7129 25.8723 22.9182 25.8573 23.1612ZM32.2429 23.1612L31.5944 33.6765C31.5806 33.8992 31.396 34.0728 31.1729 34.0728H29.5424C29.3193 34.0728 29.1346 33.8992 29.1209 33.6765L28.4724 23.1612C28.4574 22.9183 28.6504 22.7129 28.8939 22.7129H31.8214C32.0648 22.7129 32.2578 22.9182 32.2429 23.1612Z' fill='#80B6FC'/>
                <Path d='M36.2688 19.1584L32.6565 35.0106C32.5252 35.587 32.0125 35.996 31.4213 35.996H6.50549L6.76742 37.1453C6.89875 37.7217 7.41145 38.1307 8.00265 38.1307H33.5559C34.1472 38.1307 34.6598 37.7218 34.7911 37.1453L38.8899 19.1584H36.2688V19.1584Z' fill='#5392F9'/>
                <Path d='M5.5354 15.3066L12.5638 8.27822L14.3552 10.0696L7.32678 17.098L5.5354 15.3066Z' fill='#E4F6FF'/>
                <Path d='M6.54797 16.3194L13.5764 9.29099L14.3551 10.0697L7.32669 17.0981L6.54797 16.3194Z' fill='#D3EFFB'/>
                <Path d='M18.2733 4.37009C17.2725 3.36923 15.6498 3.36923 14.649 4.37009L10.9716 8.04747C10.5474 8.47174 10.5474 9.15973 10.9716 9.584L13.0594 11.6718C13.4837 12.096 14.1717 12.096 14.596 11.6718L18.2733 7.9944C19.2742 6.99362 19.2742 5.37087 18.2733 4.37009ZM16.2658 7.22954C15.776 7.22954 15.3789 6.83251 15.3789 6.3427C15.3789 5.85289 15.776 5.45586 16.2658 5.45586C16.7556 5.45586 17.1526 5.85289 17.1526 6.3427C17.1526 6.83251 16.7556 7.22954 16.2658 7.22954Z' fill='#365E7D'/>
                <Path d='M18.2733 4.3701C18.1017 4.1985 17.9115 4.05699 17.7095 3.9442C18.2543 4.9198 18.1128 6.17553 17.2836 7.0047L13.6062 10.6821C13.182 11.1064 12.494 11.1064 12.0697 10.6821L13.0594 11.6718C13.4837 12.0961 14.1717 12.0961 14.5959 11.6718L18.2733 7.99441C19.2742 6.99363 19.2742 5.37089 18.2733 4.3701Z' fill='#2B4D66'/>
                <Path d='M27.1969 10.0718L28.9883 8.28038L36.0166 15.3088L34.2253 17.1001L27.1969 10.0718Z' fill='#E4F6FF'/>
                <Path d='M28.9923 8.27748L28.2322 9.03767L34.5014 15.307L33.4699 16.3385L34.2301 17.0986L36.0217 15.307L28.9923 8.27748Z' fill='#D3EFFB'/>
                <Path d='M23.2852 7.9944L26.9626 11.6718C27.3868 12.096 28.0748 12.096 28.4991 11.6718L30.5869 9.584C31.0111 9.15973 31.0111 8.47174 30.5869 8.04747L26.9095 4.37009C25.9086 3.36923 24.286 3.36923 23.2852 4.37009C22.2843 5.37087 22.2843 6.99362 23.2852 7.9944ZM24.406 6.3427C24.406 5.85289 24.803 5.45586 25.2928 5.45586C25.7826 5.45586 26.1797 5.85289 26.1797 6.3427C26.1797 6.83251 25.7826 7.22954 25.2928 7.22954C24.803 7.22954 24.406 6.83251 24.406 6.3427Z' fill='#365E7D'/>
                <Path d='M30.5869 8.04747L26.9095 4.37009C26.1702 3.63074 25.0906 3.43797 24.174 3.79095C24.4981 3.9158 24.8034 4.10865 25.0648 4.37009L29.0327 8.33794C29.2966 8.60183 29.2966 9.02963 29.0327 9.29352L26.8085 11.5177L26.9625 11.6718C27.3868 12.0961 28.0748 12.0961 28.4991 11.6719L30.5869 9.58408C31.0112 9.15965 31.0112 8.47173 30.5869 8.04747Z' fill='#2B4D66'/>
                <Path d='M40.7382 20.1054H38.6741L37.4444 19.2099L36.053 20.1054H0.820312C0.367254 20.1054 0 19.7382 0 19.2851V15.451C0 14.998 0.367254 14.6307 0.820312 14.6307H40.7382C41.1913 14.6307 41.5585 14.998 41.5585 15.451V19.2851C41.5585 19.7382 41.1913 20.1054 40.7382 20.1054Z' fill='#80B6FC'/>
                <Path d='M40.7382 14.6306H39.4063V17.1328C39.4063 17.5858 39.039 17.9531 38.5859 17.9531H0V19.285C0 19.7381 0.367254 20.1053 0.820312 20.1053H40.7382C41.1913 20.1053 41.5585 19.7381 41.5585 19.285V15.4509C41.5585 14.9979 41.1913 14.6306 40.7382 14.6306Z' fill='#5392F9'/>
            </Svg>
        );
    case 'basket-exchange-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 42 42'>
                <Path d='M2.66855 16.1584L6.76732 34.1452C6.89865 34.7216 7.41135 35.1306 8.00255 35.1306H33.5559C34.1471 35.1306 34.6597 34.7217 34.7911 34.1452L38.8899 16.1584H2.66855ZM13.0862 20.1612L12.4376 30.6765C12.4239 30.8992 12.2393 31.0728 12.0162 31.0728H10.3857C10.1626 31.0728 9.97794 30.8992 9.96424 30.6765L9.3157 20.1612C9.30069 19.9183 9.49371 19.7129 9.73718 19.7129H12.6647C12.9082 19.7129 13.1012 19.9182 13.0862 20.1612ZM19.4717 20.1612L18.8232 30.6765C18.8095 30.8992 18.6249 31.0728 18.4017 31.0728H16.7713C16.5482 31.0728 16.3635 30.8992 16.3498 30.6765L15.7013 20.1612C15.6863 19.9183 15.8794 19.7129 16.1227 19.7129H19.0503C19.2937 19.7129 19.4868 19.9182 19.4717 20.1612ZM25.8573 20.1612L25.2088 30.6765C25.1951 30.8992 25.0104 31.0728 24.7873 31.0728H23.1568C22.9337 31.0728 22.7491 30.8992 22.7354 30.6765L22.0868 20.1612C22.0718 19.9183 22.2648 19.7129 22.5083 19.7129H25.4358C25.6792 19.7129 25.8723 19.9182 25.8573 20.1612ZM32.2429 20.1612L31.5943 30.6765C31.5805 30.8992 31.396 31.0728 31.1728 31.0728H29.5424C29.3193 31.0728 29.1346 30.8992 29.1209 30.6765L28.4724 20.1612C28.4574 19.9183 28.6504 19.7129 28.8939 19.7129H31.8214C32.0648 19.7129 32.2578 19.9182 32.2429 20.1612Z' fill='#80B6FC'/>
                <Path d='M36.2689 16.1584L32.6565 32.0106C32.5252 32.587 32.0125 32.996 31.4213 32.996H6.50555L6.76748 34.1453C6.89881 34.7217 7.41151 35.1307 8.00271 35.1307H33.5559C34.1472 35.1307 34.6598 34.7218 34.7912 34.1453L38.8899 16.1584H36.2689V16.1584Z' fill='#5392F9'/>
                <Path d='M5.53525 12.3066L12.5636 5.27825L14.355 7.06963L7.32663 14.098L5.53525 12.3066Z' fill='#E4F6FF'/>
                <Path d='M6.5481 13.3194L13.5765 6.291L14.3552 7.06971L7.32681 14.0981L6.5481 13.3194Z' fill='#D3EFFB'/>
                <Path d='M18.2732 1.37009C17.2723 0.36923 15.6497 0.36923 14.6489 1.37009L10.9715 5.04747C10.5472 5.47174 10.5472 6.15973 10.9715 6.584L13.0593 8.67178C13.4835 9.09604 14.1715 9.09604 14.5958 8.67178L18.2732 4.9944C19.274 3.99362 19.274 2.37087 18.2732 1.37009ZM16.2656 4.22954C15.7758 4.22954 15.3788 3.83251 15.3788 3.3427C15.3788 2.85289 15.7758 2.45586 16.2656 2.45586C16.7554 2.45586 17.1525 2.85289 17.1525 3.3427C17.1525 3.83251 16.7554 4.22954 16.2656 4.22954Z' fill='#365E7D'/>
                <Path d='M18.2733 1.3701C18.1017 1.1985 17.9114 1.05699 17.7095 0.944199C18.2542 1.9198 18.1127 3.17553 17.2836 4.0047L13.6062 7.68208C13.1819 8.10643 12.4939 8.10643 12.0697 7.68208L13.0594 8.67179C13.4836 9.09605 14.1716 9.09605 14.5959 8.67179L18.2733 4.99441C19.2741 3.99363 19.2741 2.37089 18.2733 1.3701Z' fill='#2B4D66'/>
                <Path d='M27.1968 7.07173L28.9882 5.28035L36.0165 12.3087L34.2251 14.1001L27.1968 7.07173Z' fill='#E4F6FF'/>
                <Path d='M28.9922 5.27748L28.2321 6.03767L34.5014 12.307L33.4698 13.3385L34.23 14.0986L36.0217 12.307L28.9922 5.27748Z' fill='#D3EFFB'/>
                <Path d='M23.2852 4.9944L26.9626 8.67178C27.3868 9.09604 28.0748 9.09604 28.4991 8.67178L30.5869 6.584C31.0111 6.15973 31.0111 5.47174 30.5869 5.04747L26.9095 1.37009C25.9086 0.36923 24.286 0.36923 23.2852 1.37009C22.2843 2.37087 22.2843 3.99362 23.2852 4.9944ZM24.406 3.3427C24.406 2.85289 24.803 2.45586 25.2928 2.45586C25.7826 2.45586 26.1797 2.85289 26.1797 3.3427C26.1797 3.83251 25.7826 4.22954 25.2928 4.22954C24.803 4.22954 24.406 3.83251 24.406 3.3427Z' fill='#365E7D'/>
                <Path d='M30.587 5.04747L26.9096 1.37009C26.1703 0.630741 25.0907 0.437967 24.174 0.790948C24.4982 0.915799 24.8035 1.10865 25.0649 1.37009L29.0327 5.33794C29.2966 5.60183 29.2966 6.02963 29.0327 6.29352L26.8086 8.51772L26.9626 8.67177C27.3869 9.09612 28.0749 9.09612 28.4992 8.67185L30.587 6.58408C31.0113 6.15965 31.0113 5.47173 30.587 5.04747Z' fill='#2B4D66'/>
                <Path d='M40.7382 17.1054H38.6741L37.4444 16.2099L36.053 17.1054H0.820312C0.367254 17.1054 0 16.7382 0 16.2851V12.451C0 11.998 0.367254 11.6307 0.820312 11.6307H40.7382C41.1913 11.6307 41.5585 11.998 41.5585 12.451V16.2851C41.5585 16.7382 41.1913 17.1054 40.7382 17.1054Z' fill='#80B6FC'/>
                <Path d='M40.7382 11.6306H39.4063V14.1328C39.4063 14.5858 39.039 14.9531 38.5859 14.9531H0V16.285C0 16.7381 0.367254 17.1053 0.820312 17.1053H40.7382C41.1913 17.1053 41.5585 16.7381 41.5585 16.285V12.4509C41.5585 11.9979 41.1913 11.6306 40.7382 11.6306Z' fill='#5392F9'/>
                <Path d='M32.9041 41.3806C37.9276 41.3806 41.9999 37.3083 41.9999 32.2848C41.9999 27.2613 37.9276 23.189 32.9041 23.189C27.8807 23.189 23.8083 27.2613 23.8083 32.2848C23.8083 37.3083 27.8807 41.3806 32.9041 41.3806Z' fill='#FFE07D'/>
                <Path d='M32.8663 38.0992C31.3824 38.0992 29.9874 37.5213 28.9381 36.4721C27.8889 35.4228 27.311 34.0277 27.311 32.5439C27.311 31.06 27.8889 29.665 28.9381 28.6157C29.9874 27.5665 31.3824 26.9886 32.8663 26.9886C34.3501 26.9886 35.7452 27.5665 36.7945 28.6157C37.8437 29.665 38.4216 31.06 38.4216 32.5439C38.4216 34.0277 37.8437 35.4228 36.7945 36.4721C35.7452 37.5214 34.3501 38.0992 32.8663 38.0992ZM32.8663 28.2524C31.72 28.2524 30.6423 28.6988 29.8318 29.5094C29.0211 30.3199 28.5747 31.3976 28.5747 32.544C28.5747 33.6903 29.0211 34.768 29.8318 35.5785C30.6423 36.3891 31.72 36.8355 32.8663 36.8355C34.0126 36.8355 35.0903 36.3891 35.9008 35.5785C36.7115 34.7679 37.1579 33.6903 37.1579 32.544C37.1579 31.3976 36.7115 30.3199 35.9008 29.5094C35.0903 28.6988 34.0126 28.2524 32.8663 28.2524Z' fill='#2F3D47'/>
                <Path d='M36.5977 30.0534H34.1079C33.7589 30.0534 33.4761 29.7705 33.4761 29.4215C33.4761 29.0726 33.7589 28.7896 34.1079 28.7896H35.9658V26.9319C35.9658 26.5829 36.2486 26.3 36.5977 26.3C36.9467 26.3 37.2296 26.5829 37.2296 26.9319V29.4215C37.2296 29.7705 36.9467 30.0534 36.5977 30.0534Z' fill='#2F3D47'/>
                <Path d='M28.7525 38.377C28.4034 38.377 28.1206 38.0941 28.1206 37.7451V35.2555C28.1206 34.9065 28.4034 34.6236 28.7525 34.6236H31.2421C31.5912 34.6236 31.874 34.9065 31.874 35.2555C31.874 35.6045 31.5912 35.8874 31.2421 35.8874H29.3844V37.7451C29.3844 38.0941 29.1015 38.377 28.7525 38.377Z' fill='#2F3D47'/>
                <Path d='M38.3855 25.026C39.5383 26.5503 40.2225 28.4488 40.2225 30.5073C40.2225 35.5307 36.1502 39.603 31.1267 39.603C29.0682 39.603 27.1697 38.9189 25.6454 37.766C27.3059 39.9616 29.9393 41.3806 32.9042 41.3806C37.9276 41.3806 42 37.3083 42 32.2848C42.0001 29.3198 40.5811 26.6865 38.3855 25.026Z' fill='#FFD064'/>
            </Svg>
        );

    case 'basket-user-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 42 42'>
                <Path d='M2.66855 16.1584L6.76732 34.1452C6.89865 34.7216 7.41135 35.1306 8.00255 35.1306H33.5559C34.1471 35.1306 34.6597 34.7217 34.7911 34.1452L38.8899 16.1584H2.66855ZM13.0862 20.1612L12.4376 30.6765C12.4239 30.8992 12.2393 31.0728 12.0162 31.0728H10.3857C10.1626 31.0728 9.97794 30.8992 9.96424 30.6765L9.3157 20.1612C9.30069 19.9183 9.49371 19.7129 9.73718 19.7129H12.6647C12.9082 19.7129 13.1012 19.9182 13.0862 20.1612ZM19.4717 20.1612L18.8232 30.6765C18.8095 30.8992 18.6249 31.0728 18.4017 31.0728H16.7713C16.5482 31.0728 16.3635 30.8992 16.3498 30.6765L15.7013 20.1612C15.6863 19.9183 15.8794 19.7129 16.1227 19.7129H19.0503C19.2937 19.7129 19.4868 19.9182 19.4717 20.1612ZM25.8573 20.1612L25.2088 30.6765C25.1951 30.8992 25.0104 31.0728 24.7873 31.0728H23.1568C22.9337 31.0728 22.7491 30.8992 22.7354 30.6765L22.0868 20.1612C22.0718 19.9183 22.2648 19.7129 22.5083 19.7129H25.4358C25.6792 19.7129 25.8723 19.9182 25.8573 20.1612ZM32.2429 20.1612L31.5943 30.6765C31.5805 30.8992 31.396 31.0728 31.1728 31.0728H29.5424C29.3193 31.0728 29.1346 30.8992 29.1209 30.6765L28.4724 20.1612C28.4574 19.9183 28.6504 19.7129 28.8939 19.7129H31.8214C32.0648 19.7129 32.2578 19.9182 32.2429 20.1612Z' fill='#80B6FC'/>
                <Path d='M36.2689 16.1584L32.6565 32.0106C32.5252 32.587 32.0125 32.996 31.4213 32.996H6.50555L6.76748 34.1453C6.89881 34.7217 7.41151 35.1307 8.00271 35.1307H33.5559C34.1472 35.1307 34.6598 34.7218 34.7912 34.1453L38.8899 16.1584H36.2689V16.1584Z' fill='#5392F9'/>
                <Path d='M5.53525 12.3066L12.5636 5.27824L14.355 7.06962L7.32663 14.098L5.53525 12.3066Z' fill='#E4F6FF'/>
                <Path d='M6.5481 13.3194L13.5765 6.29099L14.3552 7.0697L7.32681 14.0981L6.5481 13.3194Z' fill='#D3EFFB'/>
                <Path d='M18.2732 1.37009C17.2723 0.36923 15.6497 0.36923 14.6489 1.37009L10.9715 5.04747C10.5472 5.47174 10.5472 6.15973 10.9715 6.584L13.0593 8.67178C13.4835 9.09604 14.1715 9.09604 14.5958 8.67178L18.2732 4.9944C19.274 3.99362 19.274 2.37087 18.2732 1.37009ZM16.2656 4.22954C15.7758 4.22954 15.3788 3.83251 15.3788 3.3427C15.3788 2.85289 15.7758 2.45586 16.2656 2.45586C16.7554 2.45586 17.1525 2.85289 17.1525 3.3427C17.1525 3.83251 16.7554 4.22954 16.2656 4.22954Z' fill='#365E7D'/>
                <Path d='M18.2732 1.3701C18.1016 1.1985 17.9114 1.05699 17.7094 0.944199C18.2542 1.9198 18.1127 3.17553 17.2835 4.0047L13.6062 7.68208C13.1819 8.10643 12.4939 8.10643 12.0696 7.68208L13.0593 8.67179C13.4836 9.09605 14.1716 9.09605 14.5959 8.67179L18.2732 4.99441C19.2741 3.99363 19.2741 2.37089 18.2732 1.3701Z' fill='#2B4D66'/>
                <Path d='M27.1968 7.07175L28.9882 5.28036L36.0165 12.3087L34.2251 14.1001L27.1968 7.07175Z' fill='#E4F6FF'/>
                <Path d='M28.9923 5.27748L28.2322 6.03767L34.5014 12.307L33.4699 13.3385L34.2301 14.0986L36.0217 12.307L28.9923 5.27748Z' fill='#D3EFFB'/>
                <Path d='M23.2852 4.9944L26.9626 8.67178C27.3868 9.09604 28.0748 9.09604 28.4991 8.67178L30.5869 6.584C31.0111 6.15973 31.0111 5.47174 30.5869 5.04747L26.9095 1.37009C25.9086 0.36923 24.286 0.36923 23.2852 1.37009C22.2843 2.37087 22.2843 3.99362 23.2852 4.9944ZM24.406 3.3427C24.406 2.85289 24.803 2.45586 25.2928 2.45586C25.7826 2.45586 26.1797 2.85289 26.1797 3.3427C26.1797 3.83251 25.7826 4.22954 25.2928 4.22954C24.803 4.22954 24.406 3.83251 24.406 3.3427Z' fill='#365E7D'/>
                <Path d='M30.587 5.04747L26.9096 1.37009C26.1702 0.630741 25.0907 0.437967 24.174 0.790948C24.4982 0.915799 24.8034 1.10865 25.0649 1.37009L29.0327 5.33794C29.2966 5.60183 29.2966 6.02963 29.0327 6.29352L26.8085 8.51772L26.9626 8.67177C27.3868 9.09612 28.0748 9.09612 28.4992 8.67185L30.587 6.58408C31.0113 6.15965 31.0113 5.47173 30.587 5.04747Z' fill='#2B4D66'/>
                <Path d='M40.7382 17.1054H38.6741L37.4444 16.2099L36.053 17.1054H0.820312C0.367254 17.1054 0 16.7382 0 16.2851V12.451C0 11.998 0.367254 11.6307 0.820312 11.6307H40.7382C41.1913 11.6307 41.5585 11.998 41.5585 12.451V16.2851C41.5585 16.7382 41.1913 17.1054 40.7382 17.1054Z' fill='#80B6FC'/>
                <Path d='M40.7382 11.6306H39.4063V14.1328C39.4063 14.5858 39.039 14.9531 38.5859 14.9531H0V16.285C0 16.7381 0.367254 17.1053 0.820312 17.1053H40.7382C41.1913 17.1053 41.5585 16.7381 41.5585 16.285V12.4509C41.5585 11.9979 41.1913 11.6306 40.7382 11.6306Z' fill='#5392F9'/>
                <Path d='M32.9041 41.3806C37.9276 41.3806 41.9999 37.3083 41.9999 32.2848C41.9999 27.2613 37.9276 23.189 32.9041 23.189C27.8807 23.189 23.8083 27.2613 23.8083 32.2848C23.8083 37.3083 27.8807 41.3806 32.9041 41.3806Z' fill='#FFE07D'/>
                <Path d='M38.3856 25.026C39.5384 26.5503 40.2225 28.4488 40.2225 30.5073C40.2225 35.5307 36.1503 39.6031 31.1268 39.6031C29.0683 39.6031 27.1698 38.9189 25.6455 37.7661C27.306 39.9616 29.9394 41.3806 32.9043 41.3806C37.9277 41.3806 42.0001 37.3083 42.0001 32.2848C42.0002 29.3198 40.5812 26.6865 38.3856 25.026Z' fill='#FFD064'/>
                <Path d='M37 34.9507C35.9123 36.2054 34.2996 37 32.5 37C30.7004 37 29.0877 36.2054 28 34.9507C28.7294 33.9438 29.739 33.1685 30.9049 32.7202H30.9052C31.0131 32.6787 31.0415 32.5401 30.9582 32.4599L30.7564 32.2661C29.5286 31.0862 29.828 29.0606 31.3457 28.2793C31.7076 28.0933 32.1038 28 32.5 28C32.8962 28 33.2924 28.0933 33.6543 28.2793C34.5349 28.7327 35.0054 29.6048 35.0054 30.4876C35.0054 31.1264 34.7591 31.7707 34.2436 32.2661L34.0418 32.4599C33.9585 32.5401 33.9869 32.6787 34.0948 32.7202H34.0951C35.261 33.1685 36.2706 33.9438 37 34.9507Z' fill='#2F3D47'/>
            </Svg>
        );

    case 'basket-coins-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 48 48' fill='none'>
                <Path d='M9.16943 32.4634L12.526 47.1929C12.6335 47.6649 13.0533 47.9999 13.5375 47.9999H34.4633C34.9474 47.9999 35.3672 47.665 35.4748 47.1929L38.8314 32.4634H9.16943ZM17.7004 36.1162L17.1693 44.7272C17.1581 44.9095 17.0068 45.0518 16.8241 45.0518H15.4889C15.3062 45.0518 15.155 44.9096 15.1437 44.7272L14.6126 36.1162C14.6004 35.9172 14.7584 35.749 14.9578 35.749H17.3552C17.5546 35.749 17.7127 35.9172 17.7004 36.1162ZM22.9296 36.1162L22.3985 44.7272C22.3872 44.9095 22.236 45.0518 22.0533 45.0518H20.7181C20.5354 45.0518 20.3842 44.9096 20.3729 44.7272L19.8418 36.1162C19.8296 35.9172 19.9876 35.749 20.187 35.749H22.5844C22.7838 35.749 22.9419 35.9172 22.9296 36.1162ZM28.1588 36.1162L27.6277 44.7272C27.6164 44.9095 27.4652 45.0518 27.2825 45.0518H25.9473C25.7646 45.0518 25.6134 44.9096 25.6021 44.7272L25.071 36.1162C25.0587 35.9172 25.2168 35.749 25.4162 35.749H27.8136C28.0129 35.749 28.171 35.9172 28.1588 36.1162ZM33.3879 36.1162L32.8568 44.7272C32.8455 44.9095 32.6943 45.0518 32.5116 45.0518H31.1764C30.9937 45.0518 30.8425 44.9096 30.8312 44.7272L30.3001 36.1162C30.2878 35.9172 30.4459 35.749 30.6453 35.749H33.0427C33.2421 35.749 33.4001 35.9172 33.3879 36.1162Z' fill='#B3E59F'/>
                <Path d='M29.5991 29.3774L34.7164 24.2602L36.7155 26.2594L31.5983 31.3766L29.5991 29.3774Z' fill='#E4F6FF'/>
                <Path d='M30.7974 30.5762L35.9146 25.4589L36.7155 26.2598L31.5982 31.377L30.7974 30.5762Z' fill='#D3EFFB'/>
                <Path d='M37.1597 20.8319L34.1483 23.8434C33.8008 24.1908 33.8008 24.7542 34.1483 25.1017L35.858 26.8114C36.2054 27.1589 36.7688 27.1589 37.1163 26.8114L40.1277 23.8C40.9473 22.9804 40.9473 21.6516 40.1277 20.832C39.3081 20.0124 37.9793 20.0124 37.1597 20.8319ZM38.5122 21.7498C38.9133 21.7498 39.2384 22.0749 39.2384 22.4759C39.2384 22.877 38.9133 23.2021 38.5122 23.2021C38.1112 23.2021 37.786 22.877 37.786 22.4759C37.786 22.0749 38.1112 21.7498 38.5122 21.7498Z' fill='#365E7D'/>
                <Path d='M37.1158 26.8113L40.1272 23.7999C40.7327 23.1944 40.8902 22.3112 40.6012 21.5605C40.4989 21.826 40.3413 22.075 40.1272 22.2893L36.8779 25.5385C36.6619 25.7546 36.3115 25.7546 36.0954 25.5385L34.274 23.7172L34.1479 23.8433C33.8003 24.1907 33.8003 24.7541 34.1479 25.1016L35.8576 26.8113C36.205 27.1587 36.7684 27.1587 37.1158 26.8113Z' fill='#2B4D66'/>
                <Path d='M10.9072 26.1367L12.9064 24.1375L18.0236 29.2548L16.0245 31.254L10.9072 26.1367Z' fill='#E4F6FF'/>
                <Path d='M12.1484 24.8965L12.9066 24.1383L18.0239 29.2555L17.2657 30.0137L12.1484 24.8965Z' fill='#D3EFFB'/>
                <Path d='M7.62688 20.832C6.80732 21.6515 6.80732 22.9804 7.62688 23.7999L10.6383 26.8114C10.9858 27.1588 11.5491 27.1588 11.8966 26.8114L13.6063 25.1016C13.9538 24.7542 13.9538 24.1909 13.6063 23.8433L10.5949 20.8319C9.77535 20.0124 8.44653 20.0124 7.62688 20.832ZM9.96856 22.4761C9.96856 22.8771 9.64344 23.2023 9.24238 23.2023C8.84132 23.2023 8.51619 22.8771 8.51619 22.4761C8.51619 22.075 8.84132 21.7499 9.24238 21.7499C9.64344 21.7498 9.96856 22.0749 9.96856 22.4761Z' fill='#365E7D'/>
                <Path d='M13.6061 23.8434L10.5947 20.832C9.98374 20.221 9.09002 20.0662 8.33496 20.3661C8.59277 20.4684 8.83455 20.6233 9.04324 20.832L12.0547 23.8434C12.4021 24.1909 12.4021 24.7542 12.0547 25.1016L10.4916 26.6647L10.6382 26.8114C10.9856 27.1588 11.549 27.1588 11.8965 26.8114L13.6062 25.1016C13.9536 24.7542 13.9536 24.1909 13.6061 23.8434Z' fill='#2B4D66'/>
                <Path d='M36.7585 32.4634L33.7866 45.5048C33.6791 45.9769 33.2593 46.3118 32.7751 46.3118H12.3252L12.526 47.1929C12.6335 47.6649 13.0534 47.9999 13.5376 47.9999H34.4633C34.9474 47.9999 35.3673 47.665 35.4749 47.1929L38.8314 32.4634H36.7585Z' fill='#95D6A4'/>
                <Path d='M40.0789 33.2392H38.6544L37.6423 32.7313L36.5778 33.2392H7.92139C7.40361 33.2392 6.98389 32.8194 6.98389 32.3017V29.6934C6.98389 29.1756 7.40361 28.7559 7.92139 28.7559H40.0788C40.5966 28.7559 41.0163 29.1756 41.0163 29.6934V32.3017C41.0164 32.8194 40.5967 33.2392 40.0789 33.2392Z' fill='#B3E59F'/>
                <Path d='M40.0794 28.7559H39.4348V31.3765C39.4348 31.5317 39.309 31.6575 39.1537 31.6575H6.98389V32.3021C6.98389 32.8196 7.40342 33.2391 7.92082 33.2391H40.0794C40.5969 33.2391 41.0163 32.8195 41.0163 32.3021V29.6928C41.0164 29.1754 40.5969 28.7559 40.0794 28.7559Z' fill='#95D6A4'/>
                <Path d='M15.9123 12.4057C19.1375 12.4057 21.7519 9.79126 21.7519 6.56615C21.7519 3.34103 19.1375 0.726562 15.9123 0.726562C12.6872 0.726562 10.0728 3.34103 10.0728 6.56615C10.0728 9.79126 12.6872 12.4057 15.9123 12.4057Z' fill='#FFD064'/>
                <Path d='M18.6466 1.40625C19.0799 2.22216 19.3262 3.15234 19.3262 4.14047C19.3262 7.36556 16.7117 9.98006 13.4867 9.98006C12.4985 9.98006 11.5683 9.73369 10.7524 9.30047C11.7332 11.1474 13.6755 12.4058 15.9124 12.4058C19.1375 12.4058 21.752 9.79134 21.752 6.56625C21.752 4.32928 20.4937 2.38697 18.6466 1.40625Z' fill='#FFC250'/>
                <Path d='M15.9123 13.1322C12.2917 13.1322 9.34619 10.1867 9.34619 6.56606C9.34619 2.94553 12.2917 0 15.9123 0C19.5328 0 22.4784 2.94553 22.4784 6.56606C22.4784 10.1867 19.5328 13.1322 15.9123 13.1322ZM15.9123 1.45294C13.0929 1.45294 10.7992 3.74672 10.7992 6.56606C10.7992 9.3855 13.093 11.6792 15.9123 11.6792C18.7317 11.6792 21.0254 9.38541 21.0254 6.56606C21.0254 3.74672 18.7317 1.45294 15.9123 1.45294Z' fill='#FFE07D'/>
                <Path d='M16.1673 3.82443L16.7465 4.99799C16.7879 5.0819 16.8679 5.14002 16.9605 5.15343L18.2556 5.34159C18.4886 5.37543 18.5817 5.66193 18.4131 5.82637L17.476 6.73987C17.409 6.80512 17.3784 6.89924 17.3942 6.9914L17.6155 8.28131C17.6553 8.51343 17.4116 8.69052 17.2031 8.58093L16.0447 7.97193C15.9619 7.92843 15.863 7.92843 15.7802 7.97193L14.6218 8.58093C14.4133 8.69052 14.1697 8.51352 14.2094 8.28131L14.4307 6.9914C14.4465 6.89924 14.416 6.80512 14.3489 6.73987L13.4118 5.82637C13.2431 5.66193 13.3362 5.37543 13.5693 5.34159L14.8644 5.15343C14.9569 5.14002 15.037 5.0818 15.0784 4.99799L15.6576 3.82443C15.7618 3.61321 16.0631 3.61321 16.1673 3.82443Z' fill='#F4FBFF'/>
                <Path d='M23.9601 24.2201C28.7043 24.2201 32.5502 20.3742 32.5502 15.6301C32.5502 10.8859 28.7043 7.04004 23.9601 7.04004C19.216 7.04004 15.3701 10.8859 15.3701 15.6301C15.3701 20.3742 19.216 24.2201 23.9601 24.2201Z' fill='#FFD064'/>
                <Path d='M28.3594 8.25146C29.1285 9.53837 29.5709 11.0431 29.5709 12.6512C29.5709 17.3954 25.725 21.2413 20.9808 21.2413C19.3726 21.2413 17.868 20.7989 16.5811 20.0298C18.0807 22.5394 20.8237 24.2201 23.9597 24.2201C28.7039 24.2201 32.5498 20.3742 32.5498 15.6301C32.5498 12.4941 30.869 9.75109 28.3594 8.25146Z' fill='#FFC250'/>
                <Path d='M23.9602 25.8558C18.3217 25.8558 13.7344 21.2685 13.7344 15.6301C13.7344 9.99167 18.3217 5.4043 23.9602 5.4043C29.5986 5.4043 34.1858 9.99158 34.1858 15.63C34.1858 21.2684 29.5986 25.8558 23.9602 25.8558ZM23.9602 7.96714C19.7348 7.96714 16.2972 11.4047 16.2972 15.6301C16.2972 19.8555 19.7348 23.293 23.9602 23.293C28.1855 23.293 31.623 19.8555 31.623 15.6301C31.623 11.4047 28.1855 7.96714 23.9602 7.96714Z' fill='#FFE07D'/>
                <Path d='M30.4505 7.73389C31.9054 9.5007 32.7801 11.7622 32.7801 14.2243C32.7801 19.8628 28.1928 24.45 22.5544 24.45C20.0923 24.45 17.8308 23.5752 16.064 22.1204C17.9409 24.3998 20.7837 25.8558 23.9602 25.8558C29.5986 25.8558 34.1858 21.2685 34.1858 15.6301C34.1858 12.4536 32.7299 9.61095 30.4505 7.73389Z' fill='#FFD064'/>
                <Path d='M24.3349 11.5973L25.1869 13.3237C25.2478 13.447 25.3655 13.5325 25.5017 13.5523L27.4068 13.8292C27.7497 13.8789 27.8866 14.3003 27.6385 14.5422L26.2599 15.8859C26.1614 15.9819 26.1164 16.1204 26.1397 16.256L26.4651 18.1534C26.5237 18.4949 26.1652 18.7553 25.8585 18.5941L24.1545 17.6982C24.0327 17.6342 23.8872 17.6342 23.7654 17.6982L22.0614 18.5941C21.7548 18.7553 21.3963 18.4949 21.4549 18.1534L21.7803 16.256C21.8035 16.1203 21.7586 15.9819 21.66 15.8859L20.2815 14.5422C20.0333 14.3003 20.1703 13.8789 20.5132 13.8292L22.4183 13.5523C22.5544 13.5325 22.6721 13.447 22.7331 13.3237L23.5851 11.5973C23.7384 11.2866 24.1816 11.2866 24.3349 11.5973Z' fill='#F4FBFF'/>
            </Svg>
        );

    case 'cart-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 47 40' fill='none'>
                <Path d='M45.0118 34.8315C45.0118 35.6107 44.3948 36.2434 43.6351 36.2434H16.6005C14.2427 36.2434 12.2582 34.3545 12.0818 31.9436L10.08 4.61117C10.0749 4.54217 10.0178 4.4877 9.95048 4.4877H6.95537C6.1946 4.4877 5.57861 3.85592 5.57861 3.07564C5.57861 2.29639 6.1946 1.66357 6.95537 1.66357H9.95048C11.4506 1.66357 12.7131 2.86535 12.8253 4.39976L14.8272 31.7322C14.8966 32.6777 15.6756 33.4193 16.6006 33.4193H43.6351C44.3948 33.4194 45.0118 34.0512 45.0118 34.8315Z' fill='#655E67'/>
                <Path d='M2.06468 5.19345H6.87055C7.44732 5.19345 7.91487 4.71392 7.91487 4.12235V2.0291C7.91487 1.43754 7.44732 0.958008 6.87055 0.958008H2.06468C0.924391 0.958102 0 1.9062 0 3.07573C0 4.24535 0.924391 5.19345 2.06468 5.19345Z' fill='#EEC06B'/>
                <Path d='M22.5326 39.0418C24.7999 39.0418 26.6379 37.1566 26.6379 34.8312C26.6379 32.5058 24.7999 30.6206 22.5326 30.6206C20.2653 30.6206 18.4272 32.5058 18.4272 34.8312C18.4272 37.1566 20.2653 39.0418 22.5326 39.0418Z' fill='#EEC06B'/>
                <Path d='M38.0555 39.0418C40.3228 39.0418 42.1608 37.1566 42.1608 34.8312C42.1608 32.5058 40.3228 30.6206 38.0555 30.6206C35.7882 30.6206 33.9502 32.5058 33.9502 34.8312C33.9502 37.1566 35.7882 39.0418 38.0555 39.0418Z' fill='#EEC06B'/>
                <Path d='M22.5326 36.4C23.3774 36.4 24.0623 35.6976 24.0623 34.8311C24.0623 33.9646 23.3774 33.2622 22.5326 33.2622C21.6878 33.2622 21.0029 33.9646 21.0029 34.8311C21.0029 35.6976 21.6878 36.4 22.5326 36.4Z' fill='#655E67'/>
                <Path d='M38.0556 36.4C38.9004 36.4 39.5852 35.6976 39.5852 34.8311C39.5852 33.9646 38.9004 33.2622 38.0556 33.2622C37.2107 33.2622 36.5259 33.9646 36.5259 34.8311C36.5259 35.6976 37.2107 36.4 38.0556 36.4Z' fill='#655E67'/>
                <Path d='M45.1538 7.31152H13.0386L14.4745 26.9155H37.5408C39.7737 26.9155 41.7507 25.4355 42.4314 23.2545L46.7204 9.51296C47.0602 8.42424 46.2683 7.31152 45.1538 7.31152ZM23.4736 19.9776C23.4736 20.784 22.8362 21.4378 22.0499 21.4378C21.2637 21.4378 20.6263 20.784 20.6263 19.9776V14.2494C20.6263 13.443 21.2637 12.7892 22.0499 12.7892C22.8362 12.7892 23.4736 13.443 23.4736 14.2494V19.9776ZM30.0033 19.9776C30.0033 20.784 29.3659 21.4378 28.5796 21.4378C27.7934 21.4378 27.156 20.784 27.156 19.9776V14.2494C27.156 13.443 27.7934 12.7892 28.5796 12.7892C29.3659 12.7892 30.0033 13.443 30.0033 14.2494V19.9776ZM36.5329 19.9776C36.5329 20.784 35.8955 21.4378 35.1092 21.4378C34.323 21.4378 33.6856 20.784 33.6856 19.9776V14.2494C33.6856 13.443 34.323 12.7892 35.1092 12.7892C35.8955 12.7892 36.5329 13.443 36.5329 14.2494V19.9776Z' fill='#80B6FC'/>
                <Path d='M45.1541 7.31152H42.2175C43.332 7.31152 44.1238 8.42434 43.7841 9.51296L39.4951 23.2545C38.8143 25.4355 36.8374 26.9155 34.6045 26.9155H37.5411C39.774 26.9155 41.751 25.4355 42.4317 23.2545L46.7207 9.51296C47.0605 8.42424 46.2686 7.31152 45.1541 7.31152Z' fill='#5392F9'/>
            </Svg>
        );
    case 'pie-chart-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 42 42'>
                <Path d='M21 0C9.40201 0 0 9.40201 0 21C0 32.598 9.40201 42 21 42C32.598 42 42 32.598 42 21C42 9.40201 32.598 0 21 0ZM21 34.125C13.7512 34.125 7.875 28.2487 7.875 21C7.875 13.7512 13.7512 7.875 21 7.875C28.2487 7.875 34.125 13.7512 34.125 21C34.125 28.2487 28.2487 34.125 21 34.125Z' fill='#7CC394'/>
                <Path d='M35.6303 21C35.6303 12.9199 29.0801 6.36981 21.0001 6.36981C12.9201 6.36981 6.3699 12.9199 6.3699 21C6.3699 29.0801 12.92 35.6302 21.0001 35.6302C29.0802 35.6302 35.6303 29.0801 35.6303 21ZM7.87509 21C7.87509 13.7512 13.7514 7.875 21.0001 7.875C28.2488 7.875 34.1251 13.7512 34.1251 21C34.1251 28.2487 28.2488 34.125 21.0001 34.125C13.7514 34.125 7.87509 28.2487 7.87509 21ZM42.0001 21C42.0001 32.598 32.5981 42 21.0001 42C20.6976 42 20.3971 41.9921 20.0978 41.9795C31.277 41.5067 40.1954 32.2955 40.1954 21C40.1954 9.70446 31.277 0.493336 20.0978 0.0205078C20.3971 0.007875 20.6976 0 21.0001 0C32.5981 0 42.0001 9.40201 42.0001 21Z' fill='#5BA473'/>
                <Path d='M26.173 17.6902L17.5671 26.0499C17.3269 26.2902 17.0119 26.4104 16.6971 26.4104C16.3823 26.4104 16.0673 26.2903 15.8271 26.0499C15.3465 25.5694 15.3465 24.7903 15.8271 24.3098L24.433 15.9501C24.9134 15.4695 25.6926 15.4695 26.1731 15.9501C26.6536 16.4306 26.6536 17.2097 26.173 17.6902ZM17.7188 18.5391C18.6234 18.5391 19.3594 17.8031 19.3594 16.8984C19.3594 15.9938 18.6234 15.2578 17.7188 15.2578C16.8142 15.2578 16.0782 15.9938 16.0782 16.8984C16.0782 17.8031 16.8142 18.5391 17.7188 18.5391ZM24.2813 23.4609C23.3767 23.4609 22.6407 24.1969 22.6407 25.1016C22.6407 26.0062 23.3767 26.7422 24.2813 26.7422C25.186 26.7422 25.9219 26.0062 25.9219 25.1016C25.9219 24.1969 25.186 23.4609 24.2813 23.4609Z' fill='#FF733D'/>
                <Path d='M42 21H32.887C32.6655 21 32.4852 20.824 32.4776 20.6026C32.3824 17.802 31.2843 15.2558 29.5313 13.3117C27.5119 11.0723 24.6233 9.63195 21.397 9.52235C21.1758 9.5148 21 9.33442 21 9.1131V0C32.598 0 42 9.40201 42 21Z' fill='#FF733D'/>
                <Path d='M40.1953 21H34.125C34.125 13.7512 28.2487 7.87499 21 7.87499V0.0780029C31.7529 0.993472 40.1953 10.0101 40.1953 21ZM41.9948 20.5898C41.9975 20.7263 42 20.8628 42 21C42 20.8628 41.9975 20.7263 41.9948 20.5898Z' fill='#FF904E'/>
            </Svg>
        );
    case 'user-case-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 42 42'>
                <Path d='M25.8167 22.0902L21.9567 20.5716H8.25543L4.39545 22.0902C2.91536 22.6725 1.96609 24.1278 2.02975 25.717L2.49839 37.4172C2.52514 38.0851 3.07442 38.6129 3.74297 38.6129H26.4692C27.1378 38.6129 27.6871 38.0852 27.7138 37.4172L28.1825 25.717C28.246 24.1278 27.2968 22.6725 25.8167 22.0902Z' fill='#C9C6CA'/>
                <Path d='M14.1967 24.9116L13.3999 38.6129H16.8124L16.0156 24.9116H14.1967Z' fill='#5675E5'/>
                <Path d='M15.6742 25.5344H14.5379C14.1638 25.5344 13.8605 25.2311 13.8605 24.8569V23.0432H16.3517V24.8569C16.3517 25.2311 16.0483 25.5344 15.6742 25.5344Z' fill='#2C459F'/>
                <Path d='M19.466 15.5695H10.7469V23.0434H19.466V15.5695Z' fill='#E6AF78'/>
                <Path d='M10.7466 17.8954C11.9817 18.7848 13.49 19.3065 15.1061 19.3065C16.7223 19.3065 18.2306 18.7848 19.4657 17.8954V15.5698H10.7466V17.8954Z' fill='#D29B6E'/>
                <Path d='M15.1061 0.0196223C11.9967 0.187704 10.2276 1.88797 10.2276 1.88797C10.2276 1.88797 7.63266 1.88797 7.63266 5.00187C7.63266 7.493 8.25544 9.9646 8.25544 9.9646H22.5796C23.8251 3.11401 20.8668 -0.291768 15.1061 0.0196223Z' fill='#6E4B53'/>
                <Path d='M11.3693 7.39461C9.08147 4.53418 10.2275 1.88793 10.2275 1.88793C10.2275 1.88793 7.63255 1.88793 7.63255 5.00184C7.63255 7.49296 8.25533 9.96457 8.25533 9.96457H11.3692L11.3693 7.39461Z' fill='#5C414B'/>
                <Path d='M21.9567 9.34187H21.3339L20.5133 7.84415C20.3837 7.60765 20.1139 7.48649 19.8486 7.53505C15.747 8.28531 13.0514 7.33194 12.03 6.85468C11.7625 6.72967 11.4439 6.81187 11.272 7.05197C10.6417 7.93225 9.94628 8.62607 9.52924 9.00997C9.29774 9.22317 8.99718 9.34171 8.68243 9.34171H8.23429C8.23396 9.34171 8.23372 9.34187 8.23331 9.34187C8.16506 9.3431 8.09533 9.35032 8.02437 9.36402C7.41824 9.48116 7.00981 10.0606 7.00981 10.6779V11.1293C7.00981 11.725 7.39126 12.2869 7.9713 12.4223C8.10198 12.4528 8.22912 12.4614 8.35086 12.4518C8.66053 12.4271 8.93082 12.6524 8.9866 12.958C9.51603 15.8597 12.0513 18.0606 15.106 18.0606C18.1608 18.0606 20.696 15.8597 21.2255 12.958C21.2792 12.6636 21.5333 12.4493 21.8284 12.4555H21.9567C22.6446 12.4555 23.2023 11.8979 23.2023 11.21V10.5872C23.2024 9.89952 22.6446 9.34187 21.9567 9.34187Z' fill='#F0C087'/>
                <Path d='M11.3694 11.833V6.95699C11.3355 6.98693 11.2993 7.01392 11.272 7.05207C10.6417 7.93235 9.94628 8.62617 9.52924 9.01007C9.29774 9.22327 8.99718 9.34181 8.68243 9.34181H8.23429C8.23396 9.34181 8.23372 9.34197 8.23331 9.34197C8.16506 9.3432 8.09533 9.35042 8.02437 9.36412C7.41824 9.48126 7.00981 10.0607 7.00981 10.678V11.1293C7.00981 11.7251 7.39126 12.287 7.9713 12.4224C8.10198 12.4529 8.22912 12.4615 8.35086 12.4519C8.66053 12.4272 8.93082 12.6525 8.9866 12.9581C9.51603 15.8598 12.0513 18.0607 15.106 18.0607C15.5295 18.0607 15.9415 18.0146 16.3409 17.9339C13.5036 17.3529 11.3694 14.8422 11.3694 11.833Z' fill='#E6AF78'/>
                <Path d='M15.1061 23.0628L10.5623 19.0869C10.3155 18.871 9.9436 18.8834 9.71178 19.1152L8.2554 20.5716L11.1494 24.9126C11.5854 25.5666 12.5107 25.6582 13.0665 25.1024L15.1061 23.0628Z' fill='#DBD9DC'/>
                <Path d='M15.1061 23.0628L19.6499 19.0869C19.8967 18.871 20.2686 18.8834 20.5004 19.1152L21.9568 20.5716L19.0628 24.9126C18.6268 25.5666 17.7015 25.6582 17.1457 25.1024L15.1061 23.0628Z' fill='#DBD9DC'/>
                <Path d='M5.29244 26.3278L2.58295 23.6183C2.20495 24.2351 1.9993 24.9592 2.02965 25.717L2.49829 37.4172C2.52503 38.0851 3.0744 38.6129 3.74287 38.6129H6.38698V28.97C6.38698 27.9791 5.99323 27.0286 5.29244 26.3278Z' fill='#DBD9DC'/>
                <Path d='M27.1023 25.0645C27.1023 24.6911 27.406 24.3871 27.7797 24.3871H31.8442C32.2179 24.3871 32.5216 24.6911 32.5216 25.0645V27.7741H33.8764V25.0645C33.8764 23.9439 32.9648 23.0322 31.8442 23.0322H27.7797C26.6591 23.0322 25.7475 23.9439 25.7475 25.0645V27.7741H27.1023V25.0645Z' fill='#2C459F'/>
                <Path d='M20.3281 30.4839V40.0367C20.3281 41.121 21.2072 42 22.2915 42H37.3325C38.4168 42 39.2959 41.121 39.2959 40.0367V30.4839H20.3281Z' fill='#9e8a6a'/>
                <Path d='M29.8119 35.9032C30.9997 35.9032 32.1397 35.8051 33.1992 35.6248L35.9082 34.9253C38.3766 34.0327 39.9732 32.6115 39.9732 31.0108V29.129C39.9732 28.0067 39.0633 27.0968 37.941 27.0968H21.6829C20.5605 27.0968 19.6506 28.0067 19.6506 29.129V31.0108C19.6506 32.6115 21.2473 34.0327 23.7157 34.9253L26.4246 35.6248C27.484 35.8051 28.6242 35.9032 29.8119 35.9032Z' fill='#5675E6'/>
                <Path d='M25.07 37.2581C24.3217 37.2581 23.7151 36.6515 23.7151 35.9033V34.5484C23.7151 34.1743 24.0184 33.871 24.3926 33.871H25.7474C26.1215 33.871 26.4248 34.1743 26.4248 34.5484V35.9033C26.4248 36.6515 25.8183 37.2581 25.07 37.2581Z' fill='#C9C6CA'/>
                <Path d='M34.5539 37.2581C33.8057 37.2581 33.1991 36.6515 33.1991 35.9033V34.5484C33.1991 34.1743 33.5024 33.871 33.8765 33.871H35.2314C35.6055 33.871 35.9088 34.1743 35.9088 34.5484V35.9033C35.9088 36.6515 35.3022 37.2581 34.5539 37.2581Z' fill='#C9C6CA'/>
                <Path d='M25.0703 36.5809C25.4445 36.5809 25.7477 36.2776 25.7477 35.9034C25.7477 35.5293 25.4445 35.226 25.0703 35.226C24.6962 35.226 24.3929 35.5293 24.3929 35.9034C24.3929 36.2776 24.6962 36.5809 25.0703 36.5809Z' fill='#DBD9DC'/>
                <Path d='M34.5538 36.5809C34.928 36.5809 35.2313 36.2776 35.2313 35.9034C35.2313 35.5293 34.928 35.226 34.5538 35.226C34.1797 35.226 33.8764 35.5293 33.8764 35.9034C33.8764 36.2776 34.1797 36.5809 34.5538 36.5809Z' fill='#DBD9DC'/>
            </Svg>
        );

    case 'cool-smile-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 32 32'>
                <Path d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z' fill='#FFE17D'/>
                <Path d='M9.80613 19.6125C12.3715 19.6125 14.4511 17.764 14.4511 15.4837C14.4511 13.2035 12.3715 11.355 9.80613 11.355C7.24077 11.355 5.16113 13.2035 5.16113 15.4837C5.16113 17.764 7.24077 19.6125 9.80613 19.6125Z' fill='#FFD164'/>
                <Path d='M22.1938 19.6125C24.7592 19.6125 26.8388 17.764 26.8388 15.4837C26.8388 13.2035 24.7592 11.355 22.1938 11.355C19.6285 11.355 17.5488 13.2035 17.5488 15.4837C17.5488 17.764 19.6285 19.6125 22.1938 19.6125Z' fill='#FFD164'/>
                <Path d='M18.3226 29.6774C9.486 29.6774 2.32256 22.5139 2.32256 13.6774C2.32256 9.85069 3.66763 6.33919 5.90856 3.58594C2.30394 6.51969 0 10.9901 0 15.9999C0 24.8365 7.16344 31.9999 16 31.9999C21.0099 31.9999 25.4803 29.6959 28.4141 26.0913C25.6608 28.3322 22.1493 29.6774 18.3226 29.6774Z' fill='#FFD164'/>
                <Path d='M14.331 14.2826L13.4893 13.6853C14.0664 12.8723 15.0049 12.3872 16.0009 12.3872C16.9883 12.3872 17.9228 12.866 18.5004 13.6682L17.6627 14.2715C17.2786 13.738 16.6571 13.4195 16.0009 13.4195C15.3391 13.4195 14.7146 13.7423 14.331 14.2826Z' fill='#5A4650'/>
                <Path d='M9.80628 10.3223C9.22359 10.3223 8.66603 10.4188 8.15191 10.594C7.70284 10.7471 7.23584 10.8384 6.76134 10.8384H4.64503C4.35997 10.8384 4.12891 11.0695 4.12891 11.3545V12.3504C4.12891 12.6955 4.30141 13.0179 4.58859 13.2093L4.71222 13.2918C5.01103 13.491 5.19697 13.8317 5.17072 14.1899C5.16441 14.2763 5.16116 14.3634 5.16116 14.4513C5.16116 16.728 7.24484 18.5804 9.80634 18.5804C12.3678 18.5804 14.4515 16.7281 14.4515 14.4513C14.4515 12.1746 12.3678 10.3223 9.80628 10.3223Z' fill='#6E5A64'/>
                <Path d='M16.0018 24.9034C13.3476 24.9034 10.8385 23.6476 9.29012 21.544C9.07893 21.257 9.14043 20.853 9.42724 20.6419C9.71456 20.4304 10.1182 20.4919 10.3294 20.779C11.6354 22.5537 13.7559 23.6132 16.0018 23.6132C18.2477 23.6132 20.3682 22.5537 21.6737 20.7792C21.8849 20.4919 22.2881 20.4307 22.5759 20.6419C22.8627 20.853 22.9242 21.2568 22.713 21.5438C21.1651 23.6474 18.6566 24.9034 16.0018 24.9034Z' fill='#AA7346'/>
                <Path d='M11.3547 9.41928H8.25798C7.90161 9.41928 7.61279 9.13047 7.61279 8.77409C7.61279 8.41772 7.90161 8.12891 8.25798 8.12891H11.3547C11.7111 8.12891 11.9999 8.41772 11.9999 8.77409C11.9999 9.13047 11.7111 9.41928 11.3547 9.41928Z' fill='#AA7346'/>
                <Path d='M23.7419 9.41928H20.6452C20.2888 9.41928 20 9.13047 20 8.77409C20 8.41772 20.2888 8.12891 20.6452 8.12891H23.7419C24.0983 8.12891 24.3871 8.41772 24.3871 8.77409C24.3871 9.13047 24.0983 9.41928 23.7419 9.41928Z' fill='#AA7346'/>
                <Path d='M9.80623 17.548C7.8143 17.548 6.19336 16.1589 6.19336 14.4512C6.19336 12.7436 7.8143 11.3545 9.80623 11.3545C11.7982 11.3545 13.4191 12.7436 13.4191 14.4512C13.4192 16.1589 11.7982 17.548 9.80623 17.548Z' fill='#5A4650'/>
                <Path d='M9.80643 12.9029C11.1634 12.9029 12.3961 13.2212 13.3184 13.7369C12.9407 12.3731 11.5112 11.3545 9.80643 11.3545C8.10175 11.3545 6.67225 12.3731 6.29443 13.7368C7.21681 13.2211 8.4495 12.9029 9.80643 12.9029Z' fill='#84737B'/>
                <Path d='M17.5483 14.4513C17.5483 16.728 19.632 18.5804 22.1935 18.5804C24.755 18.5804 26.8387 16.7281 26.8387 14.4513C26.8387 14.3635 26.8355 14.2763 26.8292 14.1899C26.803 13.8318 26.9889 13.491 27.2877 13.2918L27.4113 13.2093C27.6985 13.0179 27.871 12.6955 27.871 12.3504V11.3545C27.871 11.0695 27.6399 10.8384 27.3548 10.8384H25.2385C24.764 10.8384 24.297 10.7471 23.8479 10.594C23.3338 10.4188 22.7763 10.3223 22.1935 10.3223C19.632 10.3223 17.5483 12.1746 17.5483 14.4513Z' fill='#6E5A64'/>
                <Path d='M18.5806 14.4512C18.5806 12.7436 20.2015 11.3545 22.1934 11.3545C24.1854 11.3545 25.8063 12.7436 25.8063 14.4512C25.8063 16.1589 24.1854 17.548 22.1934 17.548C20.2015 17.548 18.5806 16.1589 18.5806 14.4512Z' fill='#5A4650'/>
                <Path d='M22.1936 12.9029C20.8366 12.9029 19.604 13.2212 18.6816 13.7369C19.0594 12.3731 20.4889 11.3545 22.1936 11.3545C23.8983 11.3545 25.3278 12.3731 25.7056 13.7368C24.7833 13.2211 23.5506 12.9029 22.1936 12.9029Z' fill='#84737B'/>
            </Svg>
        );
    case 'sad-smile-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 32 32'>
                <Path d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z' fill='#FFE17D'/>
                <Path d='M10.5806 16.0001C9.86797 16.0001 9.29028 15.4224 9.29028 14.7098V13.6775C9.29028 12.9649 9.86797 12.3872 10.5806 12.3872C11.2932 12.3872 11.8709 12.9649 11.8709 13.6775V14.7098C11.8709 15.4224 11.2932 16.0001 10.5806 16.0001Z' fill='#7D5046'/>
                <Path d='M10.5806 12.3872C10.4922 12.3872 10.4059 12.3963 10.3225 12.4132V14.1936C10.3225 14.6212 10.6691 14.9678 11.0967 14.9678C11.5243 14.9678 11.8709 14.6212 11.8709 14.1936V13.6775C11.8709 12.9648 11.2933 12.3872 10.5806 12.3872Z' fill='#9C6846'/>
                <Path d='M21.4195 16.0001C20.7068 16.0001 20.1292 15.4224 20.1292 14.7098V13.6775C20.1292 12.9649 20.7068 12.3872 21.4195 12.3872C22.1321 12.3872 22.7098 12.9649 22.7098 13.6775V14.7098C22.7098 15.4224 22.1321 16.0001 21.4195 16.0001Z' fill='#7D5046'/>
                <Path d='M21.4194 12.3872C21.3311 12.3872 21.2448 12.3963 21.1614 12.4132V14.1936C21.1614 14.6212 21.508 14.9678 21.9356 14.9678C22.3631 14.9678 22.7098 14.6212 22.7098 14.1936V13.6775C22.7098 12.9648 22.1321 12.3872 21.4194 12.3872Z' fill='#9C6846'/>
                <Path d='M10.8367 23.871C10.6805 23.871 10.5237 23.8145 10.3997 23.7006C10.1376 23.4592 10.1205 23.0512 10.3619 22.7888C11.6714 21.3657 13.7787 20.5161 15.998 20.5161C18.2182 20.5161 20.3251 21.3659 21.634 22.789C21.8754 23.0512 21.8583 23.4594 21.5962 23.7006C21.3346 23.942 20.9258 23.9257 20.6844 23.6625C19.6153 22.5002 17.8633 21.8064 15.9979 21.8064C14.133 21.8064 12.381 22.5002 11.3114 23.6628C11.1845 23.801 11.0106 23.871 10.8367 23.871Z' fill='#AA7346'/>
                <Path d='M18.3226 29.6774C9.486 29.6774 2.32256 22.5139 2.32256 13.6774C2.32256 9.85069 3.66763 6.33919 5.90856 3.58594C2.30394 6.51969 0 10.9901 0 15.9999C0 24.8365 7.16344 31.9999 16 31.9999C21.0099 31.9999 25.4803 29.6959 28.4141 26.0913C25.6608 28.3323 22.1493 29.6774 18.3226 29.6774Z' fill='#FFD164'/>
            </Svg>
        );
    case 'medal-1-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 32 32'>
                <Path d='M13.09 26.262C12.8506 26.3627 12.6239 26.4446 12.3972 26.4824C12.3783 26.4887 12.3594 26.4887 12.3342 26.4887C12.2523 26.5013 12.1641 26.5076 12.0822 26.5076C12.057 26.5076 12.0256 26.5076 12.0004 26.5076C11.8996 26.5013 11.7988 26.4887 11.7043 26.4572C10.9359 26.2053 10.5202 25.2479 10.0857 24.341C10.0164 24.2024 9.94709 24.0638 9.87781 23.9253C9.63847 23.4718 9.38024 23.0624 9.04642 22.8167C8.92045 22.7286 8.78189 22.6593 8.62443 22.5963C8.59294 22.5837 8.55515 22.5711 8.52366 22.5585C8.3725 22.5018 8.20874 22.4577 8.03238 22.4199C8.01979 22.4199 8.00719 22.4137 7.99459 22.4137C7.81194 22.3759 7.62299 22.3444 7.42774 22.3129C7.42144 22.3129 7.41514 22.3129 7.40884 22.3129C7.16321 22.2751 6.91127 22.2436 6.66564 22.2058C6.55856 22.1869 6.45149 22.1743 6.34442 22.1554C6.27514 22.1428 6.20585 22.1302 6.13657 22.1176C6.12397 22.1176 6.11138 22.1113 6.09878 22.1113L5.71458 22.7601L2.68506 27.8554L8.14575 26.6147L9.67626 31.9998L12.5294 27.1941L13.09 26.262Z' fill='#FF7058'/>
                <Path d='M26.2724 22.7474L25.8882 22.105C25.8756 22.105 25.863 22.1113 25.8504 22.1113C25.7811 22.1239 25.7118 22.1365 25.6425 22.1491C25.5355 22.168 25.4284 22.1869 25.3213 22.1995C25.0757 22.2372 24.8238 22.2687 24.5781 22.3065C24.5718 22.3065 24.5655 22.3065 24.5592 22.3065C24.364 22.3317 24.175 22.3695 23.9861 22.4073C23.9735 22.4073 23.9609 22.4136 23.9483 22.4136C23.7782 22.4514 23.6145 22.4955 23.457 22.5522C23.4255 22.5648 23.3877 22.5774 23.3562 22.59C23.2051 22.6529 23.0602 22.7222 22.9342 22.8104C22.802 22.9112 22.676 23.0308 22.5626 23.1757C21.769 24.1709 21.41 26.0793 20.2763 26.4446C20.1818 26.4761 20.0811 26.4887 19.9803 26.495C19.9551 26.495 19.9236 26.495 19.8984 26.495C19.8165 26.495 19.7347 26.4887 19.6465 26.4761C19.6276 26.4761 19.6087 26.4698 19.5835 26.4698C19.3631 26.432 19.13 26.3564 18.897 26.2556L19.4449 27.1815L22.2981 31.9871L23.8223 26.602L29.283 27.8428L26.2724 22.7474Z' fill='#FF7058'/>
                <Path d='M27.2425 9.60295C26.8205 8.30548 28.0109 6.18293 27.2236 5.09961C26.43 4.00999 24.0366 4.48866 22.947 3.69507C21.8699 2.90777 21.5865 0.482892 20.289 0.0609001C19.0357 -0.348495 17.3855 1.44025 15.9998 1.44025C14.6142 1.44025 12.964 -0.342196 11.7106 0.0609001C10.4132 0.482892 10.1298 2.90777 9.05273 3.69507C7.96311 4.48866 5.56973 4.00999 4.77613 5.09961C3.98883 6.17663 5.17923 8.30548 4.75724 9.60295C4.35414 10.85 2.12451 11.8704 2.12451 13.256C2.12451 14.6417 4.34784 15.6557 4.75724 16.9091C5.17923 18.2065 3.98883 20.3291 4.77613 21.4124C5.56973 22.502 7.96311 22.0234 9.05273 22.817C10.1298 23.6043 10.4132 26.0291 11.7106 26.4511C12.964 26.8542 14.6142 25.0655 15.9998 25.0655C17.3855 25.0655 19.0357 26.8479 20.289 26.4448C21.5865 26.0228 21.8699 23.598 22.947 22.8107C24.0366 22.0171 26.43 22.4957 27.2236 21.4061C28.0109 20.3291 26.8205 18.2002 27.2425 16.9028C27.6456 15.6557 29.8752 14.6354 29.8752 13.2497C29.8689 11.8704 27.6456 10.85 27.2425 9.60295Z' fill='#FFDC84'/>
                <Path d='M11.711 26.445C10.4135 26.023 10.1301 23.5981 9.05308 22.8108C8.35396 22.3007 7.11948 22.3133 6.11174 22.0991L5.72754 22.7479C6.13064 22.8549 6.55893 22.9116 6.96832 22.962C7.61705 23.0502 8.29098 23.1384 8.63109 23.3903C8.96491 23.6359 9.25463 24.2406 9.53176 24.82C9.97265 25.7459 10.4765 26.7977 11.4843 27.1252C11.6795 27.1882 11.8874 27.2197 12.1015 27.2197C12.2464 27.2197 12.3912 27.2071 12.5361 27.1819L13.084 26.2561C12.6054 26.4702 12.133 26.5836 11.711 26.445Z' fill='#F1543F'/>
                <Path d='M22.9474 22.8108C21.8704 23.5981 21.587 26.023 20.2895 26.445C19.8675 26.5835 19.4014 26.4638 18.9102 26.256L19.4581 27.1819C19.603 27.2071 19.7478 27.2197 19.8927 27.2197C20.1068 27.2197 20.3147 27.1882 20.5099 27.1252C21.5177 26.7977 22.0216 25.7458 22.4624 24.82C22.7396 24.2342 23.0293 23.6296 23.3631 23.3839C23.7032 23.1383 24.3772 23.0501 25.0259 22.9619C25.4353 22.9053 25.8636 22.8486 26.273 22.7415L25.8888 22.0928C24.881 22.3132 23.6465 22.3069 22.9474 22.8108Z' fill='#F1543F'/>
                <Path d='M24.3012 13.2559C24.3012 17.7151 20.7867 21.3493 16.3842 21.5445C16.2582 21.5508 16.1259 21.5571 16 21.5571C11.4777 21.5571 7.79946 17.9419 7.69868 13.4448V13.407C7.69238 13.3629 7.69238 13.3062 7.69238 13.2559C7.69238 8.67064 11.4147 4.9546 16 4.9546C20.5852 4.9483 24.3012 8.66434 24.3012 13.2559Z' fill='#bda57f'/>
                <Path d='M17.7145 18.2857H15.4083V10.6845L13.1431 11.3697V9.56122L17.503 8H17.7145V18.2857Z' fill='white'/>
            </Svg>
        );
    case 'medal-2-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 32 32'>
                <Path d='M13.09 26.261C12.8506 26.3617 12.6239 26.4436 12.3972 26.4814C12.3783 26.4877 12.3594 26.4877 12.3342 26.4877C12.2523 26.5003 12.1641 26.5066 12.0822 26.5066C12.057 26.5066 12.0256 26.5066 12.0004 26.5066C11.8996 26.5003 11.7988 26.4877 11.7043 26.4562C10.9359 26.2043 10.5202 25.2469 10.0857 24.34C10.0164 24.2014 9.94709 24.0628 9.87781 23.9243C9.63847 23.4708 9.38024 23.0614 9.04642 22.8158C8.92045 22.7276 8.78189 22.6583 8.62443 22.5953C8.59294 22.5827 8.55515 22.5701 8.52366 22.5575C8.3725 22.5009 8.20874 22.4568 8.03238 22.419C8.01979 22.419 8.00719 22.4127 7.99459 22.4127C7.81194 22.3749 7.62299 22.3434 7.42774 22.3119C7.42144 22.3119 7.41514 22.3119 7.40884 22.3119C7.16321 22.2741 6.91127 22.2426 6.66564 22.2048C6.55856 22.1859 6.45149 22.1733 6.34442 22.1544C6.27514 22.1418 6.20585 22.1292 6.13657 22.1167C6.12397 22.1167 6.11138 22.1104 6.09878 22.1104L5.71458 22.7591L2.68506 27.8545L8.14575 26.6137L9.67626 31.9988L12.5294 27.1931L13.09 26.261Z' fill='#6A7BB9'/>
                <Path d='M26.2724 22.7479L25.8882 22.1055C25.8756 22.1055 25.863 22.1118 25.8504 22.1118C25.7811 22.1244 25.7118 22.137 25.6425 22.1496C25.5355 22.1685 25.4284 22.1873 25.3213 22.1999C25.0757 22.2377 24.8238 22.2692 24.5781 22.307C24.5718 22.307 24.5655 22.307 24.5592 22.307C24.364 22.3322 24.175 22.37 23.9861 22.4078C23.9735 22.4078 23.9609 22.4141 23.9483 22.4141C23.7782 22.4519 23.6145 22.496 23.457 22.5527C23.4255 22.5652 23.3877 22.5778 23.3562 22.5904C23.2051 22.6534 23.0602 22.7227 22.9342 22.8109C22.802 22.9117 22.676 23.0313 22.5626 23.1762C21.769 24.1713 21.41 26.0797 20.2763 26.4451C20.1818 26.4765 20.0811 26.4891 19.9803 26.4954C19.9551 26.4954 19.9236 26.4954 19.8984 26.4954C19.8165 26.4954 19.7347 26.4891 19.6465 26.4765C19.6276 26.4765 19.6087 26.4702 19.5835 26.4702C19.3631 26.4325 19.13 26.3569 18.897 26.2561L19.4449 27.182L22.2981 31.9876L23.8223 26.6025L29.283 27.8433L26.2724 22.7479Z' fill='#6A7BB9'/>
                <Path d='M27.2425 9.60295C26.8205 8.30548 28.0109 6.18293 27.2236 5.09961C26.43 4.00999 24.0366 4.48866 22.947 3.69507C21.8699 2.90777 21.5865 0.482892 20.289 0.0609001C19.0357 -0.348495 17.3855 1.44025 15.9998 1.44025C14.6142 1.44025 12.964 -0.342196 11.7106 0.0609001C10.4132 0.482892 10.1298 2.90777 9.05273 3.69507C7.96311 4.48866 5.56973 4.00999 4.77613 5.09961C3.98883 6.17663 5.17923 8.30548 4.75724 9.60295C4.35414 10.85 2.12451 11.8704 2.12451 13.256C2.12451 14.6417 4.34784 15.6557 4.75724 16.9091C5.17923 18.2065 3.98883 20.3291 4.77613 21.4124C5.56973 22.502 7.96311 22.0234 9.05273 22.817C10.1298 23.6043 10.4132 26.0291 11.7106 26.4511C12.964 26.8542 14.6142 25.0655 15.9998 25.0655C17.3855 25.0655 19.0357 26.8479 20.289 26.4448C21.5865 26.0228 21.8699 23.598 22.947 22.8107C24.0366 22.0171 26.43 22.4957 27.2236 21.4061C28.0109 20.3291 26.8205 18.2002 27.2425 16.9028C27.6456 15.6557 29.8752 14.6354 29.8752 13.2497C29.8689 11.8704 27.6456 10.85 27.2425 9.60295Z' fill='#E3E3E3'/>
                <Path d='M11.711 26.4435C10.4135 26.0215 10.1301 23.5967 9.05308 22.8094C8.35396 22.2992 7.11948 22.3118 6.11174 22.0977L5.72754 22.7464C6.13064 22.8535 6.55893 22.9101 6.96832 22.9605C7.61705 23.0487 8.29098 23.1369 8.63109 23.3888C8.96491 23.6345 9.25463 24.2391 9.53176 24.8186C9.97265 25.7444 10.4765 26.7962 11.4843 27.1238C11.6795 27.1867 11.8874 27.2182 12.1015 27.2182C12.2464 27.2182 12.3912 27.2056 12.5361 27.1804L13.084 26.2546C12.6054 26.4687 12.133 26.5821 11.711 26.4435Z' fill='#4F609E'/>
                <Path d='M22.9474 22.8108C21.8704 23.5981 21.587 26.023 20.2895 26.445C19.8675 26.5835 19.4014 26.4639 18.9102 26.256L19.4581 27.1819C19.603 27.2071 19.7478 27.2197 19.8927 27.2197C20.1068 27.2197 20.3147 27.1882 20.5099 27.1252C21.5177 26.7977 22.0216 25.7458 22.4624 24.82C22.7396 24.2342 23.0293 23.6296 23.3631 23.3839C23.7032 23.1383 24.3772 23.0501 25.0259 22.9619C25.4353 22.9053 25.8636 22.8486 26.273 22.7415L25.8888 22.0928C24.881 22.3132 23.6465 22.3069 22.9474 22.8108Z' fill='#4F609E'/>
                <Path d='M24.3012 13.2559C24.3012 17.7151 20.7867 21.3493 16.3842 21.5445C16.2582 21.5508 16.1259 21.5571 15.9999 21.5571C11.4777 21.5571 7.79946 17.9419 7.69868 13.4448V13.407C7.69238 13.3629 7.69238 13.3063 7.69238 13.2559C7.69238 8.67064 11.4147 4.9546 15.9999 4.9546C20.5852 4.9483 24.3012 8.66434 24.3012 13.2559Z' fill='#B7B9BD'/>
                <Path d='M20.2856 18.2857H12.7925V16.7537L16.2441 13.3274C17.0946 12.4128 17.5199 11.6862 17.5199 11.1477C17.5199 10.7113 17.4191 10.3793 17.2176 10.1519C17.016 9.92436 16.7234 9.81062 16.3399 9.81062C15.9613 9.81062 15.6541 9.96383 15.4181 10.2702C15.1821 10.572 15.0641 10.9504 15.0641 11.4054H12.5713C12.5713 10.7832 12.736 10.2099 13.0654 9.68527C13.3948 9.15601 13.8521 8.74282 14.4372 8.44569C15.0223 8.14856 15.6762 8 16.3989 8C17.5593 8 18.4517 8.25302 19.0761 8.75907C19.7054 9.26511 20.0201 9.99168 20.0201 10.9388C20.0201 11.338 19.9414 11.728 19.7841 12.1087C19.6267 12.4848 19.3809 12.8817 19.0466 13.2995C18.7172 13.7127 18.1837 14.2675 17.4462 14.9639L16.0597 16.4751H20.2856V18.2857Z' fill='white'/>
            </Svg>
        );
    case 'medal-3-image':
        return (
            <Svg {...dataStyles} viewBox='0 0 32 32'>
                <Path d='M13.09 26.2605C12.8506 26.3613 12.6239 26.4431 12.3972 26.4809C12.3783 26.4872 12.3594 26.4872 12.3342 26.4872C12.2523 26.4998 12.1641 26.5061 12.0822 26.5061C12.057 26.5061 12.0256 26.5061 12.0004 26.5061C11.8996 26.4998 11.7988 26.4872 11.7043 26.4557C10.9359 26.2038 10.5202 25.2464 10.0857 24.3395C10.0164 24.2009 9.94709 24.0624 9.87781 23.9238C9.63847 23.4703 9.38024 23.0609 9.04642 22.8153C8.92045 22.7271 8.78189 22.6578 8.62443 22.5948C8.59294 22.5822 8.55515 22.5696 8.52366 22.557C8.3725 22.5004 8.20874 22.4563 8.03238 22.4185C8.01979 22.4185 8.00719 22.4122 7.99459 22.4122C7.81194 22.3744 7.62299 22.3429 7.42774 22.3114C7.42144 22.3114 7.41514 22.3114 7.40884 22.3114C7.16321 22.2736 6.91127 22.2421 6.66564 22.2043C6.55856 22.1854 6.45149 22.1728 6.34442 22.154C6.27514 22.1414 6.20585 22.1288 6.13657 22.1162C6.12397 22.1162 6.11138 22.1099 6.09878 22.1099L5.71458 22.7586L2.68506 27.854L8.14575 26.6132L9.67626 31.9983L12.5294 27.1926L13.09 26.2605Z' fill='#59B388'/>
                <Path d='M26.2724 22.7479L25.8882 22.1055C25.8756 22.1055 25.863 22.1118 25.8504 22.1118C25.7811 22.1244 25.7118 22.137 25.6425 22.1496C25.5355 22.1685 25.4284 22.1873 25.3213 22.1999C25.0757 22.2377 24.8238 22.2692 24.5781 22.307C24.5718 22.307 24.5655 22.307 24.5592 22.307C24.364 22.3322 24.175 22.37 23.9861 22.4078C23.9735 22.4078 23.9609 22.4141 23.9483 22.4141C23.7782 22.4519 23.6145 22.496 23.457 22.5527C23.4255 22.5652 23.3877 22.5778 23.3562 22.5904C23.2051 22.6534 23.0602 22.7227 22.9342 22.8109C22.802 22.9117 22.676 23.0313 22.5626 23.1762C21.769 24.1713 21.41 26.0797 20.2763 26.4451C20.1818 26.4765 20.0811 26.4891 19.9803 26.4954C19.9551 26.4954 19.9236 26.4954 19.8984 26.4954C19.8165 26.4954 19.7347 26.4891 19.6465 26.4765C19.6276 26.4765 19.6087 26.4702 19.5835 26.4702C19.3631 26.4325 19.13 26.3569 18.897 26.2561L19.4449 27.182L22.2981 31.9876L23.8223 26.6025L29.283 27.8433L26.2724 22.7479Z' fill='#59B388'/>
                <Path d='M27.2425 9.60295C26.8205 8.30548 28.0109 6.18293 27.2236 5.09961C26.43 4.00999 24.0366 4.48866 22.947 3.69507C21.8699 2.90777 21.5865 0.482892 20.289 0.0609C19.0357 -0.348495 17.3855 1.44025 15.9998 1.44025C14.6142 1.44025 12.964 -0.342196 11.7106 0.0609C10.4132 0.482892 10.1298 2.90777 9.05273 3.69507C7.96311 4.48866 5.56973 4.00999 4.77613 5.09961C3.98883 6.17663 5.17923 8.30548 4.75724 9.60295C4.35414 10.85 2.12451 11.8704 2.12451 13.256C2.12451 14.6417 4.34784 15.6557 4.75724 16.9091C5.17923 18.2065 3.98883 20.3291 4.77613 21.4124C5.56973 22.502 7.96311 22.0234 9.05273 22.817C10.1298 23.6042 10.4132 26.0291 11.7106 26.4511C12.964 26.8542 14.6142 25.0655 15.9998 25.0655C17.3855 25.0655 19.0357 26.8479 20.289 26.4448C21.5865 26.0228 21.8699 23.598 22.947 22.8107C24.0366 22.0171 26.43 22.4957 27.2236 21.4061C28.0109 20.3291 26.8205 18.2002 27.2425 16.9028C27.6456 15.6557 29.8752 14.6354 29.8752 13.2497C29.8689 11.8704 27.6456 10.85 27.2425 9.60295Z' fill='#ECC9AF'/>
                <Path d='M11.711 26.4431C10.4135 26.0211 10.1301 23.5962 9.05308 22.8089C8.35396 22.2987 7.11948 22.3113 6.11174 22.0972L5.72754 22.7459C6.13064 22.853 6.55893 22.9097 6.96832 22.96C7.61705 23.0482 8.29098 23.1364 8.63109 23.3883C8.96491 23.634 9.25463 24.2386 9.53176 24.8181C9.97265 25.7439 10.4765 26.7958 11.4843 27.1233C11.6795 27.1863 11.8874 27.2178 12.1015 27.2178C12.2464 27.2178 12.3912 27.2052 12.5361 27.18L13.084 26.2541C12.6054 26.4683 12.133 26.5816 11.711 26.4431Z' fill='#428D6A'/>
                <Path d='M22.9474 22.8108C21.8704 23.5981 21.587 26.023 20.2895 26.445C19.8675 26.5835 19.4014 26.4639 18.9102 26.256L19.4581 27.1819C19.603 27.2071 19.7478 27.2197 19.8927 27.2197C20.1068 27.2197 20.3147 27.1882 20.5099 27.1252C21.5177 26.7977 22.0216 25.7458 22.4624 24.82C22.7396 24.2342 23.0293 23.6296 23.3631 23.3839C23.7032 23.1383 24.3772 23.0501 25.0259 22.9619C25.4353 22.9053 25.8636 22.8486 26.273 22.7415L25.8888 22.0928C24.881 22.3132 23.6465 22.3069 22.9474 22.8108Z' fill='#428D6A'/>
                <Path d='M24.3012 13.2559C24.3012 17.7151 20.7867 21.3493 16.3842 21.5445C16.2582 21.5508 16.1259 21.5571 15.9999 21.5571C11.4777 21.5571 7.79946 17.9419 7.69868 13.4448V13.407C7.69238 13.3629 7.69238 13.3063 7.69238 13.2559C7.69238 8.67064 11.4147 4.9546 15.9999 4.9546C20.5852 4.9483 24.3012 8.66434 24.3012 13.2559Z' fill='#C89167'/>
                <Path d='M14.8397 12.1775H15.8702C16.6869 12.1775 17.0952 11.7538 17.0952 10.9064C17.0952 10.5766 16.998 10.3086 16.8035 10.1025C16.6091 9.89178 16.3347 9.78643 15.9804 9.78643C15.6909 9.78643 15.4382 9.87575 15.2221 10.0544C15.0104 10.233 14.9045 10.4552 14.9045 10.7209H12.7204C12.7204 10.1941 12.8586 9.72459 13.1352 9.31234C13.4117 8.90009 13.7941 8.57944 14.2823 8.35042C14.7749 8.11681 15.315 8 15.9026 8C16.9526 8 17.7779 8.25422 18.3785 8.76267C18.9791 9.27111 19.2794 9.96965 19.2794 10.8583C19.2794 11.2889 19.1541 11.6965 18.9035 12.0813C18.6572 12.4615 18.2964 12.7707 17.8211 13.0089C18.3223 13.2013 18.7155 13.4944 19.0007 13.8884C19.2858 14.2777 19.4284 14.761 19.4284 15.3381C19.4284 16.2313 19.1044 16.9459 18.4562 17.4818C17.8081 18.0178 16.9569 18.2857 15.9026 18.2857C15.2848 18.2857 14.7101 18.162 14.1786 17.9147C13.6515 17.6628 13.2518 17.3169 12.9796 16.8772C12.7074 16.4329 12.5713 15.929 12.5713 15.3656H14.7684C14.7684 15.6725 14.8851 15.9382 15.1184 16.1626C15.3517 16.3871 15.6391 16.4993 15.9804 16.4993C16.365 16.4993 16.6717 16.3871 16.9008 16.1626C17.1298 15.9336 17.2443 15.6427 17.2443 15.29C17.2443 14.7861 17.1254 14.4289 16.8878 14.2182C16.6501 14.0074 16.3218 13.9021 15.9026 13.9021H14.8397V12.1775Z' fill='white'/>
            </Svg>
        );


    default:
        return null;
    }
}

export default SvgSpriteAdminCore;