// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import {
    Div, svgSprite, Tooltip
} from '../../../../hybrid/wrappers';

const SubTitle = (props) => {
    //styles, compStyle, title, disabled, setFormData, attribute, hint, translation
    return (
        <Div styles={{ ...props?.styles.wrapper, ...props?.compStyle?.wrapper || {} }}>
            {props?.title.body}
            {!!props?.hint &&
                <Tooltip
                    text={props?.hint}
                    styles={{ ...props?.styles.hint, ...props?.compStyle?.hint || {} }}
                >
                    <Div
                        style={{
                            minHeight: '1rem',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        {svgSprite('info-circle', {
                            width: '20px',
                            height: '20px',
                            fill: props?.styles.variable.greyExtraDarkColor
                        })}
                    </Div>
                </Tooltip>
            }
        </Div>
    );
};


SubTitle.propTypes = {
    attribute: PropTypes.string,
    hint: PropTypes.node,
    placeholder: PropTypes.node,
    required: PropTypes.bool,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.object,
    handlers: PropTypes.object,
    compStyle: PropTypes.object,
    value: PropTypes.string,
    type: PropTypes.string,
    setFormData: PropTypes.func,
    errors: PropTypes.array,
    message: PropTypes.object,
    addon: PropTypes.node,
    variation: PropTypes.string,
    customData: PropTypes.object,
    data: PropTypes.node
};

export default Styles(SubTitle, getStyles);